import React, { useMemo } from 'react';

import { Form, Modal, Alert, Button, Radio } from 'antd';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  EXPORT_EXERCISE_SHEET_MUTATION,
  EXPORT_SHEET_MUTATION,
  EXPORT_COURSE_MUTATION,
  EXPORT_EXERCISE_MUTATION
} from '../../../helper/api/mutations';

const VERTICAL_RADIO_STYLE = { display: 'block', height: '30px', lineHeight: '30px' };

function ModalExportForm({ sheetId, exerciseId, courseId, onCancel }) {
  const { t } = useTranslation(['common', 'course']);
  const [form] = Form.useForm();

  const [
    exportExerciseSheet,
    { loading: loadingExportExerciseSheet, error: errorExportExerciseSheet }
  ] = useMutation(EXPORT_EXERCISE_SHEET_MUTATION, {
    onCompleted,
    onError
  });

  const [
    exportSheet,
    { loading: loadingExportSheet, error: errorExportSheet }
  ] = useMutation(EXPORT_SHEET_MUTATION, { onCompleted, onError });

  const [
    exprtExercise,
    { loading: loadingExportExercise, error: errorExportExercise }
  ] = useMutation(EXPORT_EXERCISE_MUTATION, { onCompleted, onError });

  const [
    exportCourse,
    { loading: loadingExportCourse, error: errorExportCourse }
  ] = useMutation(EXPORT_COURSE_MUTATION, { onCompleted, onError });

  const initialValues = useMemo(() => {
    if (exerciseId && sheetId) {
      return { format: 'PDF', mode: 'exerciseSheet' };
    }
    if (sheetId) {
      return { format: 'PDF', mode: 'sheet' };
    }
    if (exerciseId) {
      return { format: 'PDF', mode: 'exercise' };
    }
    if (courseId) {
      return { format: 'PDF', mode: 'course' };
    }
    return { format: 'PDF', mode: undefined };
  }, []);

  const loading =
    loadingExportExerciseSheet ||
    loadingExportSheet ||
    loadingExportExercise ||
    loadingExportCourse;

  const error =
    errorExportExerciseSheet || errorExportSheet || errorExportExercise || errorExportCourse;

  function onError() {}

  function onCompleted(data) {
    const { mode } = form.getFieldsValue();

    switch (mode) {
      case 'exerciseSheet':
        window.location.href = `${process.env.REACT_APP_EXPORT_URL}?token=${data.export.exerciseSheet.token}`;
        break;
      case 'sheet':
        window.location.href = `${process.env.REACT_APP_EXPORT_URL}?token=${data.export.sheet.token}`;
        break;
      case 'exercise':
        window.location.href = `${process.env.REACT_APP_EXPORT_URL}?token=${data.export.exercise.token}`;
        break;
      case 'course':
        window.location.href = `${process.env.REACT_APP_EXPORT_URL}?token=${data.export.course.token}`;
        break;
      default:
        break;
    }
  }

  function onExport() {
    const { mode, format } = form.getFieldsValue();
    switch (mode) {
      case 'exerciseSheet':
        return exportExerciseSheet({ variables: { sheet: sheetId, exercise: exerciseId, format } });
      case 'sheet':
        return exportSheet({ variables: { sheet: sheetId, format } });
      case 'exercise':
        return exprtExercise({ variables: { exercise: exerciseId, format } });
      case 'course':
        return exportCourse({ variables: { course: courseId, format } });
      default:
        return undefined;
    }
  }

  return (
    <Form
      form={form}
      name="sheetForm"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={initialValues}
    >
      <h3>{t('course:modal.export.title')}</h3>
      {error && (
        <Alert
          className="margin-bottom-md"
          description={t('res.error.unknownErrorAlert')}
          type="error"
          closable
        />
      )}
      <Form.Item
        name="mode"
        label={t('course:modal.export.whatToExport')}
        rules={[{ required: true, message: t('course:modal.export.validation.emptyMode') }]}
      >
        <Radio.Group>
          <Radio
            style={VERTICAL_RADIO_STYLE}
            value="exerciseSheet"
            disabled={!exerciseId || !sheetId}
          >
            {t('course:modal.export.mode.exerciseSheet')}
          </Radio>
          <Radio style={VERTICAL_RADIO_STYLE} value="sheet" disabled={!sheetId}>
            {t('course:modal.export.mode.sheet')}
          </Radio>
          <Radio style={VERTICAL_RADIO_STYLE} value="exercise" disabled={!exerciseId}>
            {t('course:modal.export.mode.exercise')}
          </Radio>
          <Radio style={VERTICAL_RADIO_STYLE} value="course" disabled={!courseId}>
            {t('course:modal.export.mode.course')}
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="format"
        label={t('course:modal.export.format')}
        rules={[{ required: true, message: t('course:modal.export.validation.emptyFormat') }]}
      >
        <Radio.Group>
          <Radio value="PDF">{t('course:modal.export.file', { type: 'PDF' })}</Radio>
          <Radio value="CSV">{t('course:modal.export.file', { type: 'CSV' })}</Radio>
        </Radio.Group>
      </Form.Item>
      <div className="text-right padding-top-md">
        <span className="padding-right-sm">
          <Button onClick={onCancel}>{t('buttons.cancel')}</Button>
        </span>
        <Button type="primary" onClick={onExport} loading={loading} disabled={loading}>
          {t('course:modal.export.okButton')}
        </Button>
      </div>
    </Form>
  );
}

function ModalExport({ visible, onCancel, sheetId, exerciseId, courseId }) {
  return (
    <Modal visible={visible} closable onCancel={onCancel} footer={null} destroyOnClose>
      <ModalExportForm
        onCancel={onCancel}
        sheetId={sheetId}
        exerciseId={exerciseId}
        courseId={courseId}
      />
    </Modal>
  );
}

export default ModalExport;
