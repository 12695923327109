import React, { useState, useCallback, useEffect } from 'react';

import { Form, Input, Button, Modal, Switch, Alert, Tooltip } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import ContainerShadow from '../Container/ContainerShadow/ContainerShadow';
import getErrorCode from '../../helper/getErrorCode';

function ModalSheetForm({ values, loading, error, onCancel, onOk }) {
  const [tasksKeys, setTasksKeys] = useState(() => {
    return (values.tasks || []).map((_, key) => key);
  });

  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    const code = getErrorCode(error);
    if (code === 412) {
      form.setFields([
        {
          errors: [t('modal.sheet.validation.sheetNameExists')],
          name: 'name'
        }
      ]);
    } else {
      form.setFields([
        {
          errors: [],
          name: 'name'
        }
      ]);
    }
  }, [error]);

  const transformInputData = useCallback(
    (data) => {
      return {
        id: data.id,
        name: data.name,
        ...(data.tasks || []).reduce(
          (prev, cur, key) => ({
            ...prev,
            [key]: { id: cur.id, name: cur.name, optional: cur.optional }
          }),
          []
        )
      };
    },
    [values]
  );

  function transformOutputData(data) {
    return {
      id: data.id,
      name: data.name,
      tasks: tasksKeys.map((key) => ({
        id: data[key].id || undefined,
        name: data[key].name,
        optional: data[key].optional || false
      }))
    };
  }

  function onSave() {
    return form
      .validateFields()
      .then((valuesForm) => {
        onOk(transformOutputData(valuesForm));
      })
      .catch(() => {});
  }

  function onAddTask() {
    const key = new Date().valueOf();
    setTasksKeys([...tasksKeys, key]);
    form.setFieldsValue({
      [key]: { name: t('modal.sheet.task', { number: tasksKeys.length + 1 }) }
    });
  }

  function onDeleteTask(key) {
    const index = tasksKeys.findIndex((taskKey) => taskKey === key);

    if (index >= 0) {
      setTasksKeys([...tasksKeys.slice(0, index), ...tasksKeys.slice(index + 1)]);
    }
  }

  function getErrorText(errorResponse) {
    const code = getErrorCode(errorResponse);
    switch (code) {
      case 412:
        return t('modal.sheet.res.sheetNameExists');
      case 400:
      case 403:
      case 404:
      default:
        return t('res.error.unknownErrorAlert');
    }
  }

  return (
    <Form
      form={form}
      initialValues={transformInputData(values)}
      name="sheetForm"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <h3>{t('modal.sheet.title')}</h3>
      {error && getErrorCode(error) !== 412 && (
        <Alert
          className="margin-bottom-md"
          description={getErrorText(error)}
          type="error"
          closable
        />
      )}
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        hasFeedback
        label={t('modal.sheet.sheetName')}
        name="name"
        validateTrigger="onBlur"
        rules={[{ required: true, message: t('modal.sheet.validation.emptySheet') }]}
      >
        <Input autoFocus placeholder={t('modal.sheet.sheetName')} />
      </Form.Item>
      <div className="margin-bottom-md" />
      {tasksKeys.map((key) => (
        <ContainerShadow
          key={key}
          style={{ display: 'flex', alignItems: 'center' }}
          className="margin-bottom-sm padding-top-xs padding-right-sm padding-left-sm"
        >
          <Form.Item noStyle name={[key, 'id']}>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            name={[key, 'name']}
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                message: t('modal.sheet.validation.emptyTask')
              }
            ]}
            label={t('modal.sheet.taskName')}
            style={{ flex: 1 }}
          >
            <Input placeholder={t('modal.sheet.taskName')} />
          </Form.Item>
          <Form.Item
            name={[key, 'optional']}
            label={t('optional')}
            valuePropName="checked"
            className="padding-left-sm"
            style={{ width: 70 }}
          >
            <Switch />
          </Form.Item>
          <div className="padding-left-sm">
            <Tooltip title={t('modal.sheet.deleteTask')}>
              <Button
                type="danger"
                size="small"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => {
                  onDeleteTask(key);
                }}
              />
            </Tooltip>
          </div>
        </ContainerShadow>
      ))}
      <Button type="dashed" onClick={onAddTask} style={{ width: '100%' }}>
        {t('modal.sheet.addTask')}
        <PlusOutlined />
      </Button>
      <div className="text-right padding-top-md">
        <span className="padding-right-sm">
          <Button onClick={onCancel}>{t('buttons.close')}</Button>
        </span>
        <Button type="primary" onClick={onSave} loading={loading} disabled={loading}>
          {t('modal.sheet.okButton')}
        </Button>
      </div>
    </Form>
  );
}

function ModalSheet({ visible, values, loading, error, onCancel, onOk }) {
  return (
    <Modal visible={visible} footer={null} onCancel={onCancel} closable destroyOnClose>
      <ModalSheetForm
        values={values}
        loading={loading}
        error={error}
        onCancel={onCancel}
        onOk={onOk}
      />
    </Modal>
  );
}

export default ModalSheet;
