import React from 'react';

import { useTranslation } from 'react-i18next';

import CardClickable from '../components/CardClickable/CardClickable';
import { ACTION_REGISTER_STEPS, USER_TYPES } from '../reducer';

function RegisterStepTypeSelection({ dispatch }) {
  const { t } = useTranslation(['common', 'register']);
  function onClickStudent() {
    dispatch({ type: ACTION_REGISTER_STEPS.SELECT_USER_TYPE, userType: USER_TYPES.STUDENT });
  }

  function onClickTeacher() {
    dispatch({ type: ACTION_REGISTER_STEPS.SELECT_USER_TYPE, userType: USER_TYPES.TEACHER });
  }

  return (
    <div>
      <div className="margin-bottom-md">{t('register:teaser')}</div>
      <h3 className="padding-bottom-sm">{t('register:useAs')}</h3>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="padding-right-sm" style={{ flex: 1 }}>
          <CardClickable onClick={onClickStudent}>
            <img
              src="/assets/icons/student.svg"
              alt="student"
              style={{ width: '100%' }}
              className="padding-bottom-sm"
            />
            <h4>{t('student')}</h4>
          </CardClickable>
        </div>
        <div className="padding-left-sm" style={{ flex: 1 }}>
          <CardClickable onClick={onClickTeacher}>
            <img
              src="/assets/icons/teacher.svg"
              alt="teacher"
              style={{ width: '100%' }}
              className="padding-bottom-sm"
            />
            <h4>{t('register:teacher')}</h4>
          </CardClickable>
        </div>
      </div>
    </div>
  );
}

export default RegisterStepTypeSelection;
