import React from 'react';

import { Badge, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import VOTE_STATE from '../../constants/VOTE_STATE';

function BadgeVoteStateStudent({ state }) {
  const { t } = useTranslation();
  switch (state) {
    case VOTE_STATE.REJECTED:
      return (
        <Tooltip title={t('voteState.rejected')}>
          <Badge status="error" />
        </Tooltip>
      );
    case VOTE_STATE.VOTED:
      return (
        <Tooltip title={t('voteState.voted')}>
          <Badge status="success" />
        </Tooltip>
      );
    case VOTE_STATE.ILL:
      return (
        <Tooltip title={t('voteState.ill')}>
          <Badge status="warning" />
        </Tooltip>
      );
    default:
    case VOTE_STATE.NOT_VOTED:
      return (
        <Tooltip title={t('voteState.notVoted')}>
          <Badge status="default" />
        </Tooltip>
      );
  }
}

export default React.memo(BadgeVoteStateStudent);
