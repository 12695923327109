import React from 'react';

import { Form, message, Input, Button, Tooltip } from 'antd';
import { useMutation } from '@apollo/client';
import { SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { UPDATE_NOTE_MUTATION } from '../../../../../helper/api/mutations';
import { updatCacheNote } from '../../../../../helper/cacheUpdate';

function Note({ note, userId, sheetId }) {
  const { t } = useTranslation(['common', 'course']);
  const [form] = Form.useForm();

  const [updateNote, { loading }] = useMutation(UPDATE_NOTE_MUTATION, {
    onCompleted,
    onError
  });

  function onCompleted() {
    message.success(t('course:modal.studentInfo.res.commentUpdate'));
  }

  function onError() {
    message.success(t('course:modal.studentInfo.res.commentUpdateError'));
  }

  function onSave() {
    const newNote = form.getFieldValue('note');

    if (newNote !== note) {
      updateNote({
        variables: { note: newNote, user: userId, sheet: sheetId },
        update: updatCacheNote({ userId, sheetId }, { note: newNote })
      });
    }
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ flex: 1 }}>
        <Form form={form}>
          <Form.Item
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            label={t('comment')}
            name="note"
          >
            <Input.TextArea defaultValue={note} placeholder={t('comment')} />
          </Form.Item>
        </Form>
      </div>
      <div className="padding-left-sm">
        <Tooltip title={t('course:modal.studentInfo.tooltip.saveComment')}>
          <Button
            icon={<SaveOutlined />}
            onClick={onSave}
            type="primary"
            shape="circle"
            loading={loading}
            disabled={loading}
          />
        </Tooltip>
      </div>
    </div>
  );
}

export default Note;
