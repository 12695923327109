import React from 'react';

import { Card } from 'antd';
import { ClockCircleOutlined, EnvironmentOutlined, TeamOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import Statistic from '../../../../components/Statistic';
import DAYS from '../../../../constants/DAYS';
import MetaInformation from '../../../../components/MetaInformation/MetaInformation';
import ProgressStudent from '../../../../components/ProgressStudent';
import TagVotable from '../../../../components/Tags/TagVotable';

import './CardExercise.less';

function CardExercise({ exercise = {}, onClick }, ref) {
  const { t } = useTranslation();
  return (
    <Card ref={ref} hoverable onClick={onClick} className="main-card exercise-card">
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', maxWidth: '100%' }}
      >
        <h4 className="title">{exercise.course.name}</h4>
        <TagVotable votable={exercise.votable} />
      </div>
      <MetaInformation
        infos={[
          exercise.dayOfWeek &&
            exercise.startTime &&
            exercise.endTime && {
              icon: ClockCircleOutlined,
              info: `${DAYS[exercise.dayOfWeek]}. ${exercise.startTime.substr(
                0,
                5
              )} - ${exercise.endTime.substr(0, 5)}`
            },
          exercise.location && { icon: EnvironmentOutlined, info: exercise.location },
          { icon: TeamOutlined, info: exercise.name }
        ]}
      />
      <div className="container-main padding-top-sm">
        <div className="container-statistics">
          <Statistic
            reached={exercise.voteCount}
            max={exercise.course.taskCount.toFixed(0)}
            title={t('voting')}
          />
          <Statistic
            reached={exercise.presentCount}
            max={exercise.course.minPresentations}
            title={t('presentation')}
          />
        </div>
        <div className="padding-left-md">
          <ProgressStudent
            taskCount={exercise.course.taskCount}
            voteCount={exercise.voteCount}
            illCount={exercise.illCount}
            minVotes={exercise.course.minVotes}
          />
        </div>
      </div>
    </Card>
  );
}

export default React.forwardRef(CardExercise);
