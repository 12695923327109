import React from 'react';

import ContainerShadow from '../../../../components/Container/ContainerShadow/ContainerShadow';

import './CardClickable.less';

function CardClickable({ children, style, className, onClick }) {
  return (
    <ContainerShadow
      style={style}
      className={`card-clickable padding-top-sm padding-right-sm padding-bottom-sm padding-left-sm ${className}`}
      onClick={onClick}
    >
      {children}
    </ContainerShadow>
  );
}

export default CardClickable;
