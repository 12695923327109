import React, { useMemo, useReducer } from 'react';

import { Modal, Spin, List, Checkbox, Button, Divider, message, Alert } from 'antd';
import { TeamOutlined, EnvironmentOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { VOTABLE_SHEETS_QUERY } from '../../helper/api/queries';
import { VOTE_BY_STUDENT_MUTATION } from '../../helper/api/mutations';
import ResponseNetworkError from '../Response/ResponseNetworkError';
import DAYS from '../../constants/DAYS';
import MetaInformation from '../MetaInformation/MetaInformation';
import getErrorCode from '../../helper/getErrorCode';

const ACTION_MODAL_CONTENT = {
  ON_CHANGE: 1,
  SELECT_ALL: 2,
  UNSELECT_ALL: 3
};

function reducerModalContent(state, action) {
  switch (action.type) {
    case ACTION_MODAL_CONTENT.ON_CHANGE: {
      if (state.tasks[action.id]) {
        const { [action.id]: omit, ...tasks } = state.tasks;
        return { tasks, count: state.count - 1 };
      }
      return { tasks: { ...state.tasks, [action.id]: true }, count: state.count + 1 };
    }
    case ACTION_MODAL_CONTENT.SELECT_ALL:
      return {
        tasks: action.ids.reduce((prev, cur) => ({ ...prev, [cur]: true }), {}),
        count: action.ids.length
      };
    case ACTION_MODAL_CONTENT.UNSELECT_ALL:
      return { tasks: {}, count: 0 };
    default:
      return state;
  }
}

function ModalContent({ currentExercise, votableSheet, onClose }) {
  const [state, dispatch] = useReducer(reducerModalContent, { tasks: {}, count: 0 });

  const { t } = useTranslation();

  const [vote, { loading: loadingMutation, error: errorMutation }] = useMutation(
    VOTE_BY_STUDENT_MUTATION,
    {
      onCompleted,
      onError
    }
  );

  const allSelected = state.count === votableSheet?.node.votes.length;

  function onCompleted() {
    onClose();
    message.success(t('modal.studentVote.res.voted'));
  }

  function onError(error) {
    const code = getErrorCode(error);

    switch (code) {
      case 423:
        message.error(t('modal.studentVote.res.notVotable'));
        break;
      case 400:
      case 412:
      default:
        message.error(t('modal.studentVote.res.voteFaile'));
        break;
    }
  }

  function getErrorText(errorResponse) {
    const code = getErrorCode(errorResponse);
    switch (code) {
      case 423:
        return t('modal.studentVote.res.notVotable');
      case 400:
      case 412:
      default:
        return t('res.error.unknownErrorAlert');
    }
  }

  function onSelectAll() {
    dispatch({
      type: ACTION_MODAL_CONTENT.SELECT_ALL,
      ids: votableSheet.node.votes.map(({ task }) => task.id)
    });
  }

  function onUnselectAll() {
    dispatch({ type: ACTION_MODAL_CONTENT.UNSELECT_ALL });
  }

  function onSave() {
    vote({
      variables: {
        tasks: votableSheet.node.votes.map(({ task }) => ({
          taskId: task.id,
          voted: !!state.tasks[task.id]
        }))
      }
    });
  }

  return (
    <div>
      <h3 style={{ marginBottom: 0 }}>
        {votableSheet.node.name}
        {' '}
        -
        {currentExercise.course.name}
      </h3>
      <MetaInformation
        infos={[
          { info: currentExercise.name, icon: TeamOutlined },
          currentExercise.dayOfWeek &&
            currentExercise.startTime &&
            currentExercise.endTime && {
              icon: ClockCircleOutlined,
              info: `${DAYS[currentExercise.dayOfWeek]}. ${currentExercise.startTime.substr(
                0,
                5
              )} - ${currentExercise.endTime.substr(0, 5)}`
            },
          currentExercise.location && { icon: EnvironmentOutlined, info: currentExercise.location }
        ]}
      />

      {errorMutation && (
        <Alert
          className="margin-bottom-md"
          description={getErrorText(errorMutation)}
          type="error"
          closable
        />
      )}

      <List
        itemLayout="horizontal"
        dataSource={votableSheet.node.votes}
        split={false}
        renderItem={({ task }) => (
          <List.Item>
            <List.Item.Meta
              title={(
                <Checkbox
                  checked={state.tasks[task.id]}
                  onChange={() => dispatch({ type: ACTION_MODAL_CONTENT.ON_CHANGE, id: task.id })}
                >
                  {task.name}
                  {' '}
                  {task.optional && (
                  <span className="text-secondary">
                    (
                    {t('optional')}
                    )
                  </span>
)}
                </Checkbox>
              )}
            />
          </List.Item>
        )}
      />
      {allSelected ? (
        <Button type="link" onClick={onUnselectAll}>
          {t('modal.studentVote.voteNothing')}
        </Button>
      ) : (
        <Button type="link" onClick={onSelectAll}>
          {t('modal.studentVote.voteAll')}
        </Button>
      )}
      <Divider type="horizontal" />
      <div className="text-secondary padding-bottom-sm">
        {t('modal.studentVote.notice1')} 
        {' '}
        <b>{t('modal.studentVote.notice2')}</b>
        {' '}
        {t('modal.studentVote.notice3')}
      </div>
      <div className="text-right">
        <span className="padding-right-sm">
          <Button onClick={onClose}>{t('buttons.close')}</Button>
        </span>
        <Button
          type="primary"
          loading={loadingMutation}
          disabled={loadingMutation}
          onClick={onSave}
        >
          {t('modal.studentVote.okButton')}
        </Button>
      </div>
    </div>
  );
}

function APIInterface({ exerciseId, votableSheet, currentExercise, onClose }) {
  const { data, loading, error } = useQuery(VOTABLE_SHEETS_QUERY, {
    variables: { id: exerciseId },
    skip: !!votableSheet && !!currentExercise,
    fetchPolicy: 'cache-and-network'
  });

  const apiVotableSheet = useMemo(() => {
    return data?.exercise.sheets.find((sheet) => sheet.votable);
  }, [data]);

  if (votableSheet && currentExercise) {
    return (
      <ModalContent
        onClose={onClose}
        votableSheet={votableSheet}
        currentExercise={currentExercise}
      />
    );
  }

  if (loading) {
    return (
      <div style={{ width: '100%' }} className="text-center">
        <Spin />
      </div>
    );
  }

  if (error || !apiVotableSheet) {
    return <ResponseNetworkError />;
  }

  return (
    <ModalContent
      onClose={onClose}
      votableSheet={apiVotableSheet}
      currentExercise={data?.exercise}
    />
  );
}

function ModalStudentVote({
  visible,
  exerciseId,
  votableSheet = undefined,
  currentExercise = undefined,
  onClose
}) {
  return (
    <Modal visible={visible} footer={null} maskClosable onCancel={onClose} destroyOnClose>
      <APIInterface
        exerciseId={exerciseId}
        votableSheet={votableSheet}
        currentExercise={currentExercise}
        onClose={onClose}
      />
    </Modal>
  );
}

export default ModalStudentVote;
