import React from 'react';

function ContainerMaxWidth({ children, maxWidth }) {
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '100%', maxWidth }}>{children}</div>
    </div>
  );
}

export default ContainerMaxWidth;
