import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const ACCOUNT_FRAGMENT = gql`
  fragment UserData on User {
    id
    firstName
    lastName
    matriculationNumber
    email
    study
    degree
    weeklyEmail
    voteEmail
    endVotingPhaseEmail
    votePush
    language
  }
`;

export const EXERCISE_FRAGMENT = gql`
  fragment ExerciseData on Exercise {
    id
    name
    location
    dayOfWeek
    startTime
    endTime
    startVote
    endVote
    autoVotable
  }
`;

export const SHEET_FRAGMENT = gql`
  fragment SheetData on Sheet {
    id
    name
    tasks {
      id
      name
      optional
    }
  }
`;

export const TEACHER_ROLE_FRAGMENT = gql`
  fragment TeacherRole on CourseTeacherEdge {
    role
  }
`;

export const STUDENT_NOTE_FRAGMENT = gql`
  fragment StudentNote on StudentSheetInfo {
    note
  }
`;

export const STUDENT_VOTE_FRAGMENT = gql`
  fragment StudentVote on Vote {
    state
    presented
  }
`;

export const DASHBOARD_COURSES_FRAGMENT = gql`
  fragment DashboardCourse on Course {
    id
    name
    exerciseCount
    studentCount
    teacherCount
  }
`;

export const DASHBOARD_EXERCISE_FRAGMENT = gql`
  fragment DashboardExercise on Exercise {
    id
    name
    location
    dayOfWeek
    startTime
    endTime
    course {
      id
      name
      minVotes
      minPresentations
      taskCount
    }
    illCount
    voteCount
    presentCount
    votable
  }
`;

export const DASHBOARD_INVITATION_FRAGMENT = gql`
  fragment DashboardInvitation on CourseInvitation {
    course {
      id
      name
    }
  }
`;

export const STUDENT_SHEET_INFO_OVERVIEW_FRAGMENT = gql`
  fragment StudentSheetInfoOverview on StudentSheetInfo {
    cursor
    user {
      id
      firstName
      lastName
    }
    sheet {
      id
    }
    votes {
      task {
        id
      }
      user {
        id
      }
      ...StudentVote
    }
    ...StudentNote
    presentCount
    voteCount
  }
  ${STUDENT_NOTE_FRAGMENT}
  ${STUDENT_VOTE_FRAGMENT}
`;

export const STUDENT_EXERCISE_SHEET_FRAGMENT = gql`
  fragment StudentExerciseSheetData on Sheet {
    id
    name
    date
    votable
    votes {
      task {
        id
        name
        optional
      }
      state
      presented
    }
  }
`;

export const EXERCISE_STATISTIC_FRAGMENT = gql`
  fragment ExerciseStatistic on Exercise {
    id
    illCount
    voteCount
    presentCount
    course {
      id
      name
      minVotes
      taskCount
      minPresentations
    }
  }
`;
