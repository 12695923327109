/* eslint-disable import/first */
import React from 'react';

import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import RoutePrivateOnly from './components/Routes/RoutePrivateOnly';
import RoutePublicOnly from './components/Routes/RoutePublicOnly';
import RouteSuspense from './components/Routes/RouteSuspense';

import { StorageContextProvider } from './contexts/StorageContextProvider';
import { AccountContextProvider } from './contexts/AccountContextProvider';
import I18NProvider from './contexts/I18NProvider';
import Apollo from './core/Apollo';
import ErrorBoundary from './core/ErrorBoundary';

// const Error404 = React.lazy(() => import('./screens/Error404'));
// const Dashboard = React.lazy(() => import('./screens/Dashboard/Dashboard'));
// const Course = React.lazy(() => import('./screens/Course/Course'));
// const Exercise = React.lazy(() => import('./screens/Exercise/Exercise'));
// const Login = React.lazy(() => import('./screens/Login/Login'));
// const PrivacyPolicy = React.lazy(() => import('./screens/PrivacyPolicy/PrivacyPolicy'));
// const Imprint = React.lazy(() => import('./screens/Imprint/Imprint'));
// const ResetStart = React.lazy(() => import('./screens/ResetStart/ResetStart'));
// const ResetFinalize = React.lazy(() => import('./screens/ResetFinalize/ResetFinalize'));
// const Register = React.lazy(() => import('./screens/Register/Register'));
// const Settings = React.lazy(() => import('./screens/Settings/Settings'));
// const CreateCourse = React.lazy(() => import('./screens/CreateCourse/CreateCourse'));

import Error404 from './screens/Error404';
import Dashboard from './screens/Dashboard/Dashboard';
import Course from './screens/Course/Course';
import Exercise from './screens/Exercise/Exercise';
import Login from './screens/Login/Login';
import PrivacyPolicy from './screens/PrivacyPolicy/PrivacyPolicy';
import Imprint from './screens/Imprint/Imprint';
import ResetStart from './screens/ResetStart/ResetStart';
import ResetFinalize from './screens/ResetFinalize/ResetFinalize';
import Register from './screens/Register/Register';
import Settings from './screens/Settings/Settings';
import CreateCourse from './screens/CreateCourse/CreateCourse';

import { ContainerLoggedOutFallback } from './components/Container/ContainerLoggedOut/ContainerLoggedOut';
import { ContainerLoggedInFallback } from './components/Container/ContainerLoggedIn/ContainerLoggedIn';

import './index.less';
import { ContainerBlankFallback } from './components/Container/ContainerBlank/ContainerBlank';
import { ContainerErrorFallback } from './components/Container/ContainerError/ContainerError';

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <I18NProvider>
          <StorageContextProvider>
            <AccountContextProvider>
              <Apollo>
                <Switch>
                  <RoutePrivateOnly
                    exact
                    path={['/', '/join/:join/:inviteCode', '/join/:inviteCode']}
                    fallback={<ContainerLoggedInFallback />}
                    component={Dashboard}
                  />
                  <RoutePrivateOnly
                    exact
                    path="/course/create"
                    fallback={<ContainerLoggedInFallback />}
                    component={CreateCourse}
                  />
                  <RoutePrivateOnly
                    exact
                    path="/course/:id/:tab?/:settingsMenu?"
                    fallback={<ContainerLoggedInFallback />}
                    component={Course}
                  />
                  <RoutePrivateOnly
                    exact
                    path="/exercise/:id/:name"
                    fallback={<ContainerLoggedInFallback />}
                    component={Exercise}
                  />
                  <RoutePrivateOnly
                    exact
                    path="/settings"
                    fallback={<ContainerLoggedInFallback />}
                    component={Settings}
                  />
                  <RoutePublicOnly
                    exact
                    path="/login"
                    fallback={<ContainerLoggedOutFallback />}
                    component={Login}
                  />
                  <RoutePublicOnly
                    exact
                    path="/reset"
                    fallback={<ContainerLoggedOutFallback />}
                    component={ResetStart}
                  />
                  <RoutePublicOnly
                    exact
                    path="/reset/:token"
                    fallback={<ContainerLoggedOutFallback />}
                    component={ResetFinalize}
                  />
                  <RoutePublicOnly
                    exact
                    path="/register"
                    fallback={<ContainerLoggedOutFallback />}
                    component={Register}
                  />
                  <RouteSuspense
                    exact
                    path="/imprint"
                    fallback={<ContainerBlankFallback />}
                    component={Imprint}
                  />
                  <RouteSuspense
                    exact
                    path="/privacy-policy"
                    fallback={<ContainerBlankFallback />}
                    component={PrivacyPolicy}
                  />
                  <RouteSuspense component={Error404} fallback={<ContainerErrorFallback />} />
                </Switch>
              </Apollo>
            </AccountContextProvider>
          </StorageContextProvider>
        </I18NProvider>
      </ErrorBoundary>
    </Router>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
