import React from 'react';

import { Alert, Button, Tooltip } from 'antd';

function AlertWithButton({ message, buttons = [], ...alertProps }) {
  return (
    <Alert
      message={(
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <span style={{ left: 0 }}>{message}</span>
          <span>
            {buttons.map(({ title, tooltipTitle, style = {}, ...buttonProps }) => (
              <Tooltip title={tooltipTitle} key={title}>
                <Button style={{ marginLeft: 10, ...style }} {...buttonProps}>
                  {title}
                </Button>
              </Tooltip>
            ))}
          </span>
        </div>
      )}
      {...alertProps}
    />
  );
}

export default AlertWithButton;
