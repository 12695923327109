/**
 * Important: if you change something at the theme, you also have to adjust the fallback version
 */

import React from 'react';

import { Link } from 'react-router-dom';

import Footer from '../../Footer';

import './ContainerError.less';

function ContainerError({ children }) {
  return (
    <div className="container-error">
      <Link to="/">
        <div className="container-logo padding-left-md padding-right-md padding-top-sm">
          <img src="/assets/icons/cards.svg" alt="finvote logo" />
          <span className="title padding-left-sm padding-right-md">FinVote</span>
          <span className="divider" />
        </div>
      </Link>
      <div className="content text-center" style={{ flex: 1 }}>
        {children}
      </div>
      <div style={{ background: '#fff' }}>
        <Footer />
      </div>
    </div>
  );
}

export function ContainerErrorFallback() {
  return (
    <div className="container-error">
      <Link to="/">
        <div className="container-logo padding-left-md padding-right-md padding-top-sm">
          <img src="/assets/icons/cards.svg" alt="finvote logo" />
          <span className="title padding-left-sm padding-right-md">FinVote</span>
          <span className="divider" />
        </div>
      </Link>
      <div className="content text-center" style={{ flex: 1 }} />
    </div>
  );
}

export default ContainerError;
