/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import {
  DASHBOARD_COURSES_FRAGMENT,
  DASHBOARD_EXERCISE_FRAGMENT,
  DASHBOARD_INVITATION_FRAGMENT,
  STUDENT_SHEET_INFO_OVERVIEW_FRAGMENT,
  STUDENT_EXERCISE_SHEET_FRAGMENT,
  EXERCISE_STATISTIC_FRAGMENT
} from './fragments';

export const VOTEABLE_UPDATE_SUBSCRIPTION = gql`
  subscription votableUpdate($exercise: ID!, $sheet: ID!) {
    votableUpdate(exercise: $exercise, sheet: $sheet) {
      votable
      closeTime
    }
  }
`;

export const DASHBOARD_COURSE_SUBSCRIPTION = gql`
  subscription dashboardCourseUpdate {
    courseUpdate {
      ... on NewCourse {
        course {
          ...DashboardCourse
        }
      }
      ... on Course {
        ...DashboardCourse
      }
      ... on Deleted {
        deleted
      }
    }
  }
  ${DASHBOARD_COURSES_FRAGMENT}
`;

export const DASHBOARD_EXERCISE_SUBSCRIPTION = gql`
  subscription dashboardExerciseUpdate {
    studentExerciseUpdate {
      ... on NewExercise {
        exercise {
          ...DashboardExercise
        }
      }
      ... on Exercise {
        ...DashboardExercise
      }
      ... on Deleted {
        deleted
      }
    }
  }
  ${DASHBOARD_EXERCISE_FRAGMENT}
`;

export const DASHBOARD_INVITATION_SUBSCRIPTION = gql`
  subscription dashboardInviteUpdate {
    invitationUpdate {
      ... on NewInvitation {
        invitation {
          ...DashboardInvitation
        }
      }
      ... on Deleted {
        deleted
      }
    }
  }
  ${DASHBOARD_INVITATION_FRAGMENT}
`;

export const VOTE_UPDATE_SUBSCRIPTION = gql`
  subscription voteUpdateExerciseOverview($exercise: ID!, $sheet: ID!) {
    voteUpdate(exercise: $exercise, sheet: $sheet) {
      ... on NewStudentSheetInfo {
        studentSheetInfo {
          ...StudentSheetInfoOverview
        }
      }
      ... on StudentSheetInfo {
        ...StudentSheetInfoOverview
      }
      ... on Deleted {
        deleted
      }
    }
  }
  ${STUDENT_SHEET_INFO_OVERVIEW_FRAGMENT}
`;

export const STUDENT_SHEET_UPDATE_SUBSCRIPTION = gql`
  subscription studentSheetUpdate($exercise: ID!) {
    studentSheetUpdate(exercise: $exercise) {
      ... on NewSheet {
        sheet {
          ...StudentExerciseSheetData
        }
      }
      ... on Sheet {
        ...StudentExerciseSheetData
      }
      ... on Deleted {
        deleted
      }
    }
  }
  ${STUDENT_EXERCISE_SHEET_FRAGMENT}
`;

export const STUDENT_EXERCISE_SUMMARY_UPDATE_SUBSCRIPTION = gql`
  subscription dashboardExerciseUpdate {
    studentExerciseUpdate {
      ... on Exercise {
        ...ExerciseStatistic
      }
    }
  }
  ${EXERCISE_STATISTIC_FRAGMENT}
`;
