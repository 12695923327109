import React from 'react';

import { Form, Input, Button, Select } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import PrimaryButton from '../../../components/PrimaryButton';
import { ACTION_REGISTER_STEPS } from '../reducer';

function RegisterStepSetAccountData({ state, dispatch, onPrev }) {
  const { t, i18n } = useTranslation(['common', 'register']);
  const [form] = Form.useForm();

  function onNext() {
    return form
      .validateFields()
      .then((values) =>
        dispatch({
          type: ACTION_REGISTER_STEPS.SET_ACCOUNT_DATA,
          firstName: values.firstName,
          lastName: values.lastName,
          language: values.language
        })
      )
      .catch(() => {});
  }

  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={{
        firstName: state.firstName,
        lastName: state.lastName,
        language: state.language || i18n.language.toUpperCase()
      }}
    >
      <Form.Item
        hasFeedback
        label={t('yourFirstName')}
        name="firstName"
        validateTrigger="onBlur"
        rules={[{ required: true, message: t('validation.emptyFirstName') }]}
      >
        <Input size="large" placeholder={t('yourFirstName')} autoComplete="given-name" autoFocus />
      </Form.Item>

      <Form.Item
        hasFeedback
        label={t('yourLastName')}
        name="lastName"
        validateTrigger="onBlur"
        rules={[{ required: true, message: t('validation.emptyLastName') }]}
      >
        <Input size="large" placeholder={t('yourLastName')} autoComplete="family-name" />
      </Form.Item>

      <Form.Item
        hasFeedback
        label={t('preferedLanguage')}
        name="language"
        rules={[{ required: true, message: t('validation.emptyLanguage') }]}
      >
        <Select size="large" placeholder={t('pleaseSelect')}>
          <Select.Option value="DE">{t('lang.de')}</Select.Option>
          <Select.Option value="EN">{t('lang.en')}</Select.Option>
        </Select>
      </Form.Item>

      <PrimaryButton style={{ width: '100%' }} size="large" htmlType="submit" onClick={onNext}>
        {t('buttons.next')} 
        {' '}
        <RightOutlined />
      </PrimaryButton>
      <Button type="link" style={{ width: '100%' }} size="large" onClick={onPrev}>
        {t('buttons.back')}
      </Button>
    </Form>
  );
}

export default RegisterStepSetAccountData;
