import React, { useContext } from 'react';

import { Skeleton } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ResponseNetworkError from '../../../../../components/Response/ResponseNetworkError';
import ResponseEmpty from '../../../../../components/Response/ResponseEmpty';
import OverviewTable from './OverviewTable';
import CourseContext from '../../../contexts/CourseContextProvider';

function SpecificOverviewContent({ state }) {
  const { t } = useTranslation(['course']);
  const { id } = useContext(CourseContext);
  const history = useHistory();

  function onAddExercise() {
    history.push(`/course/${id}/settings/exercises`, { addExercise: true });
  }

  function onAddSheet() {
    history.push(`/course/${id}/settings/sheets?add`, { addSheet: true });
  }

  if (state.loading) {
    return <Skeleton active />;
  }
  if (state.error) {
    return <ResponseNetworkError />;
  }

  if (state.exerciseCount === 0)
    return (
      <ResponseEmpty
        title={t('course:res.noExercise')}
        button={{ title: t('course:createExercise'), onClick: onAddExercise }}
      />
    );

  if (state.sheetCount === 0)
    return (
      <ResponseEmpty
        title={t('course:res.noSheet')}
        button={{ title: t('course:createSheet'), onClick: onAddSheet }}
      />
    );

  if (!state.selectedExercise || !state.selectedSheet) {
    return null;
  }

  return <OverviewTable state={state} />;
}

export default SpecificOverviewContent;
