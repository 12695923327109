import React from 'react';

function TabsWrapper({ children }) {
  return (
    <span>
      <div className="course-settings-wrapper">{children}</div>
    </span>
  );
}

export default TabsWrapper;
