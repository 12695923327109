/**
 * Important: if you change something at the theme, you also have to adjust the fallback version
 */

import React, { useState } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import Footer from '../../Footer';
import DropdownLng from '../../DropdownLng/DropdownLng';
import ModalFeedbackLoggedOut from '../../Modals/ModalFeedbackLoggedOut';

import './ContainerLoggedOut.less';

function ContainerLoggedOut({ children, title, withGoBack, image }) {
  const { t } = useTranslation();
  const location = useLocation();
  const [visibleFeedbackModal, setVisibleFeedbackModal] = useState(false);

  const layout = { left: { xs: 24, md: 12 }, right: { xs: 0, md: 12 } };

  function onOpenFeedbackModal() {
    setVisibleFeedbackModal(true);
  }

  function onCloseFeedbackModal() {
    setVisibleFeedbackModal(false);
  }

  return (
    <Row className="container-logged-out">
      <ModalFeedbackLoggedOut visible={visibleFeedbackModal} onCancel={onCloseFeedbackModal} />
      <Col {...layout.left} className="container-main">
        {withGoBack && (
          <div className="container-go-back padding-top-md padding-left-md">
            <Link to={{ pathname: '/login', state: location.state }}>
              <Button icon={<LeftOutlined />} size="large" shape="round">
                {t('toHome')}
              </Button>
            </Link>
          </div>
        )}
        <div className="container-lng padding-top-md padding-right-md">
          <Button type="link" onClick={onOpenFeedbackModal}>
            {t('contact')}
          </Button>
          <DropdownLng />
        </div>
        <div className="content">
          <div className="text-center">
            <img
              src="/assets/icons/cards-blue.svg"
              alt="FinVote Icon"
              className="logo margin-bottom-sm"
            />
            <h1 className="margin-bottom-lg">{title}</h1>
          </div>
          {children}
        </div>
        <Footer />
      </Col>
      <Col {...layout.right} className="container-description">
        <div className="content">
          <img src={image} className="image" alt="description" />
        </div>
      </Col>
    </Row>
  );
}

export function ContainerLoggedOutFallback() {
  const layout = { left: { xs: 24, md: 12 }, right: { xs: 0, md: 12 } };

  return (
    <Row className="container-logged-out">
      <Col {...layout.left} className="container-main">
        <div className="content">
          <div className="text-center">
            <img
              src="/assets/icons/cards-blue.svg"
              alt="FinVote Icon"
              className="logo margin-bottom-sm"
            />
          </div>
        </div>
      </Col>
      <Col {...layout.right} className="container-description" />
    </Row>
  );
}

export default ContainerLoggedOut;
