import React, { useState } from 'react';

import { Modal, Input, Form, Button, message, Alert } from 'antd';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { UPDATE_NOTE_MUTATION } from '../../../../../helper/api/mutations';
import { updatCacheNote } from '../../../../../helper/cacheUpdate';

function ModalEditNoteForm({ note, onCancel, userId, sheetId }) {
  const { t } = useTranslation(['common', 'course']);
  const [showDeleteNote, setShowDeleteNote] = useState(!!note);
  const [form] = Form.useForm();

  const [updateNote, { loading, error }] = useMutation(UPDATE_NOTE_MUTATION, {
    onCompleted,
    onError
  });

  function onCompleted() {
    message.success(t('course:modal.editNote.res.saved'));
    onCancel();
  }

  function onError() {
    message.success(t('res.error.unknownError'));
  }

  function onSave() {
    const newNote = form.getFieldValue('note');

    if (newNote !== note) {
      updateNote({
        variables: { note: newNote, user: userId, sheet: sheetId },
        update: updatCacheNote({ userId, sheetId }, { note: newNote })
      });
    } else {
      onCancel();
    }
  }

  function onChangeNote({ note: noteInternal }) {
    if (noteInternal && noteInternal.length > 0 && !showDeleteNote) {
      setShowDeleteNote(true);
    } else if ((!noteInternal || noteInternal.length === 0) && showDeleteNote) {
      setShowDeleteNote(false);
    }
  }

  function resetNote() {
    form.setFieldsValue({ note: '' });
    onSave('');
  }

  return (
    <Form form={form} initialValues={{ note }} onValuesChange={onChangeNote}>
      <h3>{t('course:modal.editNote.title')}</h3>
      {error && (
        <Alert
          className="margin-bottom-md"
          description={t('res.error.unknownErrorAlert')}
          type="error"
          closable
        />
      )}
      <Form.Item
        label={t('comment')}
        name="note"
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        help={(
          <Button type="link" style={{ padding: 0 }} onClick={resetNote} disabled={!showDeleteNote}>
            {t('course:modal.editNote.delete')}
          </Button>
        )}
      >
        <Input.TextArea allowClear autoFocus placeholder={t('comment')} />
      </Form.Item>

      <div className="text-right">
        <span className="padding-right-sm">
          <Button onClick={onCancel}>{t('buttons.cancel')}</Button>
        </span>
        <Button type="primary" loading={loading} disabled={loading} onClick={onSave}>
          {t('buttons.save')}
        </Button>
      </div>
    </Form>
  );
}

function ModalEditNote({ visible, onCancel, note, userId, sheetId }) {
  return (
    <Modal footer={null} visible={visible} onCancel={onCancel} closable destroyOnClose>
      <ModalEditNoteForm note={note} onCancel={onCancel} userId={userId} sheetId={sheetId} />
    </Modal>
  );
}

export default ModalEditNote;
