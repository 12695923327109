import React from 'react';

import { Modal, Form, Input, Button, message, Alert } from 'antd';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { FEEDBACK_MUTATION } from '../../helper/api/mutations';

function ModalFeedbackLoggedOutContent({ onCancel }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [feedback, { loading, error }] = useMutation(FEEDBACK_MUTATION, { onCompleted, onError });

  function onCompleted() {
    onCancel();
    message.success(t('modal.feedback.res.thanks', { context: 'loggedOut' }));
  }

  function onError() {}

  function onSave() {
    return form.validateFields().then((values) =>
      feedback({
        variables: {
          type: 'OTHER',
          category: 'OTHER',
          message: values.message,
          platformType: 'web',
          email: values.email,
          useEmail: false,
          platformVersion: process.env.REACT_APP_SENTRY_RELEASE
        }
      })
    );
  }

  return (
    <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <h2>{t('modal.feedback.title', { context: 'loggedOut' })}</h2>
      {error && (
        <Alert
          className="margin-bottom-md"
          description={t('res.error.unknownErrorAlert')}
          type="error"
          closable
        />
      )}
      <Form.Item
        name="email"
        validateTrigger="onBlur"
        label={t('yourEmail')}
        rules={[{ type: 'email', message: t('validation.invalidEmail') }]}
        extra={t('modal.feedback.optionalEmail')}
      >
        <Input placeholder={t('yourEmail')} type="email" />
      </Form.Item>
      <Form.Item
        name="message"
        validateTrigger="onBlur"
        label={t('modal.feedback.yourMessage')}
        rules={[{ required: true, message: t('modal.feedback.validation.emptyMessage') }]}
      >
        <Input.TextArea placeholder={t('modal.feedback.yourMessage')} />
      </Form.Item>
      <div className="text-right padding-top-md">
        <span className="padding-right-sm">
          <Button onClick={onCancel}>{t('buttons.close')}</Button>
        </span>
        <Button type="primary" onClick={onSave} loading={loading} disabled={loading}>
          {t('modal.feedback.okButton', { context: 'loggedOut' })}
        </Button>
      </div>
    </Form>
  );
}

function ModalFeedbackLoggedOut({ visible, onCancel }) {
  return (
    <Modal visible={visible} onCancel={onCancel} closable footer={null} destroyOnClose>
      <ModalFeedbackLoggedOutContent onCancel={onCancel} />
    </Modal>
  );
}

export default ModalFeedbackLoggedOut;
