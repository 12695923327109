import React from 'react';
import { Result, Button } from 'antd';

function ResponseEmpty({ button, imgSrc, ...props }) {
  return (
    <Result
      {...props}
      icon={(
        <img
          src={imgSrc || '/assets/icons/empty.svg'}
          alt="Empty Result"
          style={{ width: '100%', maxWidth: 500 }}
        />
      )}
      extra={
        button && (
          <Button type="primary" onClick={button.onClick}>
            {button.title}
          </Button>
        )
      }
    />
  );
}

export default React.memo(ResponseEmpty);
