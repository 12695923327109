import React from 'react';

import { Form, Input, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';
import { ACTIONS_CREATE_COURSE } from '../reducer';
import StepsWrapper from '../components/StepsWrapper';

function MainInformation({ state, disptach }) {
  const { t } = useTranslation(['common', 'createCourse']);
  const history = useHistory();
  const [form] = Form.useForm();

  function onSubmit() {
    return form
      .validateFields()
      .then((values) =>
        disptach({
          type: ACTIONS_CREATE_COURSE.SET_MAIN_INFORMATION,
          name: values.name,
          minVotes: values.minVotes,
          minPresentations: values.minPresentations
        })
      )
      .catch();
  }

  function onCancel() {
    history.goBack();
  }

  return (
    <StepsWrapper
      title={t('createCourse:courseInformation')}
      onSubmit={onSubmit}
      onPrev={onCancel}
      prevText={t('buttons.cancel')}
    >
      <Form
        form={form}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{
          name: state.name,
          minVotes: state.minVotes,
          minPresentations: state.minPresentations
        }}
      >
        <Form.Item
          hasFeedback
          name="name"
          validateTrigger="onBlur"
          label={t('courseName')}
          rules={[{ required: true, message: t('validation.emptyCourseName') }]}
        >
          <Input autoFocus placeholder={t('courseName')} />
        </Form.Item>
        <Form.Item
          extra={t('createCourse:mainInformation.laterEditable')}
          name="minVotes"
          validateTrigger="onBlur"
          validateFirst
          label={t('minVote')}
          rules={[
            {
              validator: (_, value) => {
                const minVotes = parseInt(value, 10);
                if (Number.isNaN(minVotes)) {
                  return Promise.reject(t('validation.invalidMinVotePattern'));
                }
                if (minVotes < 0 || minVotes > 100) {
                  return Promise.reject(t('validation.invalidMinVoteRange'));
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input style={{ width: '100%' }} placeholder={t('minVote')} addonAfter="%" />
        </Form.Item>
        <Form.Item
          extra={t('createCourse:mainInformation.laterEditable')}
          name="minPresentations"
          validateTrigger="onBlur"
          label={t('minPresentations')}
          rules={[{ pattern: /^\d+$/, message: t('validation.invalidMinPresentation') }]}
        >
          <InputNumber style={{ width: '100%' }} placeholder={t('minPresentations')} />
        </Form.Item>
      </Form>
    </StepsWrapper>
  );
}

export default MainInformation;
