import React, { useContext, useState, useEffect } from 'react';

import { Form, Select, Button, Tooltip, Col, Row } from 'antd';
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  QrcodeOutlined,
  DeleteOutlined,
  ExportOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined
} from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import ContainerShadow from '../../../../../../components/Container/ContainerShadow/ContainerShadow';
import VotingSchedule from '../VotingSchedule';
import ModalInvite from '../ModalInvite/ModalInvite';
import { COURSE_OVERVIEW_MENU_QUERY } from '../../../../../../helper/api/queries';
import CourseContext from '../../../../contexts/CourseContextProvider';
import ButtonResetCourse from '../ButtonResetCourse';
import ButtonExport from '../../../../components/ButtonExport';
import MetaInformation from '../../../../../../components/MetaInformation/MetaInformation';
import DAYS from '../../../../../../constants/DAYS';

import './ContainerSelection.less';
import StorageContext from '../../../../../../contexts/StorageContextProvider';

const ExerciseItem = React.memo(({ exercise }) => {
  const { t } = useTranslation(['common']);

  const infos = [];
  if (exercise.dayOfWeek && exercise.startTime && exercise.endTime) {
    infos.push({
      icon: ClockCircleOutlined,
      info: `${DAYS[exercise.dayOfWeek]}. ${exercise.startTime.substr(
        0,
        5
      )} - ${exercise.endTime.substr(0, 5)}`
    });
  }

  if (exercise.location) {
    infos.push({ icon: EnvironmentOutlined, info: exercise.location });
  }

  if (infos.length === 0) {
    return (
      <>
        {exercise.name}
        <div className="text-secondary">{t('noMoreInfo')}</div>
      </>
    );
  }

  return (
    <>
      {exercise.name}
      <MetaInformation infos={infos} style={{ paddingBottom: 0, minHeight: 25 }} />
    </>
  );
});

function ContainerSelection({
  init,
  onChangeSelectedExercise,
  onChangeSelectedSheet,
  togglePrivacyMode,
  state
}) {
  const selectedSheetId = state.selectedSheet?.id;
  const selectedExerciseId = state.selectedExercise?.id;

  const { getItem, setItem } = useContext(StorageContext);
  const { t } = useTranslation(['common', 'course']);
  const { id, name } = useContext(CourseContext);
  const [stateInviteCodeModal, setStateInviteCodeModal] = useState({ visible: false });

  const { data, loading } = useQuery(COURSE_OVERVIEW_MENU_QUERY, {
    variables: { id },
    onError,
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    if (data) {
      const [{ value: initalExercise }, { value: initialSheet }] = getItem(
        `course-${id}-initialExercise`,
        `course-${id}-initialSheet`
      );

      const sheet = data.course.sheets.find((e) => e.id === initialSheet) || data.course.sheets[0];
      const exercise =
        data.course.exercises.find((e) => e.id === initalExercise) || data.course.exercises[0];

      init(sheet, exercise, data.course.sheets.length, data.course.exercises.length);
    }
  }, [data]);

  function onError() {
    init(undefined, undefined, 0, 0, true);
  }

  function onShowInviteCodeModal() {
    setStateInviteCodeModal({
      visible: true,
      title: `${name} - ${state.selectedExercise.name}`,
      inviteCode: state.selectedExercise.inviteCode
    });
  }

  function onCloseInviteCodeModal() {
    setStateInviteCodeModal({ visible: false });
  }

  function onChangeSelectedExerciseInternal(exerciseId) {
    const exercise = data.course.exercises.find((e) => e.id === exerciseId);
    onChangeSelectedExercise(exercise);
    setItem({ item: `course-${id}-initialExercise`, value: exercise.id });
  }

  function onChangeSelectedSheetInternal(sheetId) {
    const sheet = data.course.sheets.find((e) => e.id === sheetId);
    onChangeSelectedSheet(sheet);
    setItem({ item: `course-${id}-initialSheet`, value: sheet.id });
  }

  return (
    <>
      <ContainerShadow className="container-overview-selection padding-top-sm padding-right-md padding-bottom-sm padding-left-md margin-bottom-md">
        <Row gutter={[15, 0]}>
          <Col xs={24} sm={12} lg={6}>
            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label={t('exercise')}>
              <Select
                loading={loading}
                disabled={loading}
                value={selectedExerciseId}
                onChange={onChangeSelectedExerciseInternal}
                placeholder={t('course:selectExercise')}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ minWidth: 300 }}
              >
                {(data?.course.exercises || []).map((exercise) => (
                  <Select.Option key={exercise.id} value={exercise.id}>
                    <ExerciseItem exercise={exercise} />
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label={t('sheet')}>
              <Select
                loading={loading}
                disabled={loading}
                value={selectedSheetId}
                onChange={onChangeSelectedSheetInternal}
                placeholder={t('course:selectSheet')}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ minWidth: 300 }}
              >
                {(data?.course.sheets || []).map((sheet) => (
                  <Select.Option key={sheet.id} value={sheet.id}>
                    {sheet.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={6} className="responsive-margin-top">
            <VotingSchedule
              loading={loading}
              exerciseId={selectedExerciseId}
              sheetId={selectedSheetId}
            />
          </Col>
          <Col xs={24} sm={12} lg={6} className="text-right responsive-margin-top">
            <Tooltip title={state.privacyMode ? t('course:privacy.off') : t('course:privacy.on')}>
              <Button
                icon={state.privacyMode ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                shape="circle"
                size="large"
                disabled={loading}
                onClick={togglePrivacyMode}
              />
            </Tooltip>
            <Tooltip title={t('course:tooltip.showInviteCode')}>
              <Button
                icon={<QrcodeOutlined />}
                shape="circle"
                size="large"
                disabled={loading || !state.selectedExercise}
                onClick={onShowInviteCodeModal}
                style={{ marginLeft: 10 }}
              />
            </Tooltip>
            <ButtonExport
              courseId={id}
              exerciseId={selectedExerciseId}
              sheetId={selectedSheetId}
              icon={<ExportOutlined />}
              shape="circle"
              size="large"
              style={{ marginLeft: 10 }}
            />
            <ButtonResetCourse
              exerciseId={selectedExerciseId}
              sheetId={selectedSheetId}
              icon={<DeleteOutlined />}
              shape="circle"
              size="large"
              style={{ marginLeft: 10 }}
            />
          </Col>
        </Row>
      </ContainerShadow>
      <ModalInvite
        visible={stateInviteCodeModal.visible}
        onCancel={onCloseInviteCodeModal}
        title={stateInviteCodeModal.title}
        inviteCode={stateInviteCodeModal.inviteCode}
      />
    </>
  );
}

export default ContainerSelection;
