import React from 'react';

import { Tooltip } from 'antd';
import ContainerShadow from '../Container/ContainerShadow/ContainerShadow';

function PreviewWrapper({ title, extra, description }) {
  return (
    <ContainerShadow className="margin-bottom-md padding-top-sm padding-right-sm padding-bottom-sm padding-left-sm">
      <div style={{ display: 'flex', minWidth: 0 }}>
        <div style={{ flex: 1, overflow: 'hidden' }}>
          <Tooltip title={title}>
            <h4
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {title}
            </h4>
          </Tooltip>
        </div>
        <div>{extra}</div>
      </div>
      {description}
    </ContainerShadow>
  );
}

export default PreviewWrapper;
