import React, { useState } from 'react';

import { Form, Button, Modal, Alert, Slider, InputNumber } from 'antd';
import { PlusOutlined, StarOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { ACTIONS_CREATE_COURSE } from '../reducer';
import StepsWrapper from '../components/StepsWrapper';
import PreviewSheet from '../../../components/Preview/PreviewSheet';
import ModalSheet from '../../../components/Modals/ModalSheet';

function SmartSheetConfiguratorModal({ visible, onCancel, onSmartCreateSheets }) {
  const [sheetCount, setSheetCount] = useState(10);
  const [taskCount, setTaskCount] = useState(5);
  const [optionalTaskCount, setOptionalTaskCount] = useState(0);
  const { t } = useTranslation(['common', 'createCourse']);

  function onSmartCreateSheetsInternal() {
    onSmartCreateSheets(sheetCount, taskCount, optionalTaskCount);
  }

  return (
    <Modal visible={visible} closable onCancel={onCancel} footer={null}>
      <h3>{t('createCourse:smart.title')}</h3>
      <div className="text-secondary padding-bottom-sm">
        {t('createCourse:sheetDefinition.smart.desc')}
      </div>
      <h4>{t('createCourse:sheetDefinition.smart.countSheets')}</h4>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Slider min={1} max={30} onChange={setSheetCount} value={sheetCount} style={{ flex: 1 }} />
        <div className="padding-left-sm">
          <InputNumber min={1} max={30} value={sheetCount} onChange={setSheetCount} autoFocus />
        </div>
      </div>
      <h4>{t('createCourse:sheetDefinition.smart.countTasks')}</h4>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Slider min={1} max={15} onChange={setTaskCount} value={taskCount} style={{ flex: 1 }} />
        <div className="padding-left-sm">
          <InputNumber min={1} max={15} value={taskCount} onChange={setTaskCount} />
        </div>
      </div>
      <h4>{t('createCourse:sheetDefinition.smart.countOptionalTasks')}</h4>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Slider
          min={0}
          max={15}
          onChange={setOptionalTaskCount}
          value={optionalTaskCount}
          style={{ flex: 1 }}
        />
        <div className="padding-left-sm">
          <InputNumber min={0} max={15} value={optionalTaskCount} onChange={setOptionalTaskCount} />
        </div>
      </div>
      <div className="text-right padding-top-md">
        <span className="padding-right-sm">
          <Button onClick={onCancel}>{t('buttons.cancel')}</Button>
        </span>
        <Button type="primary" onClick={onSmartCreateSheetsInternal}>
          {t('buttons.create')}
        </Button>
      </div>
    </Modal>
  );
}

function SheetDefinition({ state, disptach, onPrev }) {
  const [smartConfiguratorState, setSmartConfiguratorState] = useState({
    alertVisible: state.sheets.length === 0,
    modalVisible: false
  });
  const [modalSheetState, setModalSheetState] = useState({ visible: false, values: {} });

  const { t } = useTranslation(['common', 'createCourse']);
  const [form] = Form.useForm();

  function onSubmit() {
    disptach({
      type: ACTIONS_CREATE_COURSE.SET_SHEET_INFORMATION,
      sheets: form.getFieldValue('sheets') || []
    });
  }

  const onSkip = onSubmit;

  function onAddSheet() {
    const sheets = form.getFieldValue('sheets') || [];
    const id = new Date().valueOf();
    setModalSheetState({
      visible: true,
      values: {
        id,
        name: `${t('sheetShort')} ${sheets.length + 1}`,
        tasks: [
          { name: `${t('task')} 1`, optional: false },
          { name: `${t('task')} 2`, optional: false },
          { name: `${t('task')} 3`, optional: false }
        ]
      }
    });
  }

  function onSmartCreateSheets(sheetCount, taskCount, optionalTaskCount) {
    const id = new Date().valueOf();
    form.setFieldsValue({
      sheets: new Array(sheetCount).fill(0).map((_value, sheetIndex) => ({
        id: id + sheetIndex,
        name: `${t('sheetShort')} ${sheetIndex + 1}`,
        tasks: [
          ...new Array(taskCount)
            .fill(0)
            .map((_, taskIndex) => ({ name: `${t('task')} ${taskIndex + 1}`, optional: false })),
          ...new Array(optionalTaskCount).fill(0).map((_, taskIndex) => ({
            name: `${t('task')} ${taskCount + taskIndex + 1}`,
            optional: true
          }))
        ]
      }))
    });

    setSmartConfiguratorState({ alertVisible: false, modalVisible: false });
  }

  function onEditSheet(values) {
    setModalSheetState({ visible: true, values });
  }

  function onCloseModalSheet() {
    setModalSheetState({ visible: false, values: {} });
  }

  function onCloseSmartSheetConfiguratorModal() {
    setSmartConfiguratorState({
      alertVisible: smartConfiguratorState.alertVisible,
      modalVisible: false
    });
  }

  function onOpenSmartSheetConfiguratorModal() {
    setSmartConfiguratorState({
      alertVisible: true,
      modalVisible: true
    });
  }

  function onOkModalSheet(values) {
    const sheets = form.getFieldValue('sheets') || [];
    const index = sheets.findIndex((sheet) => sheet.id === values.id);

    if (index >= 0) {
      form.setFieldsValue({
        sheets: [...sheets.slice(0, index), values, ...sheets.slice(index + 1)]
      });
    } else {
      form.setFieldsValue({
        sheets: [...sheets, values]
      });
      if (smartConfiguratorState.alertVisible) {
        setSmartConfiguratorState({ alertVisible: false, modalVisible: false });
      }
    }

    setModalSheetState({ visible: false, values: {} });
  }

  async function onDeleteSheet(id) {
    const sheets = form.getFieldValue('sheets') || [];
    const index = sheets.findIndex((sheet) => sheet.id === id);

    if (index >= 0) {
      form.setFieldsValue({
        sheets: [...sheets.slice(0, index), ...sheets.slice(index + 1)]
      });
      if (sheets.length === 1 && !smartConfiguratorState.alertVisible) {
        setSmartConfiguratorState({ alertVisible: true, modalVisible: false });
      }
    }
  }

  return (
    <StepsWrapper
      title={t('sheet')}
      description={t('createCourse:sheetDefinition.desc')}
      onSkip={onSkip}
      onPrev={onPrev}
      onSubmit={onSubmit}
    >
      {smartConfiguratorState.alertVisible && (
        <Alert
          type="info"
          icon={<StarOutlined />}
          showIcon
          message={(
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ flex: 1 }}>
                {t('createCourse:smart.teaser1')} 
                {' '}
                <b>{t('createCourse:smart.title')}</b>
                {' '}
                {t('createCourse:smart.teaser2')}
              </span>
              <Button type="primary" onClick={onOpenSmartSheetConfiguratorModal}>
                {t('createCourse:smart.startNow')}
              </Button>
            </div>
          )}
          className="margin-bottom-md"
        />
      )}
      <Button
        type="primary"
        style={{ width: '100%' }}
        onClick={onAddSheet}
        className="margin-bottom-md"
      >
        {t('createCourse:sheetDefinition.addSheet')}
        <PlusOutlined />
      </Button>

      <Form
        form={form}
        name="mainForm"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{
          sheets: state.sheets
        }}
      >
        <Form.Item
          shouldUpdate={(prevValues, curValues) => prevValues.sheets !== curValues.sheets}
          noStyle
        >
          {({ getFieldValue }) => {
            const sheets = getFieldValue('sheets') || [];
            return sheets.map((sheet) => (
              <PreviewSheet
                key={sheet.id}
                sheet={sheet}
                onEdit={onEditSheet}
                onDelete={onDeleteSheet}
              />
            ));
          }}
        </Form.Item>
        <ModalSheet
          visible={modalSheetState.visible}
          values={modalSheetState.values}
          onCancel={onCloseModalSheet}
          onOk={onOkModalSheet}
        />
        <SmartSheetConfiguratorModal
          visible={smartConfiguratorState.modalVisible}
          onCancel={onCloseSmartSheetConfiguratorModal}
          onSmartCreateSheets={onSmartCreateSheets}
        />
      </Form>
    </StepsWrapper>
  );
}

export default SheetDefinition;
