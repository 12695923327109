function isAllowed({
  userVoteCount,
  userPresentCount,
  userIllCount,
  minVotes,
  taskCount,
  minPresentations
}) {
  return (
    userVoteCount >= minVotes * (taskCount - userIllCount) && userPresentCount >= minPresentations
  );
}

export default isAllowed;
