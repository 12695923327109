import React from 'react';

import { Button } from 'antd';

function PrimaryButton({ disabled, style, ...props }) {
  return (
    <Button
      type="primary"
      disabled={disabled}
      {...props}
      style={
        disabled
          ? { backgroundColor: '#a1c8fe', borderColor: '#a1c8fe', color: '#fff', ...style }
          : style
      }
    />
  );
}

export default React.memo(PrimaryButton);
