import React from 'react';

import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ResponseAccessDenied({ title, button = {}, ...props }) {
  const history = useHistory();
  const { t } = useTranslation();

  function onBack() {
    history.goBack();
  }

  return (
    <Result
      {...props}
      icon={(
        <img
          src="/assets/icons/403.svg"
          alt="Access denied"
          style={{ width: '100%', maxWidth: 400 }}
        />
      )}
      title={t('res.error.403')}
      subTitle={title || t('res.error.403Addition')}
      extra={(
        <Button type="primary" onClick={button.onClick || onBack}>
          {button.title || t('buttons.back')}
        </Button>
      )}
    />
  );
}

export default React.memo(ResponseAccessDenied);
