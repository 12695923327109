import React from 'react';

import { Form, Input, message, Spin } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import MainContainer from '../components/MainContainer';
import PrimaryButton from '../../../components/PrimaryButton';

import { CHANGE_EMAIL_MUTATION } from '../../../helper/api/mutations';
import { ACCOUNT_QUERY } from '../../../helper/api/queries';
import ResponseNetworkError from '../../../components/Response/ResponseNetworkError';
import getErrorCode from '../../../helper/getErrorCode';

function EmailForm() {
  const { t } = useTranslation(['common', 'settings']);
  const { data, loading: loadingQuery, error: errorQuery } = useQuery(ACCOUNT_QUERY, {
    fetchPolicy: 'cache-and-network'
  });

  const [changeEmail, { loading: loadingMutation }] = useMutation(CHANGE_EMAIL_MUTATION, {
    onCompleted,
    onError
  });

  const [form] = Form.useForm();

  function onCompleted() {
    message.success(t('settings:email.res.updated'));
    form.resetFields();
  }

  function onError(error) {
    const code = getErrorCode(error);

    switch (code) {
      case 417:
        form.setFields([
          {
            errors: [t('res.error.emailInUse')],
            name: 'email'
          }
        ]);
        break;
      case 400:
      case 412:
      case 410:
      default:
        message.error(t('res.error.unknownError'));
        break;
    }
  }

  function onChangeEmail() {
    form
      .validateFields()
      .then((values) =>
        changeEmail({
          variables: { email: values.email }
        })
      )
      .catch(() => {});
  }

  if (loadingQuery) {
    return (
      <div style={{ width: '100%' }} className="text-center">
        <Spin />
      </div>
    );
  }

  if (errorQuery) {
    return <ResponseNetworkError />;
  }

  return (
    <Form
      form={form}
      initialValues={{ currentEmail: data.account.email }}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Form.Item hasFeedback name="currentEmail" label={t('settings:email.currentEmail')}>
        <Input
          type="email"
          autoComplete="email"
          placeholder={t('settings:email.currentEmail')}
          disabled
        />
      </Form.Item>
      <Form.Item
        hasFeedback
        name="email"
        validateTrigger="onBlur"
        label={t('settings:email.newEmail')}
        rules={[
          { required: true, message: t('validation.emptyEmail') },
          { type: 'email', message: t('validation.invalidEmail') },
          () => ({
            validator(_rule, value) {
              if (value === data.account.email) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject(t('settings:email.validation.sameEmail'));
              }
              return Promise.resolve();
            }
          })
        ]}
      >
        <Input type="email" autoComplete="email" placeholder={t('settings:email.newEmail')} />
      </Form.Item>
      <PrimaryButton
        htmlType="submit"
        style={{ width: '100%' }}
        disabled={loadingMutation}
        loading={loadingMutation}
        onClick={onChangeEmail}
      >
        {t('settings:email.updateEmail')}
      </PrimaryButton>
    </Form>
  );
}

function Email() {
  return (
    <MainContainer image="/assets/icons/settings-email.svg">
      <EmailForm />
    </MainContainer>
  );
}

export default Email;
