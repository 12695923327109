import React from 'react';

import { Form, Input, Select, Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import PrimaryButton from '../../../components/PrimaryButton';

import { ACTION_REGISTER_STEPS } from '../reducer';
import DEGREES from '../../../constants/DEGREES';

function RegisterStepSetStudentData({ state, dispatch, onPrev }) {
  const { t } = useTranslation(['common', 'register']);
  const [form] = Form.useForm();

  function onNext() {
    return form
      .validateFields()
      .then((values) =>
        dispatch({
          type: ACTION_REGISTER_STEPS.SET_STUDENT_DATA,
          matriculationNumber: values.matriculationNumber,
          study: values.study,
          degree: values.degree
        })
      )
      .catch(() => {});
  }

  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={{
        matriculationNumber: state.matriculationNumber,
        study: state.study,
        degree: state.degree || DEGREES[0].key
      }}
    >
      <Form.Item
        hasFeedback
        label={t('yourMatriculationNumber')}
        name="matriculationNumber"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: t('validation.emptyMatricelNumber') },
          { pattern: /^\d+$/, message: t('validation.invalidMatricelNumber') }
        ]}
      >
        <Input size="large" placeholder={t('yourMatriculationNumber')} autoFocus />
      </Form.Item>

      <Form.Item
        hasFeedback
        label={t('yourStudy')}
        name="study"
        validateTrigger="onBlur"
        rules={[{ required: true, message: t('validation.emptyStudy') }]}
      >
        <Input size="large" placeholder={t('yourStudy')} />
      </Form.Item>

      <Form.Item
        hasFeedback
        label={t('degree')}
        name="degree"
        validateTrigger="onBlur"
        rules={[{ required: true, message: t('validation.emptyDegree') }]}
      >
        <Select size="large" placeholder={t('yourDegree')}>
          {DEGREES.map((degree) => (
            <Select.Option key={degree.key} value={degree.key}>
              {degree.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <PrimaryButton style={{ width: '100%' }} size="large" htmlType="submit" onClick={onNext}>
        {t('buttons.next')} 
        {' '}
        <RightOutlined />
      </PrimaryButton>
      <Button type="link" style={{ width: '100%' }} size="large" onClick={onPrev}>
        {t('buttons.back')}
      </Button>
    </Form>
  );
}

export default RegisterStepSetStudentData;
