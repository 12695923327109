import React from 'react';

import { Menu, Select } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import useBreakpoint from '../../../../../hooks/useBreakpoint';
import ContainerShadow from '../../../../../components/Container/ContainerShadow/ContainerShadow';

function ResponsiveMenu({ menu, selected, onChange }) {
  const { toSmall } = useBreakpoint(767);

  function onChangeSelect(key) {
    onChange(key);
  }

  function onChangeMenu({ key }) {
    onChange(key);
  }

  if (toSmall) {
    return (
      <ContainerShadow className="margin-bottom-md">
        <Select
          style={{ width: '100%' }}
          value={selected}
          bordered={false}
          size="large"
          onChange={onChangeSelect}
          menuItemSelectedIcon
          suffixIcon={<MenuOutlined />}
        >
          {menu.map(({ key, icon: Icon, name }) => (
            <Select.Option key={key} value={key}>
              <Icon />
              &nbsp;&nbsp;
              {name}
            </Select.Option>
          ))}
        </Select>
      </ContainerShadow>
    );
  }

  return (
    <ContainerShadow>
      <Menu mode="inline" selectedKeys={[selected]} onClick={onChangeMenu}>
        {menu.map(({ key, icon: Icon, name }) => (
          <Menu.Item key={key}>
            <Icon />
            {name}
          </Menu.Item>
        ))}
      </Menu>
    </ContainerShadow>
  );
}

export default ResponsiveMenu;
