import gql from 'graphql-tag';

import {
  ACCOUNT_FRAGMENT,
  SHEET_FRAGMENT,
  EXERCISE_FRAGMENT,
  TEACHER_ROLE_FRAGMENT,
  STUDENT_NOTE_FRAGMENT,
  STUDENT_VOTE_FRAGMENT,
  DASHBOARD_COURSES_FRAGMENT,
  DASHBOARD_EXERCISE_FRAGMENT,
  DASHBOARD_INVITATION_FRAGMENT,
  STUDENT_SHEET_INFO_OVERVIEW_FRAGMENT,
  STUDENT_EXERCISE_SHEET_FRAGMENT,
  EXERCISE_STATISTIC_FRAGMENT
} from './fragments';

export const ACCOUNT_QUERY = gql`
  query account {
    account {
      ...UserData
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export const COURSE_SETTINGS_GENERAL_QUERY = gql`
  query courseSettingsGeneral($id: ID!) {
    course(id: $id) {
      id
      name
      minVotes
      minPresentations
    }
  }
`;

export const COURSE_SETTINGS_EXERCISES_QUERY = gql`
  query courseSettingsExercises($id: ID!) {
    course(id: $id) {
      id
      exercises {
        ...ExerciseData
      }
    }
  }
  ${EXERCISE_FRAGMENT}
`;

export const COURSE_SETTINGS_SHEETS_QUERY = gql`
  query courseSettingsExercises($id: ID!) {
    course(id: $id) {
      id
      sheets {
        ...SheetData
      }
    }
  }
  ${SHEET_FRAGMENT}
`;

export const COURSE_SETTINGS_TEACHERS_QUERY = gql`
  query courseSettingsExercises($id: ID!) {
    course(id: $id) {
      id
      invitations {
        email
      }
      teachers {
        node {
          id
          firstName
          lastName
          email
        }
        ...TeacherRole
      }
    }
  }
  ${TEACHER_ROLE_FRAGMENT}
`;

export const COURSE_OVERVIEW_MENU_QUERY = gql`
  query courseOverviewMenu($id: ID!) {
    course(id: $id) {
      id
      name
      sheets {
        id
        name
      }
      exercises {
        id
        name
        location
        dayOfWeek
        startTime
        endTime
        startVote
        endVote
        autoVotable
        votable
        inviteCode
      }
    }
  }
`;

export const EXERCISE_SHEET_VOTE_TIME = gql`
  query exerciseSheetVoteTime($exercise: ID!, $sheet: ID!) {
    exerciseSheet(exercise: $exercise, sheet: $sheet) {
      cursor
      votable
      closeTime
    }
  }
`;

export const EXERCISE_SHEET_QUERY = gql`
  query exerciseSheet($exercise: ID!, $sheet: ID!) {
    exerciseSheet(exercise: $exercise, sheet: $sheet) {
      cursor
      node {
        id
        tasks {
          id
          name
          optional
        }
      }
      students {
        ...StudentSheetInfoOverview
      }
    }
  }
  ${STUDENT_SHEET_INFO_OVERVIEW_FRAGMENT}
`;

export const COURSE_CONTEXT_QUERY = gql`
  query courseContext($id: ID!) {
    course(id: $id) {
      id
      name
      role
    }
  }
`;

export const COURSE_LOGS = gql`
  query courseLogs($id: ID!, $limit: Int) {
    course(id: $id) {
      id
      logs(limit: $limit) {
        user {
          id
          firstName
          lastName
        }
        action
        info
        timestamp
      }
    }
  }
`;

export const COURSE_STUDENTS_QUERY = gql`
  query courseStudents($id: ID!, $offset: Int, $limit: Int, $query: String) {
    course(id: $id) {
      id
      taskCount
      minPresentations
      minVotes
      students(offset: $offset, limit: $limit, query: $query) {
        node {
          id
          lastName
          firstName
        }
        exercise {
          id
        }
        voteCount
        presentCount
        illCount
      }
    }
  }
`;

export const COURSE_STUDENT_INFO_QUERY = gql`
  query courseStudentInfo($course: ID!, $user: ID!) {
    courseStudent(course: $course, user: $user) {
      cursor
      node {
        id
        firstName
        lastName
        email
        matriculationNumber
        study
        degree
      }
      voteCount
      presentCount
      illCount
      origin {
        minVotes
        minPresentations
        taskCount
      }
      exercise {
        id
      }
      sheets {
        user {
          id
        }
        sheet {
          id
          name
        }
        votes {
          task {
            id
            name
            optional
          }
          user {
            id
          }
          ...StudentVote
        }
        ...StudentNote
      }
    }
  }
  ${STUDENT_NOTE_FRAGMENT}
  ${STUDENT_VOTE_FRAGMENT}
`;

export const COURSE_STUDENT_INFO_EXERCISES_QUERY = gql`
  query courseStudentInfoExercises($id: ID!) {
    course(id: $id) {
      id
      exercises {
        id
        name
      }
    }
  }
`;

export const STUDENT_SHEET_VOTES_QUERY = gql`
  query studentSheetVotes($user: ID!, $sheet: ID!) {
    studentSheet(user: $user, sheet: $sheet) {
      votes {
        user {
          id
        }
        task {
          name
          id
        }
        ...StudentVote
      }
    }
  }
  ${STUDENT_VOTE_FRAGMENT}
`;

export const DASHBOARD_QUERY = gql`
  query account {
    account {
      courses {
        ...DashboardCourse
      }
      exercises {
        ...DashboardExercise
      }
      invitations {
        ...DashboardInvitation
      }
    }
  }
  ${DASHBOARD_COURSES_FRAGMENT}
  ${DASHBOARD_EXERCISE_FRAGMENT}
  ${DASHBOARD_INVITATION_FRAGMENT}
`;

export const STUDENT_EXERCISE_OVERVIEW = gql`
  query exerciseOverview($id: ID!) {
    exercise(id: $id) {
      id
      name
      location
      dayOfWeek
      startTime
      endTime
      votable
      ...ExerciseStatistic
      sheets {
        node {
          ...StudentExerciseSheetData
        }
      }
    }
  }
  ${STUDENT_EXERCISE_SHEET_FRAGMENT}
  ${EXERCISE_STATISTIC_FRAGMENT}
`;

export const VOTABLE_SHEETS_QUERY = gql`
  query exerciseOverview($id: ID!) {
    exercise(id: $id) {
      id
      name
      location
      dayOfWeek
      startTime
      endTime
      votable
      course {
        id
        name
      }
      sheets {
        votable
        node {
          id
          name
          votes {
            task {
              id
              name
              optional
            }
          }
        }
      }
    }
  }
`;
