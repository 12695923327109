import React from 'react';

import { Form, message, Spin, Row, Col, Switch, Divider } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import MainContainer from '../components/MainContainer';
import PrimaryButton from '../../../components/PrimaryButton';

import { CHANGE_NOTIFICATION_SETTINGS_MUTATION } from '../../../helper/api/mutations';
import { ACCOUNT_QUERY } from '../../../helper/api/queries';
import ResponseNetworkError from '../../../components/Response/ResponseNetworkError';

function NotificationsForm() {
  const { t } = useTranslation(['common', 'settings']);

  const { data, loading: loadingQuery, error: errorQuery } = useQuery(ACCOUNT_QUERY, {
    fetchPolicy: 'cache-and-network'
  });

  const [changeNotificationSettings, { loading: loadingMutation }] = useMutation(
    CHANGE_NOTIFICATION_SETTINGS_MUTATION,
    {
      onCompleted,
      onError
    }
  );

  const [form] = Form.useForm();

  function onCompleted() {
    message.success(t('settings:notification.res.save'));
    form.resetFields();
  }

  function onError() {
    message.error(t('res.error.unknownError'));
  }

  function onChangeNotifications() {
    form
      .validateFields()
      .then((values) =>
        changeNotificationSettings({
          variables: values
        })
      )
      .catch(() => {});
  }

  if (loadingQuery) {
    return (
      <div style={{ width: '100%' }} className="text-center">
        <Spin />
      </div>
    );
  }

  if (errorQuery) {
    return <ResponseNetworkError />;
  }

  return (
    <Form
      form={form}
      initialValues={{
        voteUpdate: { email: data.account.voteEmail, push: data.account.votePush },
        weekly: { email: data.account.weeklyEmail },
        endVotingPhase: { email: data.account.endVotingPhaseEmail }
      }}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Row className="margin-bottom-md">
        <Col span={24}>
          <h4 style={{ marginBottom: 0 }}>{t('settings:notification.voteNotification')}</h4>
          <div className="text-secondary margin-bottom-sm">
            {t('settings:notification.voteNotificationDesc')}
          </div>
        </Col>
        <Col span={8}>
          <b className="padding-right-sm">{t('settings:notification.email')}</b>
          <Form.Item name={['voteUpdate', 'email']} noStyle valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
        <Col span={8}>
          <b className="padding-right-sm">{t('settings:notification.push')}</b>
          <Form.Item name={['voteUpdate', 'push']} noStyle valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Divider />

      <Row className="margin-bottom-md">
        <Col span={24}>
          <h4 style={{ marginBottom: 0 }}>{t('settings:notification.weeklyNotification')}</h4>
          <div className="text-secondary margin-bottom-sm">
            {t('settings:notification.weeklyNotificationDesc')}
          </div>
        </Col>
        <Col span={8}>
          <b className="padding-right-sm">{t('settings:notification.email')}</b>
          <Form.Item name={['weekly', 'email']} noStyle valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Divider />

      <Row className="margin-bottom-md">
        <Col span={24}>
          <h4 style={{ marginBottom: 0 }}>{t('settings:notification.endVotingNotification')}</h4>
          <div className="text-secondary margin-bottom-sm">
            {t('settings:notification.endVotingNotificationDesc')}
          </div>
        </Col>
        <Col span={8}>
          <b className="padding-right-sm">{t('settings:notification.email')}</b>
          <Form.Item name={['endVotingPhase', 'email']} noStyle valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
      </Row>

      <PrimaryButton
        htmlType="submit"
        style={{ width: '100%' }}
        disabled={loadingMutation}
        loading={loadingMutation}
        onClick={onChangeNotifications}
      >
        {t('settings:notification.updateNotification')}
      </PrimaryButton>
    </Form>
  );
}

function Notifications() {
  return (
    <MainContainer image="/assets/icons/settings-notifications.svg">
      <NotificationsForm />
    </MainContainer>
  );
}

export default Notifications;
