import React, { useContext } from 'react';

import { Modal, Button, Input, Form, message } from 'antd';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { INVITE_TEACHER_MUTATION } from '../../../../../../../helper/api/mutations';
import { updateCacheInviteTeacher } from '../../../../../../../helper/cacheUpdate';
import CourseContext from '../../../../../contexts/CourseContextProvider';
import getErrorCode from '../../../../../../../helper/getErrorCode';

function NewUserModalForm({ onCancel }) {
  const { t } = useTranslation(['common', 'course']);
  const { id } = useContext(CourseContext);
  const [form] = Form.useForm();

  const [inviteTeacher, { loading }] = useMutation(INVITE_TEACHER_MUTATION, {
    onError,
    onCompleted
  });

  function onSave() {
    return form
      .validateFields()
      .then(({ email }) =>
        inviteTeacher({
          variables: { course: id, email },
          update: updateCacheInviteTeacher({ courseId: id }, (result) => ({
            invite: result.data.course.invite
          }))
        })
      )
      .catch(() => {});
  }

  function onCompleted() {
    message.success(t('course:res.teacherInvited'));
    onCancel();
  }

  function onError(error) {
    const code = getErrorCode(error);
    switch (code) {
      case 409:
        form.setFields([
          {
            name: 'email',
            errors: [t('course:validation.userAlreadyMember')]
          }
        ]);
        break;
      case 412:
        form.setFields([
          {
            name: 'email',
            errors: [t('course:validation.userAlreadyInvite')]
          }
        ]);
        break;
      default:
        form.setFields([
          {
            name: 'email',
            errors: [t('res.error.unknownError')]
          }
        ]);
    }
  }

  return (
    <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <h3>{t('addTeacher')}</h3>
      <Form.Item
        name="email"
        validateTrigger="onBlur"
        label={t('email')}
        hasFeedback
        rules={[
          { required: true, message: t('validation.emptyEmail') },
          { type: 'email', message: t('validation.invalidEmail') }
        ]}
      >
        <Input type="email" autoFocus placeholder={t('email')} />
      </Form.Item>
      <div className="text-right padding-top-md">
        <span className="padding-right-sm">
          <Button onClick={onCancel}>{t('buttons.cancel')}</Button>
        </span>
        <Button type="primary" onClick={onSave} loading={loading} disabled={loading}>
          {t('buttons.save')}
        </Button>
      </div>
    </Form>
  );
}

function NewUserModal({ visible, onCancel, onOk, error, loading }) {
  return (
    <Modal visible={visible} closable footer={null} onCancel={onCancel} destroyOnClose>
      <NewUserModalForm onCancel={onCancel} onOk={onOk} error={error} loading={loading} />
    </Modal>
  );
}

export default NewUserModal;
