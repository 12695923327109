import React, { useContext } from 'react';

import { Button, Modal, message, Col } from 'antd';
import { useMutation } from '@apollo/client';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TabsWrapper from '../components/TabsWrapper';
import { DELETE_COURSE_MUTATION } from '../../../../../helper/api/mutations';
import CourseContext from '../../../contexts/CourseContextProvider';

function Extended() {
  const { t } = useTranslation();
  const { id } = useContext(CourseContext);

  const history = useHistory();
  const [deleteAccount] = useMutation(DELETE_COURSE_MUTATION, {
    onCompleted,
    onError
  });

  function onCompleted() {
    message.success(t('course:res.deleteCourse'));
    history.replace('/');
  }

  function onError() {
    message.error('course:res.deleteCourseFail');
  }

  function onDeleteCours() {
    return deleteAccount({ variables: { id } });
  }

  function onShowConfirmation() {
    Modal.confirm({
      title: t('course:deleteCourse.title'),
      icon: <CloseCircleOutlined />,
      content: t('course:deleteCourse.sure'),
      okText: t('delete'),
      cancelText: t('buttons.cancel'),
      okButtonProps: { type: 'danger' },
      maskClosable: true,
      onOk: onDeleteCours,
      onCancel() {}
    });
  }

  return (
    <TabsWrapper>
      <Col xs={24} md={16}>
        <h3>{t('course:deleteCourse.title')}</h3>
        <span className="text-secondary">
          {t('course:deleteCourse.phrase1')} 
          {' '}
          <strong>{t('course:deleteCourse.phrase2')}</strong>
          {' '}
          {t('course:deleteCourse.phrase3')}
          <br />
          <br />
          {t('course:deleteCourse.phrase4')} 
          {' '}
          <strong>{t('course:deleteCourse.phrase5')}</strong>
          !
          <br />
          <br />
          {t('course:deleteCourse.phrase6')} 
          {' '}
          <strong>{t('course:deleteCourse.phrase7')}</strong>
          {' '}
          {t('course:deleteCourse.phrase8')}
        </span>
        <br />
        <br />
        <Button danger style={{ width: '100%' }} onClick={onShowConfirmation}>
          {t('delete')}
        </Button>
      </Col>
    </TabsWrapper>
  );
}

export default Extended;
