import React, { useEffect } from 'react';

import { Form, Input, message, Spin, Select } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import MainContainer from '../components/MainContainer';
import PrimaryButton from '../../../components/PrimaryButton';

import {
  CHANGE_STUDY_INFO_MUTATION,
  CHANGE_LANGUAGE_MUTATION
} from '../../../helper/api/mutations';
import { ACCOUNT_QUERY } from '../../../helper/api/queries';
import ResponseNetworkError from '../../../components/Response/ResponseNetworkError';
import DEGREES from '../../../constants/DEGREES';
import getErrorCode from '../../../helper/getErrorCode';

function GeneralForm() {
  const { t, i18n } = useTranslation(['common', 'settings']);
  const { data, loading: loadingQuery, error: errorQuery } = useQuery(ACCOUNT_QUERY, {
    fetchPolicy: 'cache-and-network'
  });

  const [changeStudyInfo, { loading: loadingChangeStudyInfo }] = useMutation(
    CHANGE_STUDY_INFO_MUTATION,
    {
      onCompleted,
      onError
    }
  );

  const [changeLanguage, { loading: loadingChangeLanguage }] = useMutation(
    CHANGE_LANGUAGE_MUTATION,
    {
      onCompleted,
      onError
    }
  );

  const [form] = Form.useForm();

  useEffect(() => {
    if (data?.account) {
      form.setFieldsValue({ language: data.account.language });
    }
  }, [data?.account]);

  async function onCompleted({ account }) {
    if (i18n.language !== account.changeLanguage.language) {
      await i18n.changeLanguage(account.changeLanguage.language);
    }
    message.success(t('settings:general.res.save'));
    form.resetFields();
  }

  function onError(error) {
    const code = getErrorCode(error);

    switch (code) {
      case 417:
        form.setFields([
          {
            errors: [t('res.error.matricelInUse')],
            name: 'matriculationNumber'
          }
        ]);
        break;
      default:
        message.error(t('res.error.unknownError'));
    }
  }

  function onSave() {
    form
      .validateFields()
      .then((values) => {
        if (values.study && values.degree && values.matriculationNumber) {
          return changeStudyInfo({
            variables: {
              study: values.study,
              degree: values.degree,
              matriculationNumber: values.matriculationNumber,
              language: values.language
            }
          });
        }
        return changeLanguage({ variables: { language: values.language } });
      })
      .catch(() => {});
  }

  if (loadingQuery) {
    return (
      <div style={{ width: '100%' }} className="text-center">
        <Spin />
      </div>
    );
  }

  if (errorQuery) {
    return <ResponseNetworkError />;
  }

  return (
    <Form
      form={form}
      initialValues={data.account}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Form.Item
        name="firstName"
        validateTrigger="onBlur"
        label={t('firstName')}
        rules={[{ required: true, message: t('validation.emptyFirstName') }]}
      >
        <Input type="text" autoComplete="given-name" disabled />
      </Form.Item>
      <Form.Item
        name="lastName"
        validateTrigger="onBlur"
        label={t('lastName')}
        rules={[{ required: true, message: t('validation.emptyLastName') }]}
      >
        <Input type="text" autoComplete="family-name" disabled />
      </Form.Item>
      <Form.Item
        name="language"
        label={t('preferedLanguage')}
        rules={[{ required: true, message: t('validation.emptyLanguage') }]}
      >
        <Select placeholder={t('preferedLanguage')}>
          <Select.Option value="DE">{t('lang.de')}</Select.Option>
          <Select.Option value="EN">{t('lang.en')}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="matriculationNumber"
        validateTrigger="onBlur"
        label={t('yourMatriculationNumber')}
        dependencies={['study', 'degree']}
        rules={[
          {
            required: !!data.account.matriculationNumber,
            message: t('validation.emptyMatricelNumber')
          },
          { pattern: /^\d+$/, message: t('validation.invalidMatricelNumber') },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value && (getFieldValue('study') || getFieldValue('degree'))) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject(t('validation.emptyMatricelNumber'));
              }
              return Promise.resolve();
            }
          })
        ]}
      >
        <Input type="number" placeholder={t('yourMatriculationNumber')} />
      </Form.Item>
      <Form.Item
        name="study"
        validateTrigger="onBlur"
        label={t('yourStudy')}
        dependencies={['matriculationNumber', 'degree']}
        validateFirst
        rules={[
          {
            required: !!data.account.study,
            message: t('validation.emptyStudy')
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value && (getFieldValue('matriculationNumber') || getFieldValue('degree'))) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject(t('validation.emptyStudy'));
              }
              return Promise.resolve();
            }
          })
        ]}
      >
        <Input placeholder={t('yourStudy')} />
      </Form.Item>
      <Form.Item
        name="degree"
        label={t('degree')}
        dependencies={['study', 'matriculationNumber']}
        rules={[
          {
            required: !!data.account.degree,
            message: t('validation.emptyDegree')
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value && (getFieldValue('study') || getFieldValue('matriculationNumber'))) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject(t('validation.emptyDegree'));
              }
              return Promise.resolve();
            }
          })
        ]}
      >
        <Select>
          {DEGREES.map((degree) => (
            <Select.Option key={degree.key} value={degree.key}>
              {degree.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <PrimaryButton
        htmlType="submit"
        style={{ width: '100%' }}
        disabled={loadingChangeStudyInfo || loadingChangeLanguage}
        loading={loadingChangeStudyInfo || loadingChangeLanguage}
        onClick={onSave}
      >
        {t('settings:general.updateGeneral')}
      </PrimaryButton>
    </Form>
  );
}

function General() {
  return (
    <MainContainer image="/assets/icons/settings-general.svg">
      <GeneralForm />
    </MainContainer>
  );
}

export default General;
