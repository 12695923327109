import React from 'react';

import { Badge, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

function BadgePresented({ presented }) {
  const { t } = useTranslation();
  if (presented) {
    return (
      <Tooltip title={t('voteState.presented')}>
        <Badge status="success" />
      </Tooltip>
    );
  }
  return (
    <Tooltip title={t('voteState.notPresented')}>
      <Badge status="default" />
    </Tooltip>
  );
}

export default React.memo(BadgePresented);
