import React, { useState } from 'react';

import {
  EnvironmentOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined
} from '@ant-design/icons';
import { Popconfirm, Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import DAYS from '../../constants/DAYS';
import MetaInformation from '../MetaInformation/MetaInformation';
import PreviewWrapper from './PreviewWrapper';

function PreviewExercise({ exercise, onEdit, onDelete }) {
  const [loadingDelete, setLoadingDelete] = useState(false);

  const { t } = useTranslation();

  const infos = [];

  if (exercise.location) {
    infos.push({
      icon: EnvironmentOutlined,
      info: exercise.location
    });
  }

  if (exercise.dayOfWeek && exercise.startTime && exercise.endTime) {
    infos.push({
      icon: ClockCircleOutlined,
      info: `${DAYS[exercise.dayOfWeek]}. ${exercise.startTime.substr(
        0,
        5
      )}-${exercise.endTime.substr(0, 5)}`
    });
  }

  if (exercise.startVote && exercise.endVote) {
    infos.push({
      icon: CheckCircleOutlined,
      info: `${exercise.startVote.substr(0, 5)}-${exercise.endVote.substr(0, 5)}`
    });
  }

  function onConfirmDeletion() {
    setLoadingDelete(true);
    return onDelete(exercise.id).finally(() => setLoadingDelete(false));
  }

  function onEditInternal() {
    onEdit(exercise);
  }

  return (
    <PreviewWrapper
      title={exercise.name}
      extra={(
        <span>
          {onEdit && (
            <Tooltip title={t('preview.exercise.tooltip.editExercise')}>
              <Button shape="circle" icon={<EditOutlined />} onClick={onEditInternal} />
            </Tooltip>
          )}
          {onDelete && (
            <Tooltip title={t('preview.exercise.tooltip.deleteExercise')}>
              <Popconfirm
                okText={t('delete')}
                cancelText={t('buttons.cancel')}
                okType="danger"
                title={`${t('preview.exercise.tooltip.deleteExercise')}?`}
                onConfirm={onConfirmDeletion}
              >
                <Button
                  type="danger"
                  shape="circle"
                  loading={loadingDelete}
                  icon={<DeleteOutlined />}
                  style={{ marginLeft: 10 }}
                />
              </Popconfirm>
            </Tooltip>
          )}
        </span>
      )}
      description={
        infos.length > 0 ? (
          <MetaInformation infos={infos} style={{ paddingBottom: 0 }} />
        ) : (
          <div className="text-secondary">{t('noMoreInfo')}</div>
        )
      }
    />
  );
}

export default PreviewExercise;
