import React from 'react';

import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

function TagPresented({ presented }) {
  const { t } = useTranslation();

  if (presented) {
    return <Tag color="success">{t('voteState.presented')}</Tag>;
  }
  return null;
}

export default React.memo(TagPresented);
