import React, { useState } from 'react';

import { Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { CHANGE_LANGUAGE_MUTATION } from '../../helper/api/mutations';

import GermanSvg from './flags/de.svg';
import EnglishSvg from './flags/en.svg';

function DropdownLng({ sendToBackend, className }) {
  const { t, i18n } = useTranslation();
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [changeLanguage] = useMutation(CHANGE_LANGUAGE_MUTATION, {
    onCompleted,
    onError
  });

  function onCompleted() {}

  function onError() {}

  function onChangeLanguage({ key }) {
    i18n.changeLanguage(key.toLowerCase());
    setVisibleDropdown(false);
    if (sendToBackend) {
      return changeLanguage({ variables: { language: key } });
    }
    return undefined;
  }

  function onVisibleChange(visible) {
    setVisibleDropdown(visible);
  }

  const LanguageDropdown = (
    <Menu selectedKeys={[i18n.language]}>
      <Menu.Item onClick={onChangeLanguage} key="DE">
        <img src={GermanSvg} alt="de" className="flag" /> 
        {' '}
        {t('lang.de')}
      </Menu.Item>
      <Menu.Item onClick={onChangeLanguage} key="EN">
        <img src={EnglishSvg} alt="en" className="flag" /> 
        {' '}
        {t('lang.en')}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={LanguageDropdown}
      visible={visibleDropdown}
      onVisibleChange={onVisibleChange}
      overlayClassName="dropdown-shadow"
      placement="bottomRight"
    >
      <span className={className}>
        <span className="padding-right-md padding-left-md">
          {i18n.language === 'de' ? (
            <img src={GermanSvg} alt="de" className="flag" />
          ) : (
            <img src={EnglishSvg} alt="en" className="flag" />
          )}
        </span>
      </span>
    </Dropdown>
  );
}

export default DropdownLng;
