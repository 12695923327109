import React from 'react';

import { useQuery } from '@apollo/client';

import { COURSE_CONTEXT_QUERY } from '../../../helper/api/queries';

const CourseContext = React.createContext({});

export function CourseContextProvider({ courseId, children }) {
  const { data, loading, error } = useQuery(COURSE_CONTEXT_QUERY, {
    variables: { id: courseId },
    fetchPolicy: 'cache-and-network'
  });

  const saveData = data?.course || {};

  return (
    <CourseContext.Provider value={{ ...saveData, loading, error }}>
      {children}
    </CourseContext.Provider>
  );
}

export default CourseContext;
