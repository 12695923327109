import update from 'immutability-helper';

import {
  STUDENT_VOTE_FRAGMENT,
  TEACHER_ROLE_FRAGMENT,
  STUDENT_NOTE_FRAGMENT
} from './api/fragments';
import {
  COURSE_SETTINGS_EXERCISES_QUERY,
  COURSE_SETTINGS_SHEETS_QUERY,
  COURSE_SETTINGS_TEACHERS_QUERY,
  COURSE_STUDENTS_QUERY,
  DASHBOARD_QUERY
} from './api/queries';

export function updateCacheVotePresentation({ userId, taskId }, { state, presented }) {
  return (cache) => {
    // TODO: update the counts
    const dataCache = cache.readFragment({
      fragment: STUDENT_VOTE_FRAGMENT,
      id: `Vote-${userId}-${taskId}`
    });
    cache.writeFragment({
      fragment: STUDENT_VOTE_FRAGMENT,
      id: `Vote-${userId}-${taskId}`,
      data: update(dataCache, {
        state: { $set: state || dataCache.state },
        presented: { $set: presented }
      })
    });
  };
}

export function updateCacheTeacherRole({ teacherId }, { role }) {
  return (cache) => {
    const dataCache = cache.readFragment({
      fragment: TEACHER_ROLE_FRAGMENT,
      id: `CourseTeacherEdge-${teacherId}`
    });
    cache.writeFragment({
      fragment: TEACHER_ROLE_FRAGMENT,
      id: `CourseTeacherEdge-${teacherId}`,
      data: update(dataCache, { role: { $set: role } })
    });
  };
}

export function updatCacheNote({ userId, sheetId }, { note }) {
  return (cache) => {
    const dataCache = cache.readFragment({
      fragment: STUDENT_NOTE_FRAGMENT,
      id: `StudentSheetInfo-${userId}-${sheetId}`
    });
    cache.writeFragment({
      fragment: STUDENT_NOTE_FRAGMENT,
      id: `StudentSheetInfo-${userId}-${sheetId}`,
      data: update(dataCache, { note: { $set: note } })
    });
  };
}

export function updateCacheRemoveExercise({ courseId }, { exerciseId }) {
  return (cache) => {
    const dataCache = cache.readQuery({
      query: COURSE_SETTINGS_EXERCISES_QUERY,
      variables: { id: courseId }
    });

    const index = dataCache.course.exercises.findIndex((exercise) => exercise.id === exerciseId);

    cache.writeQuery({
      query: COURSE_SETTINGS_EXERCISES_QUERY,
      variables: { id: courseId },
      data: update(dataCache, { course: { exercises: { $splice: [[index, 1]] } } })
    });
  };
}

export function updateCacheAddExercise({ courseId }, dataUpdate) {
  return (cache, result) => {
    const { exercise } = dataUpdate?.(result) || dataUpdate;

    const dataCacheSettings = cache.readQuery({
      query: COURSE_SETTINGS_EXERCISES_QUERY,
      variables: { id: courseId }
    });
    cache.writeQuery({
      query: COURSE_SETTINGS_EXERCISES_QUERY,
      variables: { id: courseId },
      data: update(dataCacheSettings, {
        course: { exercises: { $push: [exercise] } }
      })
    });
  };
}

export function updateCacheRemoveSheet({ courseId }, { sheetId }) {
  return (cache) => {
    const dataCache = cache.readQuery({
      query: COURSE_SETTINGS_SHEETS_QUERY,
      variables: { id: courseId }
    });

    const index = dataCache.course.sheets.findIndex((sheet) => sheet.id === sheetId);

    cache.writeQuery({
      query: COURSE_SETTINGS_SHEETS_QUERY,
      variables: { id: courseId },
      data: update(dataCache, { course: { sheets: { $splice: [[index, 1]] } } })
    });
  };
}

export function updateCacheAddSheet({ courseId }, dataUpdate) {
  return (cache, result) => {
    const { sheet } = dataUpdate?.(result) || dataUpdate;

    const dataCacheSettings = cache.readQuery({
      query: COURSE_SETTINGS_SHEETS_QUERY,
      variables: { id: courseId }
    });
    cache.writeQuery({
      query: COURSE_SETTINGS_SHEETS_QUERY,
      variables: { id: courseId },
      data: update(dataCacheSettings, { course: { sheets: { $push: [sheet] } } })
    });
  };
}

export function updateCacheInviteTeacher({ courseId: id }, dataUpdate) {
  return (cache, result) => {
    const { invite } = dataUpdate?.(result) || dataUpdate;

    const dataCache = cache.readQuery({
      query: COURSE_SETTINGS_TEACHERS_QUERY,
      variables: { id }
    });

    cache.writeQuery({
      query: COURSE_SETTINGS_TEACHERS_QUERY,
      variables: { id },
      data: update(dataCache, { course: { invitations: { $push: [invite] } } })
    });
  };
}

export function updateCacheKickTeacher({ courseId }, { teacherId }) {
  return (cache) => {
    const dataCache = cache.readQuery({
      query: COURSE_SETTINGS_TEACHERS_QUERY,
      variables: { id: courseId }
    });
    const index = dataCache.course.teachers.findIndex((e) => e.id === teacherId);
    cache.writeQuery({
      query: COURSE_SETTINGS_TEACHERS_QUERY,
      variables: { id: courseId },
      data: update(dataCache, { course: { teachers: { $splice: [[index, 1]] } } })
    });
  };
}

export function cacheUpdateDeleteInvitation({ courseId }, { email }) {
  return (cache) => {
    const dataCache = cache.readQuery({
      query: COURSE_SETTINGS_TEACHERS_QUERY,
      variables: { id: courseId }
    });

    const index = dataCache.course.invitations.findIndex((e) => e.email === email);

    cache.writeQuery({
      query: COURSE_SETTINGS_TEACHERS_QUERY,
      variables: { id: courseId },
      data: update(dataCache, { course: { invitations: { $splice: [[index, 1]] } } })
    });
  };
}

export function cacheUpdateKickStudent({ courseId, query }, { studentId }) {
  return (cache) => {
    const dataCache = cache.readQuery({
      query: COURSE_STUDENTS_QUERY,
      variables: { id: courseId, query }
    });

    const index = dataCache.course.students.findIndex((student) => student.node.id === studentId);

    cache.writeQuery({
      query: COURSE_STUDENTS_QUERY,
      variables: { id: courseId, query },
      data: update(dataCache, { course: { students: { $splice: [[index, 1]] } } })
    });
  };
}

export function cacheUpdateJoinCourse(_, dataUpdate) {
  return (cache, result) => {
    const { course } = dataUpdate?.(result) || dataUpdate;

    const dataCache = cache.readQuery({
      query: DASHBOARD_QUERY
    });

    const index = dataCache.account.invitations.findIndex(
      (invitation) => invitation.course.id === course.id
    );

    cache.writeQuery({
      query: DASHBOARD_QUERY,
      data: update(dataCache, {
        account: { invitations: { $splice: [[index, 1]] }, courses: { $push: [course] } }
      })
    });
  };
}

export function cacheUpdateRejectCourse(_, { courseId }) {
  return (cache) => {
    const dataCache = cache.readQuery({
      query: DASHBOARD_QUERY
    });

    const index = dataCache.account.invitations.findIndex(
      (invitation) => invitation.course.id === courseId
    );

    cache.writeQuery({
      query: DASHBOARD_QUERY,
      data: update(dataCache, {
        account: { invitations: { $splice: [[index, 1]] } }
      })
    });
  };
}

export function cacheUpdateJoinExercise(_, dataUpdate) {
  return (cache, result) => {
    const { exercise } = dataUpdate?.(result) || dataUpdate;

    const dataCache = cache.readQuery({
      query: DASHBOARD_QUERY
    });

    cache.writeQuery({
      query: DASHBOARD_QUERY,
      data: update(dataCache, {
        account: { exercises: { $push: [exercise] } }
      })
    });
  };
}
