import React, { useState } from 'react';

import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import ModalFeedbackFooter from './Modals/ModalFeedbackFooter';

function Footer() {
  const { t } = useTranslation();
  const [visibleFeedbackModal, setVisibleFeedbackModal] = useState(false);

  function onOpenFeedbackModal() {
    setVisibleFeedbackModal(true);
  }

  function onCloseFeedbackModal() {
    setVisibleFeedbackModal(false);
  }

  return (
    <div
      className="padding-left-md padding-right-md padding-top-sm padding-bottom-sm"
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <ModalFeedbackFooter visible={visibleFeedbackModal} onCancel={onCloseFeedbackModal} />
      <div style={{ fontSize: 14, lineHeight: '22px', padding: '4px 15px' }}>
        <span>
          &copy;
          {new Date().getFullYear()}
          {' '}
          FinVote
        </span>
        <span className="padding-left-md">
          {`Version: ${process.env.REACT_APP_VERSION || 'dev'}`}
        </span>
      </div>
      <div>
        <Button type="link" onClick={onOpenFeedbackModal}>
          {t('contact')}
        </Button>
        <a href="/imprint" target="_blank">
          <Button type="link">{t('footer.impress')}</Button>
        </a>
        <a href="/privacy-policy" target="_blank">
          <Button type="link">{t('footer.gdpr')}</Button>
        </a>
      </div>
    </div>
  );
}

export default Footer;
