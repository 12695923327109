import React, { useReducer } from 'react';

import { Steps } from 'antd';
import { useTranslation } from 'react-i18next';

import ContainerLoggedIn from '../../components/Container/ContainerLoggedIn/ContainerLoggedIn';
import { reducerCreateCourse, STEPS, ACTIONS_CREATE_COURSE } from './reducer';
import MainInformation from './steps/MainInformation';
import ExerciseDefinition from './steps/ExerciseDefinition';
import SheetDefinition from './steps/SheetDefinition';
import AddUser from './steps/AddUser';
import ReviewAndSaveData from './steps/ReviewAndSaveData';

function SpecifiStepContent({ state, disptach, onPrev }) {
  switch (state.currentStep) {
    case STEPS.MAIN_INFORMATION:
      return <MainInformation state={state} disptach={disptach} />;
    case STEPS.EXERCISE_DEFINITION:
      return <ExerciseDefinition state={state} disptach={disptach} onPrev={onPrev} />;
    case STEPS.SHEET_DEFINITION:
      return <SheetDefinition state={state} disptach={disptach} onPrev={onPrev} />;
    case STEPS.ADD_USERS:
      return <AddUser state={state} disptach={disptach} onPrev={onPrev} />;
    case STEPS.REVIEW_AND_SAVE_DATA:
      return <ReviewAndSaveData state={state} disptach={disptach} onPrev={onPrev} />;
    default:
      return null;
  }
}

function CreateCourseSteps() {
  const { t } = useTranslation(['common', 'createCourse']);
  const [state, disptach] = useReducer(reducerCreateCourse, {
    currentStep: STEPS.MAIN_INFORMATION,
    exercises: [],
    sheets: [],
    users: ['', '']
  });

  function onPrev() {
    disptach({ type: ACTIONS_CREATE_COURSE.PREV });
  }

  return (
    <div>
      <Steps current={state.currentStep} className="padding-bottom-md">
        <Steps.Step title={t('createCourse:courseInformation')} />
        <Steps.Step title={t('exercise_plural')} description="Optional" />
        <Steps.Step title={t('sheet_plural')} description="Optional" />
        <Steps.Step title={t('teacher_plural')} description="Optional" />
        <Steps.Step title={t('createCourse:review')} />
      </Steps>
      <SpecifiStepContent
        step={state.currentStep}
        state={state}
        disptach={disptach}
        onPrev={onPrev}
      />
    </div>
  );
}

function CreateCourse() {
  const { t } = useTranslation(['common']);
  return (
    <ContainerLoggedIn
      title={t('createCourse')}
      routes={[{ title: t('createCourse'), href: '/course/create' }]}
    >
      <CreateCourseSteps />
    </ContainerLoggedIn>
  );
}

export default CreateCourse;
