import React, { useMemo } from 'react';

import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import isAllowed from '../../helper/isAllowed';

function TagAllowed({ userCourseInfo, taskCount, minVotes, minPresentations }) {
  const { t } = useTranslation();
  const allowed = useMemo(
    () =>
      isAllowed({
        userVoteCount: userCourseInfo.voteCount,
        userPresentCount: userCourseInfo.presentCount,
        userIllCount: userCourseInfo.illCount,
        minPresentations,
        taskCount,
        minVotes
      }),
    [
      userCourseInfo.voteCount,
      userCourseInfo.illCount,
      userCourseInfo.presentCount,
      minPresentations
    ]
  );
  if (allowed) {
    return <Tag color="success">{t('allowed')}</Tag>;
  }
  return <Tag color="default">{t('notAllowed')}</Tag>;
}

export default React.memo(TagAllowed);
