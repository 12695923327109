import React from 'react';

import { Form, Input, message } from 'antd';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import MainContainer from '../components/MainContainer';
import PrimaryButton from '../../../components/PrimaryButton';

import { CHANGE_PASSWORD_MUTATION } from '../../../helper/api/mutations';
import getErrorCode from '../../../helper/getErrorCode';

function Password() {
  const { t } = useTranslation(['common', 'settings']);

  const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD_MUTATION, {
    onCompleted,
    onError
  });

  const [form] = Form.useForm();

  function onCompleted() {
    message.success(t('res.success.passwordChange'));
    form.resetFields();
  }

  function onError(error) {
    const code = getErrorCode(error);

    switch (code) {
      case 403:
        form.setFields([
          {
            errors: [t('settings:validation.wrongPassword')],
            name: 'oldPassword'
          }
        ]);
        break;
      default:
        message.error(t('res.error.unknownError'));
    }
  }

  function onChangePassword() {
    form
      .validateFields()
      .then((values) =>
        changePassword({
          variables: { oldPassword: values.oldPassword, newPassword: values.newPassword }
        })
      )
      .catch(() => {});
  }

  return (
    <MainContainer image="/assets/icons/settings-password.svg">
      <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
        <Form.Item
          hasFeedback
          name="oldPassword"
          validateTrigger="onBlur"
          label={t('settings:currentPassword')}
          rules={[{ required: true, message: t('settings:validation.emptyCurrentPassword') }]}
        >
          <Input.Password
            type="password"
            autoComplete="current-password"
            placeholder={t('settings:currentPassword')}
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          name="newPassword"
          validateTrigger="onBlur"
          label={t('settings:password.newPassword')}
          rules={[
            { required: true, message: t('settings:password.validation.emptyNewPassword') },
            { min: 6, message: t('validation.invalidPassword') }
          ]}
        >
          <Input.Password
            type="password"
            autoComplete="new-password"
            placeholder={t('settings:password.newPassword')}
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          name="newPasswordRepeat"
          validateTrigger="onBlur"
          label={t('settings:password.repeatNewPassword')}
          rules={[
            { required: true, message: t('settings:password.validation.emptyRepeatNewPassword') },
            ({ getFieldValue }) => ({
              validator(_rule, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject(t('settings:password.validation.mismatchPasswords'));
              }
            })
          ]}
        >
          <Input.Password
            type="password"
            autoComplete="new-password"
            placeholder={t('settings:password.repeatNewPassword')}
          />
        </Form.Item>
        <PrimaryButton
          htmlType="submit"
          style={{ width: '100%' }}
          disabled={loading}
          loading={loading}
          onClick={onChangePassword}
        >
          {t('settings:password.updatePassword')}
        </PrimaryButton>
      </Form>
    </MainContainer>
  );
}

export default Password;
