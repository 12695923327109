import React, { useContext, Suspense } from 'react';

import { Redirect, Route } from 'react-router-dom';
import AccountContext from '../../contexts/AccountContextProvider';

function RoutePrivateOnly({ component: Component, fallback, ...props }) {
  const { init: initAccount, token } = useContext(AccountContext);

  if (initAccount) {
    return null;
  }

  if (token) {
    return (
      <Route {...props}>
        <Suspense fallback={fallback}>
          <Component />
        </Suspense>
      </Route>
    );
  }

  return (
    <Route {...props}>
      <Redirect to="/login" />
    </Route>
  );
}

export default RoutePrivateOnly;
