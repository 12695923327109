import React, { useEffect, useContext } from 'react';

import { Form, Input, InputNumber, Alert, Col, Button, message } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import TabsWrapper from '../components/TabsWrapper';
import { COURSE_SETTINGS_GENERAL_QUERY } from '../../../../../helper/api/queries';
import { UPDATE_COURSE_MUTATION } from '../../../../../helper/api/mutations';
import CourseContext from '../../../contexts/CourseContextProvider';

function General() {
  const { t } = useTranslation(['common', 'course']);
  const { id } = useContext(CourseContext);
  const [form] = Form.useForm();
  const { data, loading: loadingQuery, error: errorQuery } = useQuery(
    COURSE_SETTINGS_GENERAL_QUERY,
    {
      variables: { id },
      fetchPolicy: 'cache-and-network'
    }
  );
  const [updateCourse, { loading: loadingMutation }] = useMutation(UPDATE_COURSE_MUTATION, {
    onError,
    onCompleted
  });

  useEffect(() => {
    if (data) {
      form.setFields([
        {
          name: 'name',
          value: data.course.name,
          touched: false
        },
        {
          name: 'minVotes',
          value: (data.course.minVotes * 100).toFixed(0),
          touched: false
        },
        { name: 'minPresentations', value: data.course.minPresentations, touched: false }
      ]);
    }
  }, [data]);

  function onCompleted() {
    message.success(t('course:res.saveCourse'));
  }

  function onError() {
    message.error(t('res.error.unknownError'));
  }

  function onSave() {
    if (
      form.isFieldTouched('name') ||
      form.isFieldTouched('minVotes') ||
      form.isFieldTouched('minPresentations')
    ) {
      return form
        .validateFields()
        .then((values) =>
          updateCourse({
            variables: {
              id,
              name: values.name,
              minVotes: values.minVotes / 100,
              minPresentations: values.minPresentations
            }
          })
        )
        .catch(() => {});
    }
    return null;
  }

  return (
    <TabsWrapper>
      <Col xs={24} md={16}>
        <h2 className="margin-bottom-md">{t('general')}</h2>
        {errorQuery && (
          <Alert
            className="margin-bottom-md"
            description={t('res.error.unknownErrorAlert')}
            type="error"
            closable
          />
        )}
        <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
          <Form.Item
            hasFeedback
            name="name"
            validateTrigger="onBlur"
            label={t('courseName')}
            rules={[{ required: true, message: t('validation.emptyCourseName') }]}
            validateStatus={loadingQuery && 'validating'}
          >
            <Input autoFocus placeholder={t('courseName')} disabled={loadingQuery} />
          </Form.Item>
          <Form.Item
            name="minVotes"
            validateTrigger="onBlur"
            label={t('minVote')}
            validateStatus={loadingQuery && 'validating'}
            rules={[
              {
                validator: (_, value) => {
                  const minVotes = parseInt(value, 10);
                  if (Number.isNaN(minVotes)) {
                    return Promise.reject(t('validation.invalidMinVotePattern'));
                  }
                  if (minVotes < 0 || minVotes > 100) {
                    return Promise.reject(t('validation.invalidMinVoteRange'));
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <Input
              style={{ width: '100%' }}
              placeholder={t('minVote')}
              addonAfter="%"
              disabled={loadingQuery}
            />
          </Form.Item>
          <Form.Item
            name="minPresentations"
            validateTrigger="onBlur"
            label={t('minPresentations')}
            validateStatus={loadingQuery && 'validating'}
            rules={[
              {
                pattern: /^\d+$/,
                message: t('validation.invalidMinPresentation')
              }
            ]}
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder={t('minPresentations')}
              disabled={loadingQuery}
            />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            onClick={onSave}
            style={{ width: '100%' }}
            loading={loadingMutation}
            disabled={loadingMutation}
          >
            {t('buttons.save')}
          </Button>
        </Form>
      </Col>
    </TabsWrapper>
  );
}

export default General;
