import React, { useState } from 'react';

import { Col, Button, Select, Switch, Form, message, Spin, Tooltip } from 'antd';
import { EditOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { UPDATE_STUDENT_VOTE_MUTATION } from '../helper/api/mutations';
import { STUDENT_SHEET_VOTES_QUERY } from '../helper/api/queries';
import VOTE_STATE from '../constants/VOTE_STATE';
import BadgeVoteStateStudent from './Badge/BadgeVoteStateStudent';
import BadgePresented from './Badge/BadgePresented';
import { updateCacheVotePresentation } from '../helper/cacheUpdate';
import ResponseNetworkError from './Response/ResponseNetworkError';
import getErrorCode from '../helper/getErrorCode';

const StudentVoteRow = React.memo(({ user, task, state, presented }) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [form] = Form.useForm();

  const [updateStudentVote, { loading }] = useMutation(UPDATE_STUDENT_VOTE_MUTATION, {
    onError,
    onCompleted
  });

  function onSave() {
    const { state: newState, presented: newPresented } = form.getFieldsValue([
      'state',
      'presented'
    ]);

    return updateStudentVote({
      variables: { user: user.id, task: task.id, state: newState, presented: newPresented },
      update: updateCacheVotePresentation(
        { userId: user.id, taskId: task.id },
        { state: newState, presented: newPresented }
      )
    });
  }

  function onCompleted() {
    setEdit(false);
    message.success(t('res.success.voteUpdate'));
  }

  function onError(error) {
    const code = getErrorCode(error);

    switch (code) {
      case 409:
        message.error(t('res.error.studentNotMember'));
        break;
      case 404:
      default:
        message.error(t('res.error.unknownError'));
        break;
    }
  }

  function onEdit() {
    setEdit(true);
    form.setFieldsValue({ state, presented });
  }

  function onCancel() {
    setEdit(false);
  }

  return (
    <Form form={form}>
      <div style={{ display: 'flex', alignItems: 'center' }} className="margin-bottom-sm">
        <div style={{ flex: 6 }}>{task.name}</div>
        <div style={{ flex: 6 }} className="text-center">
          {edit ? (
            <Form.Item name="state" noStyle>
              <Select
                size="small"
                style={{ width: '80%' }}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ minWidth: 175 }}
                disabled={loading}
                placeholder={t('pleaseSelect')}
              >
                <Select.Option value={VOTE_STATE.NOT_VOTED}>
                  {t('voteState.notVoted')}
                </Select.Option>
                <Select.Option value={VOTE_STATE.VOTED}>{t('voteState.voted')}</Select.Option>
                <Select.Option value={VOTE_STATE.REJECTED}>{t('voteState.rejected')}</Select.Option>
                <Select.Option value={VOTE_STATE.ILL}>{t('voteState.ill')}</Select.Option>
              </Select>
            </Form.Item>
          ) : (
            <BadgeVoteStateStudent state={state} />
          )}
        </div>
        <div style={{ flex: 6 }} className="text-center">
          {edit ? (
            <Form.Item name="presented" valuePropName="checked" noStyle>
              <Switch disabled={loading} />
            </Form.Item>
          ) : (
            <BadgePresented presented={presented} />
          )}
        </div>
        <div style={{ flex: 6 }} className="text-right">
          {edit && (
            <Tooltip title={t('buttons.save')}>
              <Button
                icon={<SaveOutlined />}
                shape="circle"
                onClick={onSave}
                type="primary"
                loading={loading}
                disabled={loading}
              />
            </Tooltip>
          )}
          {edit && (
            <Tooltip title={t('buttons.cancel')}>
              <Button
                icon={<CloseOutlined />}
                style={{ marginLeft: 10 }}
                shape="circle"
                onClick={onCancel}
                type="danger"
                disabled={loading}
              />
            </Tooltip>
          )}
          {!edit && (
            <Tooltip title={t('tooltip.editVoteState')}>
              <Button icon={<EditOutlined />} shape="circle" onClick={onEdit} />
            </Tooltip>
          )}
        </div>
      </div>
    </Form>
  );
});

function StudentVotesData({ userId, sheetId }) {
  const { data, loading, error } = useQuery(STUDENT_SHEET_VOTES_QUERY, {
    variables: { user: userId, sheet: sheetId },
    fetchPolicy: 'cache-and-network'
  });

  if (loading) {
    return (
      <div style={{ width: '100%' }} className="text-center">
        <Spin />
      </div>
    );
  }

  if (error) {
    return <ResponseNetworkError />;
  }

  return data.studentSheet.votes.map(({ task, state, presented, user }) => (
    <StudentVoteRow task={task} user={user} state={state} presented={presented} />
  ));
}

function StudentVoteTable({ userId, sheetId }) {
  const { t } = useTranslation();

  return (
    <Col span={24} style={{ padding: 0 }}>
      <div style={{ display: 'flex' }} className="margin-bottom-sm">
        <div style={{ flex: 6 }}>
          <b>{t('task')}</b>
        </div>
        <div style={{ flex: 6 }} className="text-center">
          <b>{t('voteState.voted')}</b>
        </div>
        <div style={{ flex: 6 }} className="text-center">
          <b>{t('voteState.presented')}</b>
        </div>
        <div style={{ flex: 6 }} />
      </div>
      <StudentVotesData userId={userId} sheetId={sheetId} />
    </Col>
  );
}

export default StudentVoteTable;
