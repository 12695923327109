import React from 'react';

import { Result } from 'antd';
import { useTranslation } from 'react-i18next';

function ResponseNetworkError(props) {
  const { t } = useTranslation();

  return (
    <Result
      {...props}
      icon={(
        <img
          src="/assets/icons/501.svg"
          alt="Network Error"
          style={{ width: '100%', maxWidth: 400 }}
        />
      )}
      title={t('res.error.501')}
    />
  );
}

export default React.memo(ResponseNetworkError);
