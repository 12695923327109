import React, { useReducer, useEffect } from 'react';

import { Skeleton, Button, Tooltip, Tag, Popover } from 'antd';
import { InfoCircleOutlined, EditOutlined, MessageOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';

import ResponseNetworkError from '../../../../../components/Response/ResponseNetworkError';
import ResponseEmpty from '../../../../../components/Response/ResponseEmpty';
import ModernTable from '../../../../../components/ModernTable';
import PrivacyText from './PrivacyText';
import ModalEditNote from './ModalEditNote';
import ModalEditVotes from './ModalEditVotes';
import { EXERCISE_SHEET_QUERY } from '../../../../../helper/api/queries';
import BadgeVoteStateTeacher from '../../../../../components/Badge/BadgeVoteStateTeacher';
import TagPresented from '../../../../../components/Tags/TagPresented';
import ModalStudentInfo from '../../../components/ModalStudentInfo/ModalStudentInfo';
import { VOTE_UPDATE_SUBSCRIPTION } from '../../../../../helper/api/subscriptions';
import RandomStudentPicker from './RandomStudentPicker';
import MinScreenWidth from '../../../../../components/MinScreenWidth';

const COMPONENTS_WIDTH = {
  PADDING_PAGE: 60,
  PADDING_ROW: 30,
  WIDTH_COLUMN: 80,
  STATIC_COMLUMNS_COUNT: 2
};

const OPEN_MODAL = {
  NONE: false,
  EDIT_NOTE: 1,
  EDIT_VOTES: 2,
  VIEW_STUDENT: 3
};

const ACTIONS_OVERVIEW_TABLE = {
  SHOW_EDIT_NOTE_MODAL: 1,
  SHOW_EDIT_VOTE_MODAL: 2,
  SHOW_STUDENT_INFO_MODAL: 3,
  HIDE_MODAL: 4
};

function reducerOverviewTable(state, action) {
  switch (action.type) {
    case ACTIONS_OVERVIEW_TABLE.SHOW_EDIT_NOTE_MODAL:
      return {
        visible: OPEN_MODAL.EDIT_NOTE,
        note: action.note,
        userId: action.userId
      };
    case ACTIONS_OVERVIEW_TABLE.SHOW_EDIT_VOTE_MODAL:
      return { visible: OPEN_MODAL.EDIT_VOTES, userId: action.userId };
    case ACTIONS_OVERVIEW_TABLE.SHOW_STUDENT_INFO_MODAL:
      return { visible: OPEN_MODAL.VIEW_STUDENT, userId: action.userId };
    case ACTIONS_OVERVIEW_TABLE.HIDE_MODAL:
      return { visible: OPEN_MODAL.NONE };
    default:
      return state;
  }
}

function OverviewTable({ state }) {
  const selectedSheetId = state.selectedSheet?.id;
  const selectedExerciseId = state.selectedExercise?.id;

  const { t } = useTranslation(['common', 'course']);
  const [modalState, dispatch] = useReducer(reducerOverviewTable, { visible: OPEN_MODAL.NONE });

  const { data, loading, error, subscribeToMore } = useQuery(EXERCISE_SHEET_QUERY, {
    variables: {
      exercise: selectedExerciseId,
      sheet: selectedSheetId
    },
    skip: !selectedExerciseId && !selectedSheetId,
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    if (data && selectedExerciseId && selectedSheetId) {
      const unsubscribe = subscribeToMore({
        document: VOTE_UPDATE_SUBSCRIPTION,
        variables: { exercise: selectedExerciseId, sheet: selectedSheetId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          // eslint-disable-next-line no-underscore-dangle
          switch (subscriptionData.data.voteUpdate.__typename) {
            case 'NewStudentSheetInfo':
              return update(prev, {
                exerciseSheet: {
                  students: {
                    $push: [subscriptionData.data.voteUpdate.studentSheetInfo]
                  }
                }
              });
            case 'Deleted': {
              const index = prev.exerciseSheet.students.findIndex(
                (student) => student.user.id === subscriptionData.data.voteUpdate.deleted
              );
              return update(prev, {
                exerciseSheet: {
                  students: {
                    $splice: [[index, 1]]
                  }
                }
              });
            }
            case 'StudentSheetInfo':
            default:
              return prev;
          }
        }
      });
      return unsubscribe;
    }
    return undefined;
  }, [subscribeToMore, data, selectedExerciseId, selectedSheetId]);

  function closeModal() {
    dispatch({ type: ACTIONS_OVERVIEW_TABLE.HIDE_MODAL });
  }

  function openEditNoteModal(note, userId) {
    dispatch({
      type: ACTIONS_OVERVIEW_TABLE.SHOW_EDIT_NOTE_MODAL,
      note,
      userId
    });
  }

  function openEditVotesModal(userId) {
    dispatch({ type: ACTIONS_OVERVIEW_TABLE.SHOW_EDIT_VOTE_MODAL, userId });
  }

  function openViewStudentModal(userId) {
    dispatch({ type: ACTIONS_OVERVIEW_TABLE.SHOW_STUDENT_INFO_MODAL, userId });
  }

  if (loading) {
    return <Skeleton loading active />;
  }

  if (error) {
    return <ResponseNetworkError />;
  }

  if (data.exerciseSheet.students.length === 0) {
    return <ResponseEmpty title={<span>{t('course:noOneVoted')}</span>} />;
  }

  return (
    <div>
      <MinScreenWidth
        width={
          COMPONENTS_WIDTH.WIDTH_COLUMN *
            (COMPONENTS_WIDTH.STATIC_COMLUMNS_COUNT + data.exerciseSheet.node.tasks.length) +
          COMPONENTS_WIDTH.PADDING_PAGE +
          COMPONENTS_WIDTH.PADDING_ROW
        }
      >
        <ModalEditNote
          visible={modalState.visible === OPEN_MODAL.EDIT_NOTE}
          onCancel={closeModal}
          note={modalState.note}
          userId={modalState.userId}
          sheetId={selectedSheetId}
        />
        <ModalEditVotes
          visible={modalState.visible === OPEN_MODAL.EDIT_VOTES}
          userId={modalState.userId}
          sheetId={selectedSheetId}
          onCancel={closeModal}
        />
        <ModalStudentInfo
          visible={modalState.visible === OPEN_MODAL.VIEW_STUDENT}
          studentId={modalState.userId}
          onCancel={closeModal}
        />
        <ModernTable
          disabledRowHeaders
          dataSource={data.exerciseSheet.students}
          columns={[
            {
              title: t('student'),
              dataIndex: 'user',
              key: 'user',
              render: (user, { note }) => (
                <span>
                  <PrivacyText privacy={state.privacyMode}>
                    {`${user.firstName} ${user.lastName}`}
                  </PrivacyText>
                  <Tooltip title={t('course:tooltip.showStudentInfo')}>
                    <Button
                      type="link"
                      icon={<InfoCircleOutlined />}
                      onClick={() => openViewStudentModal(user.id)}
                    />
                  </Tooltip>
                  {!!note && (
                    <div>
                      <Popover
                        title={t('comment')}
                        content={(
                          <div>
                            {note}
                            <br />
                            <Button
                              type="link"
                              style={{ padding: 0 }}
                              onClick={() => openEditNoteModal(note, user.id)}
                            >
                              {t('course:tooltip.editNote')}
                            </Button>
                          </div>
                        )}
                      >
                        <Tag color="blue">{t('commented')}</Tag>
                      </Popover>
                    </div>
                  )}
                </span>
              )
            },
            ...data.exerciseSheet.node.tasks.map((task, index) => ({
              title: (
                <span>
                  {`${task.name} ${task.optional ? `(${t('optional')})` : ''}`}
                  <br />
                  <RandomStudentPicker students={data.exerciseSheet.students} taskId={task.id} />
                </span>
              ),
              dataIndex: 'votes',
              key: task.id,
              className: 'text-center',
              render: (userVotes, { user }) => (
                <span>
                  <BadgeVoteStateTeacher
                    state={userVotes[index].state}
                    presented={userVotes[index].presented}
                    taskId={task.id}
                    userId={user.id}
                  />
                  <br />
                  <TagPresented presented={userVotes[index].presented} />
                </span>
              )
            })),
            {
              title: t('actions'),
              dataIndex: 'user',
              key: 'actions',
              className: 'text-right',
              render: ({ id: userId }, { note }) => {
                return (
                  <div>
                    <Tooltip title={t('tooltip.editVoteState')}>
                      <Button
                        shape="circle"
                        size="middle"
                        icon={<EditOutlined />}
                        onClick={() => openEditVotesModal(userId)}
                      />
                    </Tooltip>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Tooltip title={t('course:tooltip.editNote')}>
                      <Button
                        shape="circle"
                        size="middle"
                        icon={<MessageOutlined />}
                        onClick={() => openEditNoteModal(note, userId)}
                      />
                    </Tooltip>
                  </div>
                );
              }
            }
          ]}
        />
      </MinScreenWidth>
    </div>
  );
}

export default OverviewTable;
