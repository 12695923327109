import React, { useState } from 'react';

import { Form, Button, Modal, Alert, InputNumber, Slider } from 'antd';
import { PlusOutlined, StarOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { ACTIONS_CREATE_COURSE } from '../reducer';
import StepsWrapper from '../components/StepsWrapper';
import PreviewExercise from '../../../components/Preview/PreviewExercise';
import ModalExercise from '../../../components/Modals/ModalExercise';

function SmartExerciseConfiguratorModal({ visible, onCancel, onSmartCreateExercises }) {
  const { t } = useTranslation(['common', 'createCourse']);
  const [exerciseCount, setExerciseCount] = useState(3);

  function onSmartCreateExercisesInternal() {
    onSmartCreateExercises(exerciseCount);
  }

  return (
    <Modal visible={visible} closable onCancel={onCancel} footer={null}>
      <h3>{t('createCourse:smart.title')}</h3>
      <div className="text-secondary padding-bottom-sm">
        {t('createCourse:exerciseDefinition.smart.desc')}
      </div>
      <h4>{t('createCourse:exerciseDefinition.smart.countExercises')}</h4>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Slider
          min={1}
          max={30}
          onChange={setExerciseCount}
          value={exerciseCount}
          style={{ flex: 1 }}
        />
        <div className="padding-left-sm">
          <InputNumber
            min={1}
            max={30}
            value={exerciseCount}
            onChange={setExerciseCount}
            autoFocus
          />
        </div>
      </div>
      <div className="text-right padding-top-md">
        <span className="padding-right-sm">
          <Button onClick={onCancel}>{t('buttons.cancel')}</Button>
        </span>
        <Button type="primary" onClick={onSmartCreateExercisesInternal}>
          {t('buttons.create')}
        </Button>
      </div>
    </Modal>
  );
}

function ExerciseDefinition({ state, disptach, onPrev }) {
  const { t } = useTranslation(['common', 'createCourse']);
  const [smartConfiguratorState, setSmartConfiguratorState] = useState({
    alertVisible: state.exercises.length === 0,
    modalVisible: false
  });
  const [modalExerciseState, setModalExerciseState] = useState({ visible: false, values: {} });

  const [form] = Form.useForm();

  function onSubmit() {
    disptach({
      type: ACTIONS_CREATE_COURSE.SET_EXERCISE_INFORMATION,
      exercises: form.getFieldValue('exercises') || []
    });
  }

  const onSkip = onSubmit;

  function onAddExercise() {
    const exercises = form.getFieldValue('exercises') || [];
    setModalExerciseState({
      visible: true,
      values: {
        id: new Date().valueOf(),
        dayOfWeek: 1,
        name: `${t('group')} ${exercises.length + 1}`
      }
    });
  }

  function onSmartCreateExercises(exerciseCount) {
    const id = new Date().valueOf();
    form.setFieldsValue({
      exercises: new Array(exerciseCount).fill(0).map((_, index) => ({
        id: id + index,
        name: `${t('group')} ${index + 1}`
      }))
    });

    setSmartConfiguratorState({ alertVisible: false, modalVisible: false });
  }

  function onEditExercise(values) {
    setModalExerciseState({ visible: true, values });
  }

  function onCloseModalExercise() {
    setModalExerciseState({ visible: false, values: {} });
  }

  function onCloseSmartExerciseConfiguratorModal() {
    setSmartConfiguratorState({
      alertVisible: smartConfiguratorState.alertVisible,
      modalVisible: false
    });
  }

  function onOpenSmartExerciseConfiguratorModal() {
    setSmartConfiguratorState({
      alertVisible: true,
      modalVisible: true
    });
  }

  function onOkModal(values) {
    const exercises = form.getFieldValue('exercises') || [];
    const index = exercises.findIndex((exercise) => exercise.id === values.id);
    if (index >= 0) {
      form.setFieldsValue({
        exercises: [...exercises.slice(0, index), values, ...exercises.slice(index + 1)]
      });
    } else {
      form.setFieldsValue({
        exercises: [...exercises, values]
      });
    }
    if (smartConfiguratorState.alertVisible) {
      setSmartConfiguratorState({ alertVisible: false, modalVisible: false });
    }
    onCloseModalExercise();
  }

  async function onDeleteExercise(id) {
    const exercises = form.getFieldValue('exercises') || [];
    const index = exercises.findIndex((exercise) => exercise.id === id);

    if (index >= 0) {
      form.setFieldsValue({
        exercises: [...exercises.slice(0, index), ...exercises.slice(index + 1)]
      });
      if (exercises.length === 1 && !smartConfiguratorState.alertVisible) {
        setSmartConfiguratorState({ alertVisible: true, modalVisible: false });
      }
    }
  }

  return (
    <StepsWrapper
      title={t('exercise_plural')}
      description={t('createCourse:exerciseDefinition.desc')}
      onSkip={onSkip}
      onPrev={onPrev}
      onSubmit={onSubmit}
    >
      {smartConfiguratorState.alertVisible && (
        <Alert
          type="info"
          icon={<StarOutlined />}
          showIcon
          message={(
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ flex: 1 }}>
                {t('createCourse:smart.teaser1')} 
                {' '}
                <b>{t('createCourse:smart.title')}</b>
                {' '}
                {t('createCourse:smart.teaser2')}
              </span>
              <Button type="primary" onClick={onOpenSmartExerciseConfiguratorModal}>
                {t('createCourse:smart.startNow')}
              </Button>
            </div>
          )}
          className="margin-bottom-md"
        />
      )}
      <Button
        type="primary"
        style={{ width: '100%' }}
        onClick={onAddExercise}
        className="margin-bottom-md"
      >
        {t('createCourse:exerciseDefinition.addExercise')}
        <PlusOutlined />
      </Button>

      <Form
        form={form}
        name="mainForm"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{
          exercises: state.exercises
        }}
      >
        <Form.Item
          shouldUpdate={(prevValues, curValues) => prevValues.exercises !== curValues.exercises}
          noStyle
        >
          {({ getFieldValue }) => {
            const exercises = getFieldValue('exercises') || [];
            return exercises.map((exercise) => (
              <PreviewExercise
                key={exercise.id}
                exercise={exercise}
                onEdit={onEditExercise}
                onDelete={onDeleteExercise}
              />
            ));
          }}
        </Form.Item>
        <ModalExercise
          visible={modalExerciseState.visible}
          values={modalExerciseState.values}
          onCancel={onCloseModalExercise}
          onOk={onOkModal}
        />
        <SmartExerciseConfiguratorModal
          visible={smartConfiguratorState.modalVisible}
          onCancel={onCloseSmartExerciseConfiguratorModal}
          onSmartCreateExercises={onSmartCreateExercises}
        />
      </Form>
    </StepsWrapper>
  );
}

export default ExerciseDefinition;
