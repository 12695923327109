import React, { useContext } from 'react';

import AccountContext from '../../contexts/AccountContextProvider';
import ModalFeedback from './ModalFeedback';
import ModalFeedbackLoggedOut from './ModalFeedbackLoggedOut';

function ModalFeedbackFooter({ visible, onCancel }) {
  const { loggedIn } = useContext(AccountContext);

  if (loggedIn) {
    return <ModalFeedback visible={visible} onCancel={onCancel} />;
  }
  return <ModalFeedbackLoggedOut visible={visible} onCancel={onCancel} />;
}

export default ModalFeedbackFooter;
