import React, { useEffect, useState, useContext } from 'react';

import { Tabs, Skeleton } from 'antd';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CourseContext, { CourseContextProvider } from './contexts/CourseContextProvider';
import ContainerLoggedIn from '../../components/Container/ContainerLoggedIn/ContainerLoggedIn';
import Overview from './tabs/Overview/Overview';
import Settings from './tabs/Settings/Settings';
import Logs from './tabs/Logs';
import Students from './tabs/Students/Students';

import './index.less';
import ResponseNetworkError from '../../components/Response/ResponseNetworkError';

function CourseTabManager() {
  const { t } = useTranslation(['common', 'course']);
  const { id } = useContext(CourseContext);
  const { tab = 'overview' } = useParams();
  const [activeTab, setActiveTab] = useState(tab);
  const history = useHistory();

  useEffect(() => {
    if (tab !== activeTab) {
      setActiveTab(tab);
    }
  }, [tab]);

  function onTabChange(key) {
    history.replace(`/course/${id}/${key}`);
  }

  return (
    <Tabs
      defaultActiveKey={tab}
      size="large"
      className="padding-bottom-lg"
      onChange={onTabChange}
      activeKey={activeTab}
    >
      <Tabs.TabPane tab={t('course:overview')} key="overview">
        <Overview />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('student_plural')} key="students">
        <Students />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('course:logs')} key="logs">
        <Logs />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('settings')} key="settings">
        <Settings />
      </Tabs.TabPane>
    </Tabs>
  );
}

function CourseContent() {
  const { loading, error, name } = useContext(CourseContext);

  if (loading) {
    return <Skeleton />;
  }

  if (error) {
    return <ResponseNetworkError />;
  }

  return (
    <div>
      <h2 style={{ marginBottom: 0 }}>{name}</h2>
      <CourseTabManager />
    </div>
  );
}

function CourseContentWrapper({ children }) {
  const { t } = useTranslation(['common']);
  const { name } = useContext(CourseContext);
  const location = useLocation();

  return (
    <ContainerLoggedIn
      routes={[
        { title: t('myCourses'), href: '/' },
        { title: name, href: location.pathname }
      ]}
      className="container-course-main "
    >
      {children}
    </ContainerLoggedIn>
  );
}

function Course() {
  const { id } = useParams();

  return (
    <CourseContextProvider courseId={id}>
      <CourseContentWrapper>
        <CourseContent />
      </CourseContentWrapper>
    </CourseContextProvider>
  );
}

export default Course;
