import React, { useState } from 'react';

import { Popconfirm, Button, Tooltip } from 'antd';
import { NumberOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import MetaInformation from '../MetaInformation/MetaInformation';
import PreviewWrapper from './PreviewWrapper';

function PreviewSheet({ sheet, onEdit, onDelete }) {
  const [loadingDelete, setLoadingDelete] = useState(false);

  const { t } = useTranslation();

  const infos = [
    {
      icon: NumberOutlined,
      info: t('taskCount', { count: sheet.tasks.filter((task) => !task.optional).length })
    },
    {
      icon: NumberOutlined,
      info: t('optionalTaskCount', { count: sheet.tasks.filter((task) => task.optional).length })
    }
  ];

  function onConfirmDeletion() {
    setLoadingDelete(true);
    return onDelete(sheet.id).finally(() => setLoadingDelete(false));
  }

  function onEditInternal() {
    onEdit(sheet);
  }

  return (
    <PreviewWrapper
      title={sheet.name}
      extra={(
        <span>
          {onEdit && (
            <Tooltip title={t('preview.sheet.tooltip.editSheet')}>
              <Button
                type="ghost"
                shape="circle"
                icon={<EditOutlined />}
                onClick={onEditInternal}
              />
            </Tooltip>
          )}
          {onDelete && (
            <Tooltip title={t('preview.sheet.tooltip.deleteSheet')}>
              <Popconfirm
                okText={t('delete')}
                cancelText={t('buttons.cancel')}
                okType="danger"
                title={`${t('preview.sheet.tooltip.deleteSheet')}?`}
                onConfirm={onConfirmDeletion}
              >
                <Button
                  type="danger"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  loading={loadingDelete}
                  style={{ marginLeft: 10 }}
                />
              </Popconfirm>
            </Tooltip>
          )}
        </span>
      )}
      description={
        infos.length > 0 ? (
          <MetaInformation infos={infos} style={{ paddingBottom: 0 }} />
        ) : (
          <div className="text-secondary">{t('noMoreInfo')}</div>
        )
      }
    />
  );
}

export default PreviewSheet;
