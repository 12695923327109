import React, { useContext, useRef } from 'react';

import { Form, Input, Button, Alert, message } from 'antd';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router-dom';

import PrimaryButton from '../../../components/PrimaryButton';
import { ACTION_REGISTER_STEPS } from '../reducer';
import getErrorCode from '../../../helper/getErrorCode';
import AccountContext from '../../../contexts/AccountContextProvider';
import { REGISTER_MUTATION } from '../../../helper/api/mutations';

function RegisterStepSetAccountCredentials({ state, dispatch, onPrev }) {
  const history = useHistory();
  const { t } = useTranslation(['common', 'register']);
  const { connectAccount } = useContext(AccountContext);
  const [register, { loading, error }] = useMutation(REGISTER_MUTATION, { onCompleted, onError });
  const recaptchaRef = useRef();

  const [form] = Form.useForm();

  function onCompleted({ register: registerData }) {
    message.success(t('register:res.registered'));

    connectAccount(registerData.token, registerData.user);

    history.replace('/', { onBoardUserAs: state.userType });
  }

  function onError(errorResponse) {
    const code = getErrorCode(errorResponse);
    switch (code) {
      case 412:
        message.error(t('res.error.emailInUse'));
        break;
      case 417:
        message.error(t('res.error.matricelInUse'));
        break;
      case 400:
      case 410:
      case 411:
      case 418:
      default:
        message.error(t('res.error.unknownError'));
    }
  }

  function getErrorText(errorResponse) {
    const code = getErrorCode(errorResponse);
    switch (code) {
      case 412:
        return t('res.error.emailInUse');
      case 417:
        return t('res.error.matricelInUse');
      case 400:
      case 410:
      case 411:
      case 418:
      default:
        return t('res.error.unknownErrorAlert');
    }
  }

  function onCreateAccount(recaptcha) {
    if (recaptcha) {
      return form
        .validateFields()
        .then((values) => {
          return register({
            variables: {
              firstName: state.firstName,
              lastName: state.lastName,
              email: values.email,
              password: values.password,
              matriculationNumber: state.matriculationNumber,
              study: state.study,
              degree: state.degree,
              exerciseCode: state.exerciseCode,
              language: state.language,
              recaptcha
            }
          });
        })
        .catch(() => {});
    }
    return undefined;
  }

  function onCreateCaptch() {
    form
      .validateFields()
      .then((values) => {
        dispatch({
          type: ACTION_REGISTER_STEPS.SET_ACCOUNT_CREDENTIALS,
          email: values.email,
          password: values.password
        });
        recaptchaRef.current.reset();
        recaptchaRef.current.execute();
      })
      .catch(() => {});
  }

  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={{
        email: state.email
      }}
    >
      {error && (
        <Alert
          className="margin-bottom-md"
          description={getErrorText(error)}
          type="error"
          closable
        />
      )}
      <Form.Item
        hasFeedback
        label={t('yourEmail')}
        name="email"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: t('validation.emptyEmail') },
          { type: 'email', message: t('validation.invalidEmail') }
        ]}
      >
        <Input
          size="large"
          placeholder={t('yourEmail')}
          autoComplete="username"
          type="email"
          autoFocus
        />
      </Form.Item>

      <Form.Item
        hasFeedback
        label={t('yourPassword')}
        name="password"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: t('validation.emptyPassword') },
          { min: 6, message: t('validation.invalidPassword') }
        ]}
      >
        <Input.Password
          size="large"
          placeholder={t('yourPassword')}
          type="password"
          autoComplete="new-password"
        />
      </Form.Item>

      <Form.Item
        hasFeedback
        label={t('yourPasswordRepeat')}
        name="password-repeat"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: t('validation.emptyRepeatPassword') },
          ({ getFieldValue }) => ({
            validator(_rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject(t('validation.invalidRepeatPassword'));
            }
          })
        ]}
      >
        <Input.Password
          size="large"
          placeholder={t('yourPasswordRepeat')}
          type="password"
          autoComplete="new-password"
        />
      </Form.Item>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        size="invisible"
        ref={recaptchaRef}
        onChange={onCreateAccount}
      />

      <PrimaryButton
        style={{ width: '100%' }}
        size="large"
        htmlType="submit"
        onClick={onCreateCaptch}
        loading={loading}
        disabled={loading}
      >
        {t('register:createAccount')}
      </PrimaryButton>
      <Button
        type="link"
        style={{ width: '100%' }}
        size="large"
        onClick={onPrev}
        disabled={loading}
      >
        {t('buttons.back')}
      </Button>
    </Form>
  );
}

export default RegisterStepSetAccountCredentials;
