import React from 'react';
import { Row, Col } from 'antd';

function MainContainer({ children, image }) {
  return (
    <Row>
      <Col xs={24} md={12}>
        <div style={{ width: '100%', maxWidth: 700 }}>{children}</div>
      </Col>
      <Col xs={0} md={12} className="text-center padding-left-md padding-right-md">
        <img src={image} style={{ width: '100%', maxWidth: 400 }} alt="helper" />
      </Col>
    </Row>
  );
}

export default MainContainer;
