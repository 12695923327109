import React, { useState, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Menu, Dropdown, Avatar } from 'antd';
import { MailOutlined, SettingOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';

import useLogout from '../../../../hooks/useLogout';
import AccountContext from '../../../../contexts/AccountContextProvider';
import ModalFeedback from '../../../Modals/ModalFeedback';

function DropdownAccount() {
  const { t } = useTranslation();
  const history = useHistory();
  const logout = useLogout();
  const { account } = useContext(AccountContext);
  const [visibleModalFeedback, setVisibleModalFeedback] = useState(false);
  const [visibleDropdown, setVisibleDropdown] = useState(false);

  function onFeedback() {
    setVisibleModalFeedback(true);
    setVisibleDropdown(false);
  }

  function onCloseFeedbackModal() {
    setVisibleModalFeedback(false);
  }

  function onVisibleChange(visible) {
    setVisibleDropdown(visible);
  }

  function onLogout() {
    history.push('/login');
    logout();
  }

  function settings() {
    history.push('/settings');
  }

  const AccountDropdown = (
    <Menu selectedKeys={[]}>
      <Menu.Item onClick={onFeedback}>
        <MailOutlined />
        {t('header.sendFeedback')}
      </Menu.Item>
      <Menu.Item onClick={settings}>
        <SettingOutlined />
        {t('settings')}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={onLogout}>
        <LogoutOutlined />
        {t('header.logout')}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown
        visible={visibleDropdown}
        onVisibleChange={onVisibleChange}
        overlay={AccountDropdown}
        overlayClassName="dropdown-shadow"
      >
        <div className="container-account">
          <span className="name padding-right-sm">{account.firstName}</span>
          <Avatar icon={<UserOutlined />} size={38} className="avatar" />
        </div>
      </Dropdown>
      <ModalFeedback visible={visibleModalFeedback} onCancel={onCloseFeedbackModal} />
    </>
  );
}

export default DropdownAccount;
