/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useMemo } from 'react';

import { Col, Row } from 'antd';
import { DownOutlined, RightOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import VOTE_STATE from '../../../../../constants/VOTE_STATE';
import ContainerShadow from '../../../../../components/Container/ContainerShadow/ContainerShadow';
import MetaInformation from '../../../../../components/MetaInformation/MetaInformation';
import Note from './Note';
import StudentVoteTable from '../../../../../components/StudentVoteTable';
import TagCommented from '../../../../../components/Tags/TagCommented';

function Sheet({ data: { sheet, note, votes, user } }) {
  const { t } = useTranslation(['common']);
  const [expanded, setExpanded] = useState(false);

  const { voteCount, presentCount } = useMemo(
    () =>
      votes.reduce(
        (prev, { state, presented }) => {
          switch (state) {
            case VOTE_STATE.ILL:
              return {
                voteCount: prev.voteCount,
                presentCount: prev.presentCount + presented
              };
            case VOTE_STATE.VOTED:
              return {
                voteCount: prev.voteCount + 1,
                presentCount: prev.presentCount + presented
              };
            case VOTE_STATE.NOT_VOTED:
            case VOTE_STATE.REJECTED:
            default:
              return prev;
          }
        },
        { voteCount: 0, presentCount: 0 }
      ),
    [votes]
  );

  function toggleExpanded() {
    setExpanded(!expanded);
  }

  return (
    <Col xs={24} lg={12}>
      <ContainerShadow className="padding-top-xs padding-right-sm padding-left-sm margin-bottom-sm">
        <div onClick={toggleExpanded} style={{ cursor: 'pointer' }}>
          <h4 style={{ marginBottom: 0 }}>
            {sheet.name}
            <TagCommented note={note} />
            {expanded ? (
              <DownOutlined style={{ float: 'right' }} />
            ) : (
              <RightOutlined style={{ float: 'right' }} />
            )}
          </h4>
          <MetaInformation
            infos={[
              { info: t('votingCount', { count: voteCount }), icon: CheckCircleOutlined },
              { info: t('presentationCount', { count: presentCount }), icon: CheckCircleOutlined }
            ]}
          />
        </div>
        {expanded && (
          <div>
            <Note note={note} userId={user.id} sheetId={sheet.id} />
            <StudentVoteTable userId={user.id} sheetId={sheet.id} />
          </div>
        )}
      </ContainerShadow>
    </Col>
  );
}

function Sheets({ sheets }) {
  return (
    <Row gutter={30}>
      {sheets.map((data) => (
        <Sheet data={data} />
      ))}
    </Row>
  );
}

export default Sheets;
