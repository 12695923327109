import React, { useContext, useState, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { Skeleton, Timeline, Select } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import CourseContext from '../contexts/CourseContextProvider';
import ResponseNetworkError from '../../../components/Response/ResponseNetworkError';
import { COURSE_LOGS } from '../../../helper/api/queries';
import ContainerShadow from '../../../components/Container/ContainerShadow/ContainerShadow';

function LogItem({ log }) {
  const { t } = useTranslation(['common', 'course']);

  const { dot, color, content } = useMemo(() => {
    switch (log.action) {
      case 'COURSE_CREATED':
      case 'COURSE_JOINED':
      case 'EXERCISE_CREATED':
      case 'SHEET_CREATED':
      case 'NOTE_CREATED':
        return {
          color: 'green',
          dot: undefined,
          content: t(`course:logTypes.${log.action}`, { info: log.info })
        };
      case 'COURSE_UPDATED':
      case 'COURSE_INVITED':
      case 'EXERCISE_UPDATED':
      case 'SHEET_UPDATED':
      case 'SHEET_LOCK':
      case 'SHEET_UNLOCK':
      case 'NOTE_UPDATED':
      case 'VOTE_UPDATED':
      case 'VOTE_RESETTED':
      case 'EXERCISE_KICKED':
        return {
          color: 'blue',
          dot: undefined,
          content: t(`course:logTypes.${log.action}`, { info: log.info })
        };
      case 'COURSE_KICKED':
      case 'EXERCISE_REMOVED':
      case 'SHEET_REMOVED':
      case 'NOTE_REMOVED':
      case 'VOTE_REJECTED':
        return {
          color: 'red',
          dot: undefined,
          content: t(`course:logTypes.${log.action}`, { info: log.info })
        };
      default:
        return { color: undefined, dot: undefined, content: '' };
    }
  }, [log.action]);

  return (
    <Timeline.Item key={log.timestamp} dot={dot} color={color}>
      <span className="text-secondary padding-right-xs">
        {moment(log.timestamp).format('DD.MM.YYYY HH:mm')}
      </span>
      {content}
    </Timeline.Item>
  );
}

function Logs() {
  const { t } = useTranslation(['common', 'course']);
  const { id } = useContext(CourseContext);
  const [logCount, setLogCount] = useState(100);
  const { data, loading, error } = useQuery(COURSE_LOGS, {
    variables: { id, limit: logCount },
    fetchPolicy: 'cache-and-network'
  });

  function onChangeLogCount(limit) {
    setLogCount(limit);
  }

  if (loading && !data) {
    return <Skeleton />;
  }

  if (error) {
    return <ResponseNetworkError />;
  }

  return (
    <div>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
        className="padding-bottom-md"
      >
        <div>
          <span className="padding-right-sm">{t('course:showMe')}</span>
          <Select value={logCount} onChange={onChangeLogCount} placeholder={t('pleaseSelect')}>
            <Select.Option value={100}>{t('course:entriesCount', { count: 100 })}</Select.Option>
            <Select.Option value={200}>{t('course:entriesCount', { count: 200 })}</Select.Option>
            <Select.Option value={500}>{t('course:entriesCount', { count: 500 })}</Select.Option>
          </Select>
        </div>
      </div>
      <ContainerShadow className="padding-top-md padding-right-sm padding-bottom-md padding-left-sm">
        <Timeline pending={loading}>
          {data.course.logs.map((log) => (
            <LogItem key={log.timestamp} log={log} />
          ))}
        </Timeline>
      </ContainerShadow>
    </div>
  );
}

export default Logs;
