import React, { useReducer } from 'react';

import { useTranslation } from 'react-i18next';

import ContainerLoggedOut from '../../components/Container/ContainerLoggedOut/ContainerLoggedOut';
import RegisterStepTypeSelection from './steps/RegisterStepTypeSelection';
import RegisterStepSetAccountData from './steps/RegisterStepSetAccountData';
import RegisterStepSetStudentData from './steps/RegisterStepSetStudentData';
import RegisterStepSetAccountCredentials from './steps/RegisterStepSetAccountCredentials';

import { STEPS, reducerRegisterSteps, ACTION_REGISTER_STEPS } from './reducer';

function RegisterSteps() {
  const [state, dispatch] = useReducer(reducerRegisterSteps, { step: STEPS.SELECT_USER_TYPE });

  function onPrev() {
    dispatch({ type: ACTION_REGISTER_STEPS.PREV });
  }

  switch (state.step) {
    case STEPS.SELECT_USER_TYPE:
      return <RegisterStepTypeSelection dispatch={dispatch} onPrev={onPrev} />;
    case STEPS.SET_ACCOUNT_DATA:
      return <RegisterStepSetAccountData dispatch={dispatch} state={state} onPrev={onPrev} />;
    case STEPS.SET_STUDENT_DATA:
      return <RegisterStepSetStudentData dispatch={dispatch} state={state} onPrev={onPrev} />;
    case STEPS.SET_ACCOUNT_CREDENTIALS:
      return (
        <RegisterStepSetAccountCredentials dispatch={dispatch} state={state} onPrev={onPrev} />
      );
    default:
      return null;
  }
}

function Register() {
  const { t } = useTranslation(['register']);
  return (
    <ContainerLoggedOut image="/assets/icons/register.svg" title={t('register:now')} withGoBack>
      <RegisterSteps />
    </ContainerLoggedOut>
  );
}

export default Register;
