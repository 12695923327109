import React, { useState } from 'react';

import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import ModalExport from './ModalExport';

function ButtonExport({ sheetId, exerciseId, courseId, ...buttonProps }) {
  const { t } = useTranslation(['course']);
  const [visibleExportModal, setVisibleExportModal] = useState(false);

  function onOpenModal() {
    setVisibleExportModal(true);
  }

  function onCloseModal() {
    setVisibleExportModal(false);
  }

  return (
    <>
      <Tooltip title={t('course:tooltip.exportVote')}>
        <Button
          {...buttonProps}
          disabled={!sheetId && !exerciseId && !courseId}
          onClick={onOpenModal}
        />
      </Tooltip>
      <ModalExport
        visible={visibleExportModal}
        onCancel={onCloseModal}
        sheetId={sheetId}
        exerciseId={exerciseId}
        courseId={courseId}
      />
    </>
  );
}

export default React.memo(ButtonExport);
