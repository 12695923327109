import React, { useState, useEffect, useContext } from 'react';

import { Row, Col } from 'antd';
import {
  TeamOutlined,
  SettingOutlined,
  CalendarOutlined,
  ExclamationCircleOutlined,
  FileOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import ResponsiveMenu from './components/ResponsiveMenu';
import SETTINGS from './constants';
import General from './tabs/General';
import Extended from './tabs/Extended';
import Exercises from './tabs/Exercises';
import Sheets from './tabs/Sheets';
import Teachers from './tabs/Teachers/Teachers';
import CourseContext from '../../contexts/CourseContextProvider';
import COURSE_ROLE from '../../../../constants/COURSE_ROLE';
import ResponseAccessDenied from '../../../../components/Response/ResponseAccessDenied';

import './Settings.less';

const SECURED_ROUTES = [SETTINGS.EXTENDED, SETTINGS.TEACHERS];

function SpecifiSettingsContent({ selectedTab }) {
  const { role } = useContext(CourseContext);

  if (role === COURSE_ROLE.TUTOR && SECURED_ROUTES.includes(selectedTab)) {
    return <ResponseAccessDenied />;
  }

  switch (selectedTab) {
    case SETTINGS.GENERAL:
      return <General />;
    case SETTINGS.EXERCISES:
      return <Exercises />;
    case SETTINGS.SHEETS:
      return <Sheets />;
    case SETTINGS.TEACHERS:
      return <Teachers />;
    case SETTINGS.EXTENDED:
      return <Extended />;
    default:
      return null;
  }
}

function Settings() {
  const { t } = useTranslation(['common']);
  const { id, role } = useContext(CourseContext);
  const { settingsMenu = SETTINGS.GENERAL } = useParams();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(settingsMenu);

  useEffect(() => {
    if (settingsMenu !== selectedTab) {
      setSelectedTab(settingsMenu);
    }
  }, [settingsMenu]);

  function onChangeTab(tab) {
    setSelectedTab(tab);
    history.replace(`/course/${id}/settings/${tab}`);
  }

  const menuItems = [
    { key: SETTINGS.GENERAL, name: t('general'), icon: SettingOutlined },
    { key: SETTINGS.EXERCISES, name: t('exercise_plural'), icon: CalendarOutlined },
    { key: SETTINGS.SHEETS, name: t('sheet_plural'), icon: FileOutlined }
  ];

  if (role === COURSE_ROLE.ADMIN) {
    menuItems.push(
      { key: SETTINGS.TEACHERS, name: t('teacher_plural'), icon: TeamOutlined },
      { key: SETTINGS.EXTENDED, name: t('extended'), icon: ExclamationCircleOutlined }
    );
  }

  return (
    <Row>
      <Col xs={24} md={8} lg={6} xl={4}>
        <ResponsiveMenu selected={selectedTab} onChange={onChangeTab} menu={menuItems} />
      </Col>
      <Col xs={24} md={16} lg={18} xl={20}>
        <SpecifiSettingsContent selectedTab={selectedTab} />
      </Col>
    </Row>
  );
}

export default Settings;
