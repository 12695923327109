import React from 'react';

import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import ContainerLoggedIn from '../../components/Container/ContainerLoggedIn/ContainerLoggedIn';

import General from './Tabs/General';
import Email from './Tabs/Email';
import Password from './Tabs/Password';
import Extended from './Tabs/Extended';
import Notifications from './Tabs/Notifications';

function Settings() {
  const { t } = useTranslation(['common', 'settings']);
  return (
    <ContainerLoggedIn title={t('settings')} routes={[{ title: t('settings'), href: '/settings' }]}>
      <Tabs defaultActiveKey="general" size="large" className="padding-bottom-lg">
        <Tabs.TabPane tab={t('general')} key="general">
          <General />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('settings:notifications')} key="notifications">
          <Notifications />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('email')} key="eMail">
          <Email />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('password')} key="password">
          <Password />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('extended')} key="extended">
          <Extended />
        </Tabs.TabPane>
      </Tabs>
    </ContainerLoggedIn>
  );
}

export default Settings;
