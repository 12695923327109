import React, { useState, useContext } from 'react';

import { Button, Form, Input, Divider, message } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import AccountContext from '../../contexts/AccountContextProvider';
import ContainerLoggedOut from '../../components/Container/ContainerLoggedOut/ContainerLoggedOut';
import PrimaryButton from '../../components/PrimaryButton';
import { LOGIN_MUTATION } from '../../helper/api/mutations';
import getErrorCode from '../../helper/getErrorCode';

function LoginForm() {
  const { t } = useTranslation(['login']);
  const { connectAccount } = useContext(AccountContext);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const [form] = Form.useForm();
  const [login, { loading }] = useMutation(LOGIN_MUTATION, { onCompleted, onError });

  function onCompleted({ login: loginData }) {
    message.success(t('login:res.loggedIn'));

    connectAccount(loginData.token, loginData.user);
  }

  function onError(error) {
    const code = getErrorCode(error);
    switch (code) {
      case 429:
        form.setFields([{ name: 'email', errors: [t('login:res.toManyLoginReq')] }]);
        message.error(t('login:res.toManyLoginReq'));
        break;
      default:
        message.error(t('login:res.loggedInFail'));
    }
  }

  function checkButtonDisabled() {
    const disabled = !(
      form.isFieldsTouched(['email', 'password'], true) &&
      form
        .getFieldsError(['email', 'password'])
        .reduce((prev, cur) => prev && cur.errors.length === 0, true)
    );
    if (disabled !== submitButtonDisabled) {
      setSubmitButtonDisabled(disabled);
    }
  }

  function onLogin() {
    return form
      .validateFields()
      .then((values) => login({ variables: { email: values.email, password: values.password } }))
      .catch(() => {});
  }

  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onFieldsChange={checkButtonDisabled}
    >
      <Form.Item
        hasFeedback
        label={t('login:email')}
        name="email"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: t('login:validation.emptyEmail') },
          ...(process.env.NODE_ENV !== 'development'
            ? [{ type: 'email', message: t('login:validation.invalidEmail') }]
            : [])
        ]}
      >
        <Input
          size="large"
          placeholder={t('login:email')}
          type="email"
          autoFocus
          autoComplete="username"
        />
      </Form.Item>
      <Form.Item
        hasFeedback
        label={t('login:password')}
        name="password"
        validateTrigger="onBlur"
        rules={[{ required: true, message: t('login:validation.emptyPassword') }]}
      >
        <Input.Password
          size="large"
          placeholder={t('login:password')}
          type="password"
          autoComplete="current-password"
        />
      </Form.Item>
      <div
        style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}
        className="margin-bottom-md"
      >
        <Link to="/reset">
          <div style={{ textDecoration: 'underline', cursor: 'pointer' }}>
            {t('login:passwordForgot')}
          </div>
        </Link>
      </div>
      <PrimaryButton
        disabled={submitButtonDisabled || loading}
        style={{ width: '100%' }}
        size="large"
        htmlType="submit"
        className="margin-bottom-md"
        loading={loading}
        onClick={onLogin}
      >
        {t('login:login')}
      </PrimaryButton>
    </Form>
  );
}

function Login() {
  const { t } = useTranslation(['login']);
  const location = useLocation();

  return (
    <ContainerLoggedOut image="/assets/icons/votings.svg" title={t('login:welcomeBack')}>
      <LoginForm />
      <Divider className="margin-bottom-md" />
      <div style={{ width: '100%', textAlign: 'center' }}>
        {t('login:noAccount')}
        <Link to={{ pathname: '/register', state: location.state }}>
          <Button type="link">{t('login:registerNow')}</Button>
        </Link>
      </div>
    </ContainerLoggedOut>
  );
}

export default Login;
