import React, { useReducer, useEffect } from 'react';

import createRandomString from 'crypto-random-string';
import SHA512 from 'crypto-js/sha512';
import AES from 'crypto-js/aes';
import Base64 from 'crypto-js/enc-base64';
import UTF8 from 'crypto-js/enc-utf8';

const StorageContext = React.createContext({});

const ACTION_INIT_FINISH = 1;

function reducer(state, action) {
  switch (action.type) {
    case ACTION_INIT_FINISH:
      return { init: false, key: action.key };
    default:
      return state;
  }
}

export function StorageContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, { init: true, key: null });

  useEffect(() => {
    let key = window.localStorage.getItem(process.env.REACT_APP_SECURE_PREFIX);
    if (!key) {
      key = createRandomString({ length: 32, type: 'url-safe' });
      window.localStorage.setItem(process.env.REACT_APP_SECURE_PREFIX, key);
    }
    dispatch({
      type: ACTION_INIT_FINISH,
      key: SHA512(`${process.env.REACT_APP_SECURE_PREFIX}${key}`).toString(Base64)
    });
  }, []);

  function encrypt(value, key) {
    return AES.encrypt(JSON.stringify(value), key).toString();
  }

  function decrypt(value, key) {
    if (!value) {
      return null;
    }
    const bytes = AES.decrypt(value, key);
    const string = bytes.toString(UTF8);
    if (!string) {
      return null;
    }
    return JSON.parse(bytes.toString(UTF8));
  }

  function getItem(...items) {
    if (state.init) {
      throw new Error('cannot acces storage until init has ended');
    }
    return items.map((item) => {
      const value = decrypt(
        window.localStorage.getItem(
          `${process.env.REACT_APP_SECURE_PREFIX}-${SHA512(item).toString(Base64)}`
        ),
        state.key
      );
      return { item, value };
    });
  }

  function setItem(...items) {
    if (state.init) {
      throw new Error('cannot acces storage until init has ended');
    }
    items.forEach(({ item, value }) => {
      window.localStorage.setItem(
        `${process.env.REACT_APP_SECURE_PREFIX}-${SHA512(item).toString(Base64)}`,
        encrypt(value, state.key)
      );
    });
  }

  function delItem(...items) {
    items.forEach((item) => {
      window.localStorage.removeItem(
        `${process.env.REACT_APP_SECURE_PREFIX}-${SHA512(item).toString(Base64)}`
      );
    });
  }

  return (
    <StorageContext.Provider value={{ init: state.init, getItem, setItem, delItem }}>
      {children}
    </StorageContext.Provider>
  );
}

export default StorageContext;
