export const ACTIONS_CREATE_COURSE = {
  SET_MAIN_INFORMATION: 1,
  SET_EXERCISE_INFORMATION: 2,
  SET_SHEET_INFORMATION: 3,
  SET_USERS: 4,
  PREV: 5
};

export const STEPS = {
  MAIN_INFORMATION: 0,
  EXERCISE_DEFINITION: 1,
  SHEET_DEFINITION: 2,
  ADD_USERS: 3,
  REVIEW_AND_SAVE_DATA: 4
};

export function reducerCreateCourse(state, action) {
  switch (action.type) {
    case ACTIONS_CREATE_COURSE.SET_MAIN_INFORMATION:
      return {
        ...state,
        currentStep: STEPS.EXERCISE_DEFINITION,
        name: action.name,
        minVotes: action.minVotes,
        minPresentations: action.minPresentations
      };
    case ACTIONS_CREATE_COURSE.SET_EXERCISE_INFORMATION:
      return { ...state, exercises: action.exercises, currentStep: STEPS.SHEET_DEFINITION };
    case ACTIONS_CREATE_COURSE.SET_SHEET_INFORMATION:
      return { ...state, sheets: action.sheets, currentStep: STEPS.ADD_USERS };
    case ACTIONS_CREATE_COURSE.SET_USERS:
      return { ...state, users: action.users, currentStep: STEPS.REVIEW_AND_SAVE_DATA };

    case ACTIONS_CREATE_COURSE.PREV: {
      switch (state.currentStep) {
        case STEPS.REVIEW_AND_SAVE_DATA:
          return { ...state, currentStep: STEPS.ADD_USERS };
        case STEPS.ADD_USERS:
          return { ...state, currentStep: STEPS.SHEET_DEFINITION };
        case STEPS.SHEET_DEFINITION:
          return { ...state, currentStep: STEPS.EXERCISE_DEFINITION };
        case STEPS.EXERCISE_DEFINITION:
          return { ...state, currentStep: STEPS.MAIN_INFORMATION };
        case STEPS.MAIN_INFORMATION:
        default:
          return state;
      }
    }
    default:
      return state;
  }
}
