/**
 * Important: if you change something at the theme, you also have to adjust the fallback version
 */

import React from 'react';

import { Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header, { HeaderFallback } from './components/Header';
import Footer from '../../Footer';

import './ContainerLoggedIn.less';

function ContainerLoggedIn({ children, title, routes, className }) {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 100 }}>
        <Header />
      </div>
      <div className="container-logged-in">
        <div className={`content ${className}`} style={{ flex: 1 }}>
          <Breadcrumb>
            <Breadcrumb.Item key="main">
              <Link to="/">
                <span style={{ cursor: 'pointer' }}>
                  <HomeOutlined />
                  &nbsp;
                  {t('header.home')}
                </span>
              </Link>
            </Breadcrumb.Item>

            {routes &&
              routes.length > 0 &&
              routes.map(({ href, title: breadcrumpTitle }) => (
                <Breadcrumb.Item key={`${href}-${breadcrumpTitle}`}>
                  <Link to={href}>
                    <span style={{ cursor: 'pointer' }}>{breadcrumpTitle}</span>
                  </Link>
                </Breadcrumb.Item>
              ))}
          </Breadcrumb>
          {title && <h2>{title}</h2>}
          {children}
        </div>
        <div style={{ flexShrink: 0 }}>
          <Footer />
        </div>
      </div>
    </>
  );
}

export function ContainerLoggedInFallback() {
  return (
    <>
      <div style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 100 }}>
        <HeaderFallback />
      </div>
      <div className="container-logged-in">
        <div className="content" style={{ flex: 1 }} />
        <div style={{ flexShrink: 0 }} />
      </div>
    </>
  );
}

export default ContainerLoggedIn;
