import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as Sentry from '@sentry/browser';

function handleMissingLanguageKey(lng, ns, key, fallbackValue) {
  if (process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_SENTRY_RELEASE) {
    Sentry.withScope(function (scope) {
      scope.setLevel('info');
      scope.setExtra('lng', lng);
      scope.setExtra('ns', ns);
      scope.setExtra('key', key);
      scope.setExtra('fallbackValue', fallbackValue);
      Sentry.captureMessage(`Translation not found: [${lng}]${ns}:${key}`);
    });
  }
}

const backendConfig = {
  loadPath: '/locales/{{lng}}/{{ns}}.json',
  queryStringParams: { v: process.env.REACT_APP_SENTRY_RELEASE }
};

const detectionConfig = {
  order: ['querystring', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
  caches: ['localStorage']
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: backendConfig,
    detection: detectionConfig,
    debug: process.env.NODE_ENV === 'development',
    whitelist: ['de', 'en'],
    fallbackLng: 'de',
    ns: ['common'],
    defaultNS: 'common',
    cleanCode: true,
    interpolation: { escapeValue: false },
    saveMissing: true,
    missingKeyHandler: handleMissingLanguageKey
  });

function I18NProvider({ children }) {
  return children;
}

export default I18NProvider;
