import React, { useEffect, useContext, useReducer } from 'react';

import StorageContext from './StorageContextProvider';

const AccountContext = React.createContext({});

const ACTION_INIT_FINISH_WITH_ACCOUNT = 1;
const ACTION_INIT_FINISH_WITHOUT_ACCOUNT = 2;
const ACTION_LOGIN = 1;
const ACTION_LOGOUT = 2;

function reducer(state, action) {
  switch (action.type) {
    case ACTION_LOGIN:
    case ACTION_INIT_FINISH_WITH_ACCOUNT:
      return { init: false, account: action.account, token: action.token };
    case ACTION_LOGOUT:
    case ACTION_INIT_FINISH_WITHOUT_ACCOUNT:
      return { init: false, account: null, token: null };
    default:
      return state;
  }
}

export function AccountContextProvider({ children }) {
  const { init: storageInit, getItem, setItem, delItem } = useContext(StorageContext);
  const [state, dispatch] = useReducer(reducer, { init: true, token: null, account: null });

  useEffect(() => {
    if (!storageInit) {
      const [{ value: token }, { value: account }] = getItem('token', 'account');
      if (token && account) {
        dispatch({ type: ACTION_INIT_FINISH_WITH_ACCOUNT, account, token });
      } else {
        dispatch({ type: ACTION_INIT_FINISH_WITHOUT_ACCOUNT });
      }
    }
  }, [storageInit]);

  function connectAccount(token, accountData) {
    setItem({ item: 'token', value: token }, { item: 'account', value: accountData });
    dispatch({ type: ACTION_LOGIN, account: accountData, token });
  }

  function disconnectAccount() {
    delItem('token', 'account');
    dispatch({ type: ACTION_LOGOUT });
  }

  return (
    <AccountContext.Provider
      value={{
        init: state.init,
        token: state.token,
        account: state.account,
        loggedIn: !!state.token && !!state.account,
        connectAccount,
        disconnectAccount
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}

export default AccountContext;
