import React, { useReducer, useEffect, useContext } from 'react';

import { Col, Skeleton, Row, message, Button, Tooltip } from 'antd';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import TabsWrapper from '../components/TabsWrapper';
import {
  DELETE_SHEET_MUTATION,
  UPDATE_SHEET_MUTATION,
  CREATE_SHEET_MUTATION
} from '../../../../../helper/api/mutations';
import ResponseNetworkError from '../../../../../components/Response/ResponseNetworkError';
import {
  COURSE_SETTINGS_SHEETS_QUERY,
  COURSE_OVERVIEW_MENU_QUERY
} from '../../../../../helper/api/queries';
import ResponseEmpty from '../../../../../components/Response/ResponseEmpty';
import PreviewSheet from '../../../../../components/Preview/PreviewSheet';
import ModalSheet from '../../../../../components/Modals/ModalSheet';
import CourseContext from '../../../contexts/CourseContextProvider';
import { updateCacheRemoveSheet, updateCacheAddSheet } from '../../../../../helper/cacheUpdate';
import getErrorCode from '../../../../../helper/getErrorCode';
import COURSE_ROLE from '../../../../../constants/COURSE_ROLE';

const ACTIONS_SHEET_CONTENT = {
  EDIT: 1,
  CREATE: 2,
  NOTHING: 3,
  LOADING: 4,
  ERROR: 5
};

const MODE = {
  EDIT: 1,
  CREATE: 2
};

function reducerSheetContent(state, action) {
  switch (action.type) {
    case ACTIONS_SHEET_CONTENT.EDIT:
      return {
        visible: true,
        mode: MODE.EDIT,
        loading: false,
        error: false,
        values: action.values
      };
    case ACTIONS_SHEET_CONTENT.CREATE:
      return {
        visible: true,
        mode: MODE.CREATE,
        loading: false,
        error: false,
        values: action.values
      };
    case ACTIONS_SHEET_CONTENT.NOTHING:
      return { visible: false, mode: null, loading: false, error: false, values: {} };
    case ACTIONS_SHEET_CONTENT.LOADING:
      return { ...state, loading: true, error: false };
    case ACTIONS_SHEET_CONTENT.ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
}

function SheetList({ onAddSheet, onEditSheet, onDeleteSheet }) {
  const { t } = useTranslation(['common', 'course']);
  const { id, role } = useContext(CourseContext);
  const { data, loading: loadingQuery, error: errorQuery } = useQuery(
    COURSE_SETTINGS_SHEETS_QUERY,
    {
      variables: { id },
      fetchPolicy: 'cache-and-network'
    }
  );

  if (loadingQuery) {
    return (
      <Col span={24}>
        <Skeleton active />
      </Col>
    );
  }

  if (errorQuery) {
    return (
      <Col span={24}>
        <ResponseNetworkError />
      </Col>
    );
  }

  if (data.course.sheets.length === 0) {
    return (
      <Col span={24}>
        <ResponseEmpty
          title={t('course:noSheet')}
          button={{ title: t('course:createSheet'), onClick: onAddSheet }}
        />
      </Col>
    );
  }

  return (
    <Col span={24}>
      <Row gutter={30}>
        {data.course.sheets.map((sheet) => (
          <Col key={sheet.id} xs={24} lg={12}>
            <PreviewSheet
              sheet={sheet}
              onEdit={onEditSheet}
              onDelete={role === COURSE_ROLE.ADMIN && onDeleteSheet}
            />
          </Col>
        ))}
      </Row>
    </Col>
  );
}

function Sheet() {
  const { t } = useTranslation();
  const { id } = useContext(CourseContext);
  const history = useHistory();

  const [modalSheetState, dispatch] = useReducer(reducerSheetContent, {
    visible: false,
    mode: null,
    values: null
  });

  const client = useApolloClient();

  const [deleteSheetMutation] = useMutation(DELETE_SHEET_MUTATION, {
    onError: onErrorDeleteSheet,
    onCompleted: onCompletedDeleteSheet
  });

  const [updateSheetMutation] = useMutation(UPDATE_SHEET_MUTATION, {
    onError: onErrorUpdateSheet,
    onCompleted: onCompletedUpdateSheet
  });

  const [createSheetMutation] = useMutation(CREATE_SHEET_MUTATION, {
    onError: onErrorCreateSheet,
    onCompleted: onCompletedCreateSheet
  });

  useEffect(() => {
    if (history.location.state?.addSheet) {
      // has to be with timout, becaus ui transition is to slow
      setTimeout(() => {
        dispatch({
          type: ACTIONS_SHEET_CONTENT.CREATE,
          values: {
            name: `${t('sheetShort')} 1`,
            tasks: [
              { name: `${t('task')} 1`, optional: false },
              { name: `${t('task')} 2`, optional: false },
              { name: `${t('task')} 3`, optional: false }
            ]
          }
        });
        history.replace(history.location.pathname, {});
      }, 300);
    }
  }, [history.location.state?.addSheet]);

  /* -------------------------------------------------------------------------- */
  /*                              Delete Operations                             */
  /* -------------------------------------------------------------------------- */

  function onDeleteSheet(sheetId) {
    return deleteSheetMutation({
      variables: { id: sheetId },
      refetchQueries: [{ query: COURSE_OVERVIEW_MENU_QUERY, variables: { id } }],
      update: updateCacheRemoveSheet({ courseId: id }, { sheetId })
    });
  }

  function onCompletedDeleteSheet() {
    message.success(t('course:res.deleteSheet'));
  }

  function onErrorDeleteSheet() {
    message.error(t('course:res.deleteSheetFail'));
  }

  /* -------------------------------------------------------------------------- */
  /*                              Update Operations                             */
  /* -------------------------------------------------------------------------- */

  function onEditSheet(sheet) {
    dispatch({ type: ACTIONS_SHEET_CONTENT.EDIT, values: sheet });
  }

  function updateSheet(sheet) {
    dispatch({ type: ACTIONS_SHEET_CONTENT.LOADING });

    return updateSheetMutation({
      variables: {
        id: sheet.id,
        ...sheet
      }
    });
  }

  function onCompletedUpdateSheet() {
    dispatch({ type: ACTIONS_SHEET_CONTENT.NOTHING });
    message.success(t('course:res.saveSheet'));
  }

  function onErrorUpdateSheet(error) {
    const code = getErrorCode(error);

    switch (code) {
      case 412:
        dispatch({ type: ACTIONS_SHEET_CONTENT.ERROR, error });
        message.error(t('course:res.sheetNameExists'));
        break;
      case 400:
      case 403:
      case 404:
      default:
        dispatch({ type: ACTIONS_SHEET_CONTENT.ERROR, error });
        message.error(t('course:res.saveSheetFail'));
        break;
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                              Create Operations                             */
  /* -------------------------------------------------------------------------- */

  function onAddSheet() {
    let sheetCount = null;
    try {
      const sheets = client.readQuery({
        query: COURSE_SETTINGS_SHEETS_QUERY,
        variables: { id }
      });
      sheetCount = sheets?.course?.sheets?.length + 1;
    } catch (e) {}

    dispatch({
      type: ACTIONS_SHEET_CONTENT.CREATE,
      values: {
        name: `${t('sheetShort')} ${sheetCount || ''}`,
        tasks: [
          { name: `${t('task')} 1`, optional: false },
          { name: `${t('task')} 2`, optional: false },
          { name: `${t('task')} 3`, optional: false }
        ]
      }
    });
  }

  function createSheet(sheet) {
    return createSheetMutation({
      mutation: CREATE_SHEET_MUTATION,
      variables: {
        course: id,
        ...sheet
      },
      refetchQueries: [{ query: COURSE_OVERVIEW_MENU_QUERY, variables: { id } }],
      update: updateCacheAddSheet({ courseId: id }, (result) => ({
        sheet: result.data.sheet.create
      }))
    });
  }

  function onCompletedCreateSheet() {
    dispatch({ type: ACTIONS_SHEET_CONTENT.NOTHING });
    message.success(t('course:res.saveSheet'));
  }

  function onErrorCreateSheet(error) {
    const code = getErrorCode(error);

    switch (code) {
      case 412:
        dispatch({ type: ACTIONS_SHEET_CONTENT.ERROR, error });
        message.error(t('course:res.sheetNameExists'));
        break;
      case 400:
      case 403:
      case 404:
      default:
        dispatch({ type: ACTIONS_SHEET_CONTENT.ERROR, error });
        message.error(t('course:res.createSheetFail'));
        break;
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                               Other Functions                              */
  /* -------------------------------------------------------------------------- */

  function onOk(values) {
    if (modalSheetState.mode === MODE.EDIT) {
      return updateSheet(values);
    }
    return createSheet(values);
  }

  function onCloseModalSheet() {
    dispatch({ type: ACTIONS_SHEET_CONTENT.NOTHING });
  }

  return (
    <TabsWrapper>
      <Row>
        <Col xs={24} style={{ display: 'flex', alignItems: 'center' }} className="margin-bottom-md">
          <h2 style={{ marginBottom: 0 }} className="padding-right-sm">
            {t('sheet_plural')}
          </h2>
          <Tooltip title={t('course:tooltip.addSheet')}>
            <Button type="primary" icon={<PlusOutlined />} shape="circle" onClick={onAddSheet} />
          </Tooltip>
        </Col>
        <SheetList
          onAddSheet={onAddSheet}
          onEditSheet={onEditSheet}
          onDeleteSheet={onDeleteSheet}
        />
        <ModalSheet
          visible={modalSheetState.visible}
          values={modalSheetState.values}
          loading={modalSheetState.loading}
          error={modalSheetState.error}
          onCancel={onCloseModalSheet}
          onOk={onOk}
        />
      </Row>
    </TabsWrapper>
  );
}

export default Sheet;
