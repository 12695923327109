import React, { useRef } from 'react';

import { Form, message, Input } from 'antd';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';

import ContainerLoggedOut from '../../components/Container/ContainerLoggedOut/ContainerLoggedOut';
import PrimaryButton from '../../components/PrimaryButton';
import { RESET_MUTATION } from '../../helper/api/mutations';

function ResetStartForm() {
  const { t } = useTranslation(['common']);
  const [form] = Form.useForm();
  const [sendEmail, { loading }] = useMutation(RESET_MUTATION, { onCompleted, onError });
  const recaptchaRef = useRef();

  function onCompleted() {
    message.success(t('reset.res.request'));
    form.resetFields();
  }

  function onError() {
    message.error(t('res.error.unknownError'));
  }

  function onReset(recaptcha) {
    return form
      .validateFields()
      .then((values) => sendEmail({ variables: { email: values.email, recaptcha } }))
      .catch(() => {});
  }

  function onCreateCaptch() {
    form
      .validateFields()
      .then(() => {
        recaptchaRef.current.reset();
        recaptchaRef.current.execute();
      })
      .catch(() => {});
  }

  return (
    <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <Form.Item
        hasFeedback
        label={t('yourEmail')}
        className="margin-bottom-md"
        name="email"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: t('validation.emptyEmail') },
          { type: 'email', message: t('validation.invalidEmail') }
        ]}
      >
        <Input size="large" placeholder={t('yourEmail')} type="email" autoFocus />
      </Form.Item>

      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        size="invisible"
        ref={recaptchaRef}
        onChange={onReset}
      />

      <PrimaryButton
        disabled={loading}
        style={{ width: '100%' }}
        size="large"
        htmlType="submit"
        className="margin-bottom-md"
        loading={loading}
        onClick={onCreateCaptch}
      >
        {t('reset.resetPassword')}
      </PrimaryButton>
    </Form>
  );
}

function ResetStart() {
  const { t } = useTranslation(['common']);

  return (
    <ContainerLoggedOut withGoBack title={t('reset.title')} image="/assets/icons/forget.svg">
      <div className="text-secondary margin-bottom-md">{t('reset.teaser')}</div>
      <ResetStartForm />
    </ContainerLoggedOut>
  );
}

export default ResetStart;
