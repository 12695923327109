import React, { useContext, useMemo, useReducer, useState } from 'react';

import { Skeleton, Button, Divider, Badge, Input, Tooltip, Form, Row, Col } from 'antd';
import { useQuery } from '@apollo/client';
import {
  EditOutlined,
  SearchOutlined,
  InfoCircleOutlined,
  ExportOutlined
} from '@ant-design/icons';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import ModernTable from '../../../../components/ModernTable';
import ResponseNetworkError from '../../../../components/Response/ResponseNetworkError';
import CourseContext from '../../contexts/CourseContextProvider';
import { COURSE_STUDENTS_QUERY } from '../../../../helper/api/queries';
import MinScreenWidth from '../../../../components/MinScreenWidth';
import ModalStudentInfo from '../../components/ModalStudentInfo/ModalStudentInfo';
import TagAllowed from '../../../../components/Tags/TagAllowed';
import DeleteButton from './components/DeleteButton';
import ContainerShadow from '../../../../components/Container/ContainerShadow/ContainerShadow';
import ButtonExport from '../../components/ButtonExport';

import './Students.less';

const VoteBadge = React.memo(({ count, illCount, minVotes, taskCount }) => {
  if (count >= (taskCount - illCount) * minVotes) {
    return <Badge count={count} showZero className="badge-green" />;
  }
  return <Badge count={count} showZero className="badge-grey" />;
});

const PresentBadge = React.memo(({ count, minPresentations }) => {
  if (count >= minPresentations) {
    return <Badge count={count} showZero className="badge-green" />;
  }
  return <Badge count={count} showZero className="badge-grey" />;
});

const ACTIONS_STUDENTS = {
  SET_ORDER: 1
};

function reducerStudents(state, action) {
  switch (action.type) {
    case ACTIONS_STUDENTS.SET_ORDER:
      return { order: action.order, column: action.column };
    default:
      return state;
  }
}

function sortByName(a, b, order) {
  const nameA = `${a.node.firstName} ${a.node.lastName}`.trim().toLowerCase();
  const nameB = `${b.node.firstName} ${b.node.lastName}`.trim().toLowerCase();
  if (nameA > nameB) {
    return order === 'asc' ? 1 : -1;
  }
  if (nameA < nameB) {
    return order === 'asc' ? -1 : 1;
  }
  return 0;
}

function StudentsTable({ query }) {
  const { t } = useTranslation(['common', 'course']);
  const { id } = useContext(CourseContext);
  const [sortState, dispatch] = useReducer(reducerStudents, {
    column: 'student',
    order: 'asc'
  });
  const [modalState, setModalState] = useState({ visible: false, studentId: undefined });

  const { data, error, loading } = useQuery(COURSE_STUDENTS_QUERY, {
    variables: { id, query },
    fetchPolicy: 'cache-and-network'
  });

  const studentsSortedByName = useMemo(() => {
    if (!data?.course?.students) {
      return [];
    }
    return [...data?.course?.students].sort((a, b) => sortByName(a, b, 'asc'));
  }, [data?.course?.students]);

  const sortedStudents = useMemo(() => {
    switch (sortState.column) {
      case 'student':
        if (sortState.order !== 'asc') {
          return [...studentsSortedByName].sort((a, b) => sortByName(a, b, sortState.order));
        }
        return studentsSortedByName;
      case 'voteCount':
      case 'presentCount':
        return [...studentsSortedByName].sort((a, b) =>
          sortState.order === 'asc'
            ? a[sortState.column] - b[sortState.column]
            : b[sortState.column] - a[sortState.column]
        );
      default:
        return studentsSortedByName;
    }
  }, [studentsSortedByName, sortState.column, sortState.order]);

  function onShowStudent(studentId) {
    setModalState({ visible: true, studentId });
  }

  function onCloseModal() {
    setModalState({ visible: false, studentId: undefined });
  }

  function onChangeOrder(order, column) {
    dispatch({ type: ACTIONS_STUDENTS.SET_ORDER, order, column });
  }

  if (loading) {
    return <Skeleton />;
  }

  if (error) {
    return <ResponseNetworkError />;
  }

  return (
    <MinScreenWidth width={568}>
      <ModernTable
        dataSource={sortedStudents}
        columns={[
          {
            title: t('student'),
            dataIndex: 'node',
            key: 'student',
            sortable: true,
            order: sortState.column === 'student' && sortState.order,
            onChangeOrder,
            render: (node) => (
              <span>
                {`${node.firstName} ${node.lastName}`}
                <Tooltip title={t('course:tooltip.showStudentInfo')}>
                  <Button
                    type="link"
                    icon={<InfoCircleOutlined />}
                    onClick={() => onShowStudent(node.id)}
                  />
                </Tooltip>
              </span>
            )
          },
          {
            title: t('isAllowed'),
            dataIndex: 'node',
            key: 'allowed',
            className: 'text-center',
            render: (node, userCourseInfo) => (
              <TagAllowed
                user={node}
                userCourseInfo={userCourseInfo}
                minPresentations={data.course.minPresentations}
                taskCount={data.course.taskCount}
                minVotes={data.course.minVotes}
              />
            )
          },
          {
            title: t('voting_plural'),
            dataIndex: 'voteCount',
            key: 'voteCount',
            sortable: true,
            order: sortState.column === 'voteCount' && sortState.order,
            onChangeOrder,
            className: 'text-center',
            render: (voteCount, { illCount }) => (
              <VoteBadge
                count={voteCount}
                taskCount={data.course.taskCount}
                minVotes={data.course.minVotes}
                illCount={illCount}
              />
            )
          },
          {
            title: t('presentation_plural'),
            dataIndex: 'presentCount',
            key: 'presentCount',
            sortable: true,
            order: sortState.column === 'presentCount' && sortState.order,
            onChangeOrder,
            className: 'text-center',
            render: (presentCount) => (
              <PresentBadge count={presentCount} minPresentations={data.course.minPresentations} />
            )
          },
          {
            dataIndex: 'node',
            key: 'actions',
            className: 'text-right',
            render: (node, { exercise }) => (
              <span>
                <Tooltip title={t('course:tooltip.editStudent')}>
                  <Button
                    icon={<EditOutlined />}
                    shape="circle"
                    onClick={() => onShowStudent(node.id)}
                  />
                </Tooltip>
                <Divider type="vertical" />
                <DeleteButton
                  studentId={node.id}
                  exerciseId={exercise.id}
                  courseId={id}
                  query={query}
                />
              </span>
            )
          }
        ]}
      />
      <ModalStudentInfo
        visible={modalState.visible}
        studentId={modalState.studentId}
        courseId={id}
        onCancel={onCloseModal}
      />
    </MinScreenWidth>
  );
}

function Students() {
  const { t } = useTranslation();
  const { id } = useContext(CourseContext);
  const [query, setQuery] = useState('');

  const onChangeQuery = debounce((newQuery) => {
    setQuery(newQuery);
  }, 300);

  return (
    <>
      <ContainerShadow className="padding-top-sm padding-right-md padding-bottom-sm padding-left-md margin-bottom-md">
        <Row>
          <Col xs={20} md={8} lg={6}>
            <Form.Item
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              label={t('course:searchQuery')}
            >
              <Input
                onChange={({ target }) => onChangeQuery(target.value)}
                placeholder={t('course:searchQuery')}
                style={{ maxWidth: '100%' }}
                suffix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col xs={4} md={16} lg={18} style={{ marginTop: 28 }} className="text-right">
            <ButtonExport
              courseId={id}
              icon={<ExportOutlined />}
              shape="circle"
              size="large"
              style={{ marginLeft: 10 }}
            />
          </Col>
        </Row>
      </ContainerShadow>
      <StudentsTable query={query} />
    </>
  );
}

export default Students;
