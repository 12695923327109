import React from 'react';

import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

function TagVotable({ votable, ignoreNotVotable }) {
  const { t } = useTranslation();

  if (!votable && !ignoreNotVotable) {
    return <Tag color="default">{t('notVotable')}</Tag>;
  }
  if (votable) {
    return <Tag color="success">{t('votable')}</Tag>;
  }
  return null;
}

export default TagVotable;
