import React from 'react';

import { List } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import ContainerShadow from '../Container/ContainerShadow/ContainerShadow';

import './ModernTable.less';

function ModernHeader({ columns }) {
  return (
    <div className="modern-header padding-right-md padding-left-md">
      {columns.map(({ title, key, sortable, order, onChangeOrder, className }) => (
        <div className="column" key={key}>
          <div className={`text-secondary padding-bottom-xs padding-right-sm ${className}`}>
            {sortable && (
              <DownOutlined
                className={`sort-direction padding-right-xs ${order === 'desc' ? 'active' : ''}`}
                onClick={() => onChangeOrder('desc', key)}
              />
            )}
            {sortable && (
              <UpOutlined
                className={`sort-direction padding-right-xs ${order === 'asc' ? 'active' : ''}`}
                onClick={() => onChangeOrder('asc', key)}
              />
            )}
            {title}
          </div>
        </div>
      ))}
    </div>
  );
}

function ModernTable({ dataSource, columns, rowClassName, disabledRowHeaders }) {
  return (
    <List
      itemLayout="horizontal"
      className="modern-list"
      dataSource={dataSource}
      header={<ModernHeader columns={columns} />}
      renderItem={(item, index) => (
        <List.Item className="margin-bottom-md">
          <ContainerShadow
            className={`modern-row padding-left-md padding-right-md padding-top-sm padding-bottom-sm ${
              rowClassName || ''
            }`}
          >
            {columns.map(({ title, key, dataIndex, render, className }) => (
              <div className={`column ${className || ''}`} key={key}>
                {!disabledRowHeaders && (
                  <div className="text-secondary padding-bottom-xs padding-right-xs">{title}</div>
                )}
                {render ? render(item[dataIndex], item, index) : item[dataIndex]}
              </div>
            ))}
          </ContainerShadow>
        </List.Item>
      )}
    />
  );
}

export default ModernTable;
