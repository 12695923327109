import React from 'react';

import { Card } from 'antd';
import { CalendarOutlined, IdcardOutlined, TeamOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import './CardCourse.less';

function CardCourse({ course = {}, onClick }, ref) {
  const { t } = useTranslation();

  return (
    <Card ref={ref} hoverable onClick={onClick} className="main-card course-card">
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', maxWidth: '100%' }}
      >
        <h4 className="title">{course.name}</h4>
      </div>
      <div className="container-main container-statistics padding-top-sm">
        <div className="statistic padding-right-sm">
          <h4 className="padding-right-xs">
            <CalendarOutlined />
          </h4>
          <h1>{course.exerciseCount}</h1>
          <span className="text-secondary">{t('group', { count: course.exerciseCount })}</span>
        </div>
        <div className="statistic padding-right-sm">
          <h4 className="padding-right-xs">
            <IdcardOutlined />
          </h4>
          <h1>{course.studentCount}</h1>
          <span className="text-secondary">{t('student', { count: course.studentCount })}</span>
        </div>
        <div className="statistic">
          <h4 className="padding-right-xs">
            <TeamOutlined />
          </h4>
          <h1>{course.teacherCount}</h1>
          <span className="text-secondary">{t('teacher', { count: course.teacherCount })}</span>
        </div>
      </div>
    </Card>
  );
}

export default React.forwardRef(CardCourse);
