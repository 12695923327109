import gql from 'graphql-tag';

import {
  ACCOUNT_FRAGMENT,
  EXERCISE_FRAGMENT,
  SHEET_FRAGMENT,
  DASHBOARD_COURSES_FRAGMENT,
  DASHBOARD_EXERCISE_FRAGMENT
} from './fragments';

export const LOGIN_MUTATION = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        ...UserData
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export const REGISTER_MUTATION = gql`
  mutation register(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $matriculationNumber: Int
    $study: String
    $degree: Degree
    $exerciseCode: String
    $language: Language!
    $recaptcha: String!
  ) {
    register(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      matriculationNumber: $matriculationNumber
      study: $study
      degree: $degree
      exerciseCode: $exerciseCode
      language: $language
      recaptcha: $recaptcha
    ) {
      user {
        ...UserData
      }
      token
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export const RESET_MUTATION = gql`
  mutation sendEmail($email: String!, $recaptcha: String!) {
    forgot {
      sendEmail(email: $email, recaptcha: $recaptcha)
    }
  }
`;

export const CHECK_TOKEN_MUTATION = gql`
  mutation checkToken($token: String!) {
    forgot {
      checkToken(token: $token)
    }
  }
`;

export const CHANGE_PASSWORD_RESET_MUTATION = gql`
  mutation changePasswordReset($token: String!, $newPassword: String!) {
    forgot {
      changePassword(token: $token, newPassword: $newPassword) {
        token
        user {
          ...UserData
        }
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export const JOIN_EXERCISE_MUTATION = gql`
  mutation joinExercise($code: String!) {
    exercise {
      join(code: $code) {
        ...DashboardExercise
      }
    }
  }
  ${DASHBOARD_EXERCISE_FRAGMENT}
`;

export const JOIN_COURSE_MUTATION = gql`
  mutation joinCourse($course: ID!) {
    course {
      join(course: $course) {
        ...DashboardCourse
      }
    }
  }
  ${DASHBOARD_COURSES_FRAGMENT}
`;

export const REJECT_COURSE_MUTATION = gql`
  mutation rejectCourse($course: ID!) {
    course {
      reject(course: $course)
    }
  }
`;

export const VOTE_BY_STUDENT_MUTATION = gql`
  mutation vote($tasks: [TaskVote]!) {
    vote {
      vote(tasks: $tasks) {
        id
      }
    }
  }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    account {
      changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
        id
      }
    }
  }
`;

export const CHANGE_EMAIL_MUTATION = gql`
  mutation changeEmail($email: String!) {
    account {
      changeEmail(email: $email) {
        id
        email
      }
    }
  }
`;

export const CHANGE_STUDY_INFO_MUTATION = gql`
  mutation changeStudyInfo(
    $matriculationNumber: Int!
    $study: String!
    $degree: Degree!
    $language: Language!
  ) {
    account {
      changeStudyInfo(matriculationNumber: $matriculationNumber, study: $study, degree: $degree) {
        id
        matriculationNumber
        study
        degree
      }
      changeLanguage(language: $language) {
        id
        language
      }
    }
  }
`;

export const DELETE_ACCOUNT_MUTATION = gql`
  mutation deleteAccount($password: String!) {
    account {
      deleteAccount(password: $password)
    }
  }
`;

export const UPDATE_STUDENT_VOTE_MUTATION = gql`
  mutation updateStudentVote($user: ID!, $task: ID!, $state: VoteState, $presented: Boolean) {
    vote {
      update(user: $user, task: $task, state: $state, presented: $presented) {
        voteCount
      }
    }
  }
`;

export const UPDATE_NOTE_MUTATION = gql`
  mutation noteUpdate($user: ID!, $sheet: ID!, $note: String) {
    note {
      update(user: $user, sheet: $sheet, note: $note) {
        note
      }
    }
  }
`;

export const CREATE_COURSE_MUTATION = gql`
  mutation createCourse($name: String!, $minVotes: Float, $minPresentations: Int) {
    course {
      create(name: $name, minVotes: $minVotes, minPresentations: $minPresentations) {
        id
      }
    }
  }
`;

export const CREATE_EXERCISE_MUTATION = gql`
  mutation createExercise(
    $course: ID!
    $name: String!
    $location: String
    $dayOfWeek: Int
    $startTime: String
    $endTime: String
    $startVote: String
    $endVote: String
    $autoVotable: Boolean
  ) {
    exercise {
      create(
        course: $course
        name: $name
        location: $location
        dayOfWeek: $dayOfWeek
        startTime: $startTime
        endTime: $endTime
        startVote: $startVote
        endVote: $endVote
        autoVotable: $autoVotable
      ) {
        ...ExerciseData
      }
    }
  }
  ${EXERCISE_FRAGMENT}
`;

export const CREATE_SHEET_MUTATION = gql`
  mutation createSheet($course: ID!, $name: String!, $tasks: [UpdateTask]!) {
    sheet {
      create(course: $course, name: $name, tasks: $tasks) {
        ...SheetData
      }
    }
  }
  ${SHEET_FRAGMENT}
`;

export const UPDATE_COURSE_MUTATION = gql`
  mutation updateCourse($id: ID!, $name: String!, $minVotes: Float, $minPresentations: Int) {
    course {
      update(id: $id, name: $name, minVotes: $minVotes, minPresentations: $minPresentations) {
        id
        name
        minVotes
        minPresentations
      }
    }
  }
`;

export const DELETE_COURSE_MUTATION = gql`
  mutation deleteCourse($id: ID!) {
    course {
      delete(id: $id) {
        id
      }
    }
  }
`;

export const UPDATE_EXERCISE_MUTATION = gql`
  mutation updateExercise(
    $id: ID!
    $name: String!
    $location: String
    $dayOfWeek: Int
    $startTime: String
    $endTime: String
    $startVote: String
    $endVote: String
    $autoVotable: Boolean
  ) {
    exercise {
      update(
        id: $id
        name: $name
        location: $location
        dayOfWeek: $dayOfWeek
        startTime: $startTime
        endTime: $endTime
        startVote: $startVote
        endVote: $endVote
        autoVotable: $autoVotable
      ) {
        ...ExerciseData
      }
    }
  }
  ${EXERCISE_FRAGMENT}
`;

export const DELETE_EXERCISE_MUTATION = gql`
  mutation deleteExercise($id: ID!) {
    exercise {
      delete(id: $id) {
        id
      }
    }
  }
`;

export const UPDATE_SHEET_MUTATION = gql`
  mutation updateSheet($id: ID!, $name: String!, $tasks: [UpdateTask]!) {
    sheet {
      update(id: $id, name: $name, tasks: $tasks) {
        ...SheetData
      }
    }
  }
  ${SHEET_FRAGMENT}
`;

export const DELETE_SHEET_MUTATION = gql`
  mutation deleteSheet($id: ID!) {
    sheet {
      delete(id: $id) {
        id
      }
    }
  }
`;

export const INVITE_TEACHER_MUTATION = gql`
  mutation inviteTeacher($course: ID!, $email: String!) {
    course {
      invite(course: $course, email: $email) {
        email
      }
    }
  }
`;

export const DELETE_INVITATION_MUTATION = gql`
  mutation deleteInvitation($course: ID!, $email: String!) {
    course {
      deleteInvitation(course: $course, email: $email) {
        id
      }
    }
  }
`;

export const KICK_TEACHER_MUTATION = gql`
  mutation kickTeacher($course: ID!, $user: ID!) {
    course {
      kick(course: $course, user: $user) {
        id
      }
    }
  }
`;

export const PROMOTE_TEACHER_MUTATION = gql`
  mutation promoteTeacher($course: ID!, $user: ID!) {
    course {
      promote(course: $course, user: $user) {
        id
      }
    }
  }
`;

export const DEMOTE_TEACHER_MUTATION = gql`
  mutation demoteTeacher($course: ID!, $user: ID!) {
    course {
      demote(course: $course, user: $user) {
        id
      }
    }
  }
`;

export const MOVE_STUDENT_MUTATION = gql`
  mutation moveStudent($exercise: ID!, $user: ID!) {
    exercise {
      move(exercise: $exercise, user: $user)
    }
  }
`;

export const EXERCISE_KICK_STUDENT = gql`
  mutation kickStudent($exercise: ID!, $user: ID!) {
    exercise {
      kick(exercise: $exercise, user: $user) {
        id
      }
    }
  }
`;

export const UPDATE_VOTABLE_MUTATION = gql`
  mutation updateVotable($exercise: ID!, $sheet: ID!, $votable: Boolean!, $minutes: Int) {
    votable {
      update(exercise: $exercise, sheet: $sheet, votable: $votable, minutes: $minutes) {
        id
      }
    }
  }
`;

export const RESET_VOTES_MUTATION = gql`
  mutation resetVotes($exercise: ID!, $sheet: ID!) {
    vote {
      reset(exercise: $exercise, sheet: $sheet) {
        cursor
      }
    }
  }
`;

export const CHANGE_NOTIFICATION_SETTINGS_MUTATION = gql`
  mutation changeNotificationSettings(
    $weekly: NotificationSetting!
    $voteUpdate: NotificationSetting!
    $endVotingPhase: NotificationSetting!
  ) {
    account {
      changeNotificationSettings(
        weekly: $weekly
        voteUpdate: $voteUpdate
        endVotingPhase: $endVotingPhase
      ) {
        id
        weeklyEmail
        voteEmail
        votePush
        endVotingPhaseEmail
      }
    }
  }
`;

export const EXPORT_EXERCISE_SHEET_MUTATION = gql`
  mutation exportExerciseSheet($exercise: ID!, $sheet: ID!, $format: ExportFormat!) {
    export {
      exerciseSheet(exercise: $exercise, sheet: $sheet, format: $format) {
        token
      }
    }
  }
`;

export const EXPORT_SHEET_MUTATION = gql`
  mutation exportSheet($sheet: ID!, $format: ExportFormat!) {
    export {
      sheet(sheet: $sheet, format: $format) {
        token
      }
    }
  }
`;

export const EXPORT_EXERCISE_MUTATION = gql`
  mutation exportExercise($exercise: ID!, $format: ExportFormat!) {
    export {
      exercise(exercise: $exercise, format: $format) {
        token
      }
    }
  }
`;

export const EXPORT_COURSE_MUTATION = gql`
  mutation exportCourse($course: ID!, $format: ExportFormat!) {
    export {
      course(course: $course, format: $format) {
        token
      }
    }
  }
`;

export const FEEDBACK_MUTATION = gql`
  mutation feedback(
    $type: FeedbackType!
    $category: FeedbackCategory!
    $message: String!
    $platformType: String
    $platformVersion: String
    $email: String
    $useEmail: String
  ) {
    feedback {
      feedback(
        type: $type
        category: $category
        message: $message
        platformType: $platformType
        platformVersion: $platformVersion
        email: $email
        useEmail: $useEmail
      )
    }
  }
`;

export const CHANGE_LANGUAGE_MUTATION = gql`
  mutation changeLanguage($language: Language!) {
    account {
      changeLanguage(language: $language) {
        id
        language
      }
    }
  }
`;
