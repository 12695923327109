import React, { useEffect, useMemo, useState } from 'react';

import { Skeleton, Progress, Divider } from 'antd';
import { ClockCircleOutlined, EnvironmentOutlined, TeamOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery, useSubscription } from '@apollo/client';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';

import ContainerLoggedIn from '../../components/Container/ContainerLoggedIn/ContainerLoggedIn';
import ResponseNetworkError from '../../components/Response/ResponseNetworkError';
import ResponseEmpty from '../../components/Response/ResponseEmpty';
import Statistic from '../../components/Statistic';
import ModernTable from '../../components/ModernTable';
import MinScreenWidth from '../../components/MinScreenWidth';
import useBreakpoint from '../../hooks/useBreakpoint';
import ContainerShadow from '../../components/Container/ContainerShadow/ContainerShadow';
import MetaInformation from '../../components/MetaInformation/MetaInformation';
import { STUDENT_EXERCISE_OVERVIEW } from '../../helper/api/queries';
import DAYS from '../../constants/DAYS';
import {
  STUDENT_SHEET_UPDATE_SUBSCRIPTION,
  STUDENT_EXERCISE_SUMMARY_UPDATE_SUBSCRIPTION
} from '../../helper/api/subscriptions';
import AlertWithButton from '../../components/AlertWithButton';
import ModalStudentVote from '../../components/Modals/ModalStudentVote';
import BadgeVoteStateStudent from '../../components/Badge/BadgeVoteStateStudent';
import BadgePresented from '../../components/Badge/BadgePresented';
import TagAllowed from '../../components/Tags/TagAllowed';
import TagVotable from '../../components/Tags/TagVotable';

import './index.less';

export const SHEETS_ACTIONS = {
  SHEETS_LOADED: 1,
  FETCHING_ERROR: 2
};

function ResponsiveSummary({ exercise }) {
  const { t } = useTranslation(['common']);
  const { toSmall } = useBreakpoint(1007);
  useSubscription(STUDENT_EXERCISE_SUMMARY_UPDATE_SUBSCRIPTION);

  return (
    <ContainerShadow className="wrapper padding-right-md padding-bottom-sm padding-left-md padding-top-sm margin-bottom-md">
      <div className="headline">
        <h3 style={{ margin: 0 }}>{t('exercisePage.statistic')}</h3>
        <span className="padding-left-md">
          <TagAllowed
            userCourseInfo={exercise}
            minVotes={exercise.course.minVotes}
            taskCount={exercise.course.taskCount}
            minPresentations={exercise.course.minPresentations}
          />
        </span>
      </div>
      <Divider type="horizontal" />
      <div className="container-progress">
        <Progress
          type={toSmall ? 'line' : 'circle'}
          className="progress"
          strokeWidth={10}
          percent={
            exercise.course.taskCount === 0
              ? 0
              : (
                  (exercise.voteCount / (exercise.course.taskCount - exercise.illCount)) *
                  100
                ).toFixed(2)
          }
        />
      </div>
      <div className="container-statistics padding-top-md">
        <Statistic
          reached={exercise.voteCount}
          max={exercise.course.taskCount}
          title={t('voting_plural')}
        />
        <Statistic
          reached={exercise.presentCount}
          max={exercise.course.minPresentations}
          title={t('presentation_plural')}
        />
      </div>
    </ContainerShadow>
  );
}

function VotableList({ sheets, exercise }) {
  const { t } = useTranslation(['common']);
  const [stateVoteModal, setStateVoteModal] = useState({ visible: false, sheet: undefined });

  useEffect(() => {
    if (sheets.length === 0 && stateVoteModal.visible) {
      setStateVoteModal({ visible: false, sheet: undefined });
    }
  }, [sheets.length]);

  function onOpenVoteModal(sheet) {
    setStateVoteModal({ visible: true, votableSheet: sheet });
  }

  function onCloseVoteModal() {
    setStateVoteModal({ visible: false, sheet: undefined });
  }

  return (
    <>
      <ModalStudentVote
        visible={stateVoteModal.visible}
        votableSheet={stateVoteModal.votableSheet}
        currentExercise={exercise}
        onClose={onCloseVoteModal}
      />
      {sheets.map((sheet) => (
        <AlertWithButton
          key={sheet.node.id}
          message={t('exerciseNowVotable', { name: sheet.node.name })}
          className="margin-bottom-md"
          buttons={[
            {
              title: t('voteNow'),
              type: 'primary',
              onClick: () => onOpenVoteModal(sheet)
            }
          ]}
          type="info"
          showIcon
        />
      ))}
    </>
  );
}

function Sheets({ sheets, exercise }) {
  const { t } = useTranslation(['common']);
  const votableSheets = useMemo(() => sheets.filter((sheet) => sheet.node.votable), [sheets]);

  const infos = [{ icon: TeamOutlined, info: exercise.name }];

  if (exercise.dayOfWeek && exercise.startTime && exercise.endTime) {
    infos.push({
      icon: ClockCircleOutlined,
      info: `${DAYS[exercise.dayOfWeek]}. ${exercise.startTime.substr(
        0,
        5
      )} - ${exercise.endTime.substr(0, 5)}`
    });
  }

  if (exercise.location) {
    infos.push({ icon: EnvironmentOutlined, info: exercise.location });
  }

  return (
    <div style={{ width: '100%' }}>
      <MetaInformation infos={infos} />
      <VotableList sheets={votableSheets} exercise={exercise} />
      <div className="container-exercise-main">
        <div className="container-detailed">
          <MinScreenWidth width={568}>
            <ModernTable
              columns={[
                {
                  title: t('sheetShort'),
                  dataIndex: 'node',
                  key: 'name',
                  render: ({ name, votable }) => (
                    <span>
                      {name}
                      <br />
                      <TagVotable color="blue" votable={votable} ignoreNotVotable />
                    </span>
                  )
                },
                {
                  title: t('exercisePage.date'),
                  dataIndex: 'node',
                  key: 'date',
                  render: ({ date }) => {
                    if (moment(date).isValid()) {
                      return moment(date).format('dd. D.M.YY');
                    }
                    return t('exercisePage.invalidDate');
                  }
                },
                {
                  title: t('task'),
                  dataIndex: 'node',
                  key: 'task',
                  render: ({ votes }) =>
                    votes.map((vote) => (
                      <div key={vote.task.id} className="padding-bottom-xs">
                        {vote.task.name}
                      </div>
                    ))
                },
                {
                  title: t('voting_plural'),
                  dataIndex: 'node',
                  key: 'votes',
                  render: ({ votes }) =>
                    votes.map((vote) => (
                      <div key={vote.task.id} className="padding-bottom-xs padding-left-md">
                        <BadgeVoteStateStudent state={vote.state} />
                      </div>
                    ))
                },
                {
                  title: t('presentation_plural'),
                  dataIndex: 'node',
                  key: 'presented',
                  render: ({ votes }) =>
                    votes.map((vote) => (
                      <div key={vote.task.id} className="padding-bottom-xs padding-left-md">
                        <BadgePresented presented={vote.presented} />
                      </div>
                    ))
                }
              ]}
              dataSource={sheets}
            />
          </MinScreenWidth>
        </div>
        <div className="container-summary">
          <ResponsiveSummary exercise={exercise} />
        </div>
      </div>
    </div>
  );
}

function SheetsApi({ exerciseId }) {
  const { t } = useTranslation(['common']);
  const { data, loading, error, subscribeToMore } = useQuery(STUDENT_EXERCISE_OVERVIEW, {
    variables: { id: exerciseId },
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    if (data && subscribeToMore && exerciseId) {
      const unsubscribe = subscribeToMore({
        document: STUDENT_SHEET_UPDATE_SUBSCRIPTION,
        variables: { exercise: exerciseId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          // eslint-disable-next-line no-underscore-dangle
          switch (subscriptionData.data.studentSheetUpdate.__typename) {
            case 'NewSheet':
              return update(prev, {
                exercise: {
                  sheets: {
                    $splice: [[0, 0, { node: subscriptionData.data.studentSheetUpdate.sheet }]]
                  }
                }
              });
            case 'Deleted': {
              const index = prev.exercise.sheets.findIndex(
                (sheet) => sheet.node.id === subscriptionData.data.studentSheetUpdate.deleted
              );
              return update(prev, {
                exercise: {
                  sheets: {
                    $splice: [[index, 1]]
                  }
                }
              });
            }
            case 'StudentSheetInfo':
            default:
              return prev;
          }
        }
      });
      return unsubscribe;
    }
    return undefined;
  }, [data, subscribeToMore, exerciseId]);

  if (loading) {
    return <Skeleton active />;
  }

  if (error) {
    return <ResponseNetworkError />;
  }

  if (data?.exercise.sheets.length === 0) {
    return <ResponseEmpty title={t('exercisePage.res.noSheets')} />;
  }

  return <Sheets sheets={data.exercise.sheets} exercise={data.exercise} />;
}

function Exercise() {
  const { t } = useTranslation();
  const location = useLocation();
  const { id, name } = useParams();

  return (
    <ContainerLoggedIn
      routes={[
        { title: t('myCourses'), href: '/' },
        { title: name, href: location.pathname }
      ]}
    >
      <h2 style={{ marginBottom: 0 }}>{name}</h2>
      <SheetsApi exerciseId={id} />
    </ContainerLoggedIn>
  );
}

export default Exercise;
