import React from 'react';

import './styles.less';

function MetaInformation({ infos, style }) {
  return (
    <div className="container-meta-information text-secondary padding-bottom-sm" style={style}>
      {infos
        .filter((e) => e)
        .map(({ icon: Icon, info }) => (
          <span className="padding-right-xs" key={info}>
            <Icon className="padding-right-xs" />
            {info}
          </span>
        ))}
    </div>
  );
}

export default MetaInformation;
