export const ACTION_REGISTER_STEPS = {
  SELECT_USER_TYPE: 1,
  SET_STUDENT_DATA: 2,
  SET_ACCOUNT_DATA: 3,
  SET_ACCOUNT_CREDENTIALS: 4,
  PREV: 5
};

export const STEPS = {
  SELECT_USER_TYPE: 1,
  SET_STUDENT_DATA: 2,
  SET_ACCOUNT_DATA: 3,
  SET_ACCOUNT_CREDENTIALS: 4
};

export const USER_TYPES = {
  STUDENT: 'teacher',
  TEACHER: 'student'
};

export function reducerRegisterSteps(state, action) {
  switch (action.type) {
    case ACTION_REGISTER_STEPS.SELECT_USER_TYPE:
      if (action.userType === USER_TYPES.STUDENT) {
        return { ...state, userType: action.userType, step: STEPS.SET_STUDENT_DATA };
      }
      return { ...state, userType: action.userType, step: STEPS.SET_ACCOUNT_DATA };
    case ACTION_REGISTER_STEPS.SET_STUDENT_DATA:
      return {
        ...state,
        matriculationNumber: action.matriculationNumber,
        study: action.study,
        degree: action.degree,
        step: STEPS.SET_ACCOUNT_DATA
      };
    case ACTION_REGISTER_STEPS.SET_ACCOUNT_DATA:
      return {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
        language: action.language,
        step: STEPS.SET_ACCOUNT_CREDENTIALS
      };
    case ACTION_REGISTER_STEPS.SET_ACCOUNT_CREDENTIALS:
      return { ...state, email: action.email, password: action.password };
    case ACTION_REGISTER_STEPS.PREV: {
      switch (state.step) {
        case STEPS.SET_STUDENT_DATA:
          return { ...state, step: STEPS.SELECT_USER_TYPE };
        case STEPS.SET_ACCOUNT_DATA:
          if (state.userType === USER_TYPES.STUDENT) {
            return { ...state, step: STEPS.SET_STUDENT_DATA };
          }
          return { ...state, step: STEPS.SELECT_USER_TYPE };
        case STEPS.SET_ACCOUNT_CREDENTIALS:
          return { ...state, step: STEPS.SET_ACCOUNT_DATA };
        case STEPS.SELECT_USER_TYPE:
        default:
          return state;
      }
    }
    default:
      return state;
  }
}
