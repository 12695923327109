import React, { useState, useEffect, useContext } from 'react';

import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Spin, Form, Input, message, Alert } from 'antd';
import { useTranslation } from 'react-i18next';

import ContainerLoggedOut from '../../components/Container/ContainerLoggedOut/ContainerLoggedOut';
import ResponseNetworkError from '../../components/Response/ResponseNetworkError';
import PrimaryButton from '../../components/PrimaryButton';
import { CHECK_TOKEN_MUTATION, CHANGE_PASSWORD_RESET_MUTATION } from '../../helper/api/mutations';
import AccountContext from '../../contexts/AccountContextProvider';

function ResetFinalizeForm({ token }) {
  const { t } = useTranslation(['common']);
  const { connectAccount } = useContext(AccountContext);

  const [form] = Form.useForm();
  const [changePassword, { loading, error }] = useMutation(CHANGE_PASSWORD_RESET_MUTATION, {
    onCompleted,
    onError
  });

  function onCompleted({ forgot: { changePassword: changePasswordData } }) {
    message.success(t('res.success.passwordChange'));
    connectAccount(changePasswordData.token, changePasswordData.user);
  }

  function onError() {
    message.error(t('res.error.unknownError'));
  }

  function onChangePassword() {
    return form
      .validateFields()
      .then((values) => changePassword({ variables: { newPassword: values.newPassword, token } }))
      .catch(() => {});
  }

  return (
    <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      {error && (
        <Alert
          className="margin-bottom-md"
          description={t('res.error.unknownErrorAlert')}
          type="error"
          closable
        />
      )}
      <Form.Item
        hasFeedback
        label={t('yourPassword')}
        name="newPassword"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: 'validation.emptyPassword' },
          { min: 6, message: 'validation.invalidPassword' }
        ]}
      >
        <Input.Password
          size="large"
          placeholder={t('yourPassword')}
          type="password"
          autoComplete="new-password"
          autoFocus
        />
      </Form.Item>

      <Form.Item
        hasFeedback
        label={t('yourPasswordRepeat')}
        name="newPassword-repeat"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: t('validation.emptyRepeatPassword') },
          ({ getFieldValue }) => ({
            validator(_rule, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject(t('validation.invalidRepeatPassword'));
            }
          })
        ]}
      >
        <Input.Password
          size="large"
          placeholder={t('yourPasswordRepeat')}
          type="password"
          autoComplete="new-password"
        />
      </Form.Item>
      <PrimaryButton
        disabled={loading}
        style={{ width: '100%' }}
        size="large"
        htmlType="submit"
        className="margin-bottom-md"
        loading={loading}
        onClick={onChangePassword}
      >
        {t('changePassword')}
      </PrimaryButton>
    </Form>
  );
}

function ResetFinalizeValidation({ children, token }) {
  const [{ loading, accepted }, setState] = useState({ loading: !!token, accepted: !!token });
  const [checkToken, { error }] = useMutation(CHECK_TOKEN_MUTATION, { onCompleted, onError });

  useEffect(() => {
    if (token) {
      checkToken({ variables: { token } });
    }
    if (!token && accepted) {
      setState({ loading: false, accepted: false });
    }
  }, [token]);

  function onCompleted({ forgot: { checkToken: checkTokenData } }) {
    setState({ loading: false, accepted: checkTokenData });
  }

  function onError() {
    setState({ loading: false, accepted: false });
  }

  if (loading) {
    return (
      <div style={{ width: '100%' }} className="text-center">
        <Spin />
      </div>
    );
  }

  if (error || !accepted) {
    return <ResponseNetworkError />;
  }

  return children;
}

function ResetFinalize() {
  const { t } = useTranslation(['common']);
  const { token } = useParams();

  return (
    <ContainerLoggedOut withGoBack title={t('changePassword')} image="/assets/icons/forget.svg">
      <ResetFinalizeValidation token={token}>
        <ResetFinalizeForm token={token} />
      </ResetFinalizeValidation>
    </ContainerLoggedOut>
  );
}

export default ResetFinalize;
