import { useContext } from 'react';

import { useApolloClient } from '@apollo/client';

import AccountContext from '../contexts/AccountContextProvider';

function useLogout() {
  const client = useApolloClient();
  const { disconnectAccount } = useContext(AccountContext);

  function logout() {
    disconnectAccount();
    return client.resetStore();
  }

  return logout;
}

export default useLogout;
