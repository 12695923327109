import React, { Component } from 'react';

import * as Sentry from '@sentry/browser';
import { Button } from 'antd';

import ContainerError from '../components/Container/ContainerError/ContainerError';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };

    if (process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_SENTRY_RELEASE) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        release: process.env.REACT_APP_SENTRY_RELEASE,
        environment: process.env.REACT_APP_SENTRY_ENV,
        ignoreErrors: ['Unauthorized']
      });
      Sentry.configureScope((scope) => {
        scope.setUser({ ip_address: null });
      });
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_SENTRY_RELEASE) {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
        // const eventId = Sentry.captureException(error);
        // Sentry.showReportDialog({ eventId });
      });
    }
  }

  onReloadSite = () => {
    window.location.reload();
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <ContainerError>
          <img
            src="/assets/icons/bug.svg"
            alt="error 404"
            className="padding-bottom-md"
            style={{ width: '100%', maxWidth: 500 }}
          />
          <h1 style={{ color: '#fff' }}>Unbekannter Fehler</h1>
          <span style={{ color: '#fff' }}>
            Aufgrund eines Problems wurde dir diese Seite angezeigt. Lade die Seite erneut.
            <br />
            Sollte es dennoch zu Problemen kommen, kontaktiere uns bitte unter
            {' '}
            <a href="mailto:info@finvote.de" style={{ color: '#fff', textDecoration: 'underline' }}>
              info@finvote.de
            </a>
          </span>
          <div className="margin-bottom-md" />
          <Button onClick={this.onReloadSite} type="primary" size="large">
            Seite neu laden
          </Button>
        </ContainerError>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
