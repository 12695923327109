import React, { useContext } from 'react';

import { Form, Input, Button, Tooltip } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { ACTIONS_CREATE_COURSE } from '../reducer';
import StepsWrapper from '../components/StepsWrapper';
import AccountContext from '../../../contexts/AccountContextProvider';

function AddUser({ state, disptach, onPrev }) {
  const { t } = useTranslation(['common', 'createCourse']);
  const { account } = useContext(AccountContext);
  const [form] = Form.useForm();

  function onSubmit() {
    return form
      .validateFields()
      .then((values) =>
        disptach({
          type: ACTIONS_CREATE_COURSE.SET_USERS,
          users: values.users
        })
      )
      .catch();
  }

  const onSkip = onSubmit;

  function onFocusInput(index, fields, addField) {
    if (index === fields.length - 1) {
      addField('');
    }
  }

  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={{
        users: state.users
      }}
    >
      <StepsWrapper
        title={t('addTeacher')}
        description={t('createCourse:addTeacher.description')}
        onSkip={onSkip}
        onPrev={onPrev}
        onSubmit={onSubmit}
      >
        <Form.List name="users">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      hasFeedback
                      validateFirst
                      style={{ flex: 1 }}
                      dependencies={['users']}
                      label={`${t('teacher')} ${index + 1}`}
                      validateTrigger="onBlur"
                      rules={[
                        {
                          type: 'email',
                          message: t('validation.invalidEmail')
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value) {
                              return Promise.resolve();
                            }
                            if (value.toLowerCase() === account.email.toLowerCase()) {
                              // eslint-disable-next-line prefer-promise-reject-errors
                              return Promise.reject(t('createCourse:validation.invalidEmail'));
                            }
                            const sameUsers = getFieldValue('users').filter(
                              (user) => user.toLowerCase() === value.toLowerCase()
                            );
                            if (sameUsers.length > 1) {
                              // eslint-disable-next-line prefer-promise-reject-errors
                              return Promise.reject(
                                t('createCourse:validation.inviteAlreadyEntered')
                              );
                            }
                            return Promise.resolve();
                          }
                        })
                      ]}
                    >
                      <Input
                        placeholder={t('email')}
                        type="email"
                        style={{ width: '100%' }}
                        onFocus={() => onFocusInput(index, fields, add)}
                      />
                    </Form.Item>
                    <div className="padding-left-md">
                      {fields.length > 1 && (
                        <Tooltip title={t('tooltip.kickTeacher')}>
                          <Button
                            icon={<MinusOutlined />}
                            size="small"
                            shape="circle"
                            onClick={() => remove(index)}
                          />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                ))}
                <Form.Item noStyle>
                  <Button
                    type="dashed"
                    style={{ width: '100%' }}
                    onClick={() => add('')}
                    icon={<PlusOutlined />}
                  >
                    {t('addTeacher')}
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
      </StepsWrapper>
    </Form>
  );
}

export default AddUser;
