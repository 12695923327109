import React from 'react';

import { Button, Modal, message, Tooltip } from 'antd';
import { useMutation } from '@apollo/client';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { RESET_VOTES_MUTATION } from '../../../../../helper/api/mutations';
import { EXERCISE_SHEET_QUERY } from '../../../../../helper/api/queries';

function ButtonResetCourse({ sheetId, exerciseId, ...buttonProps }) {
  const { t } = useTranslation(['common', 'course']);
  const [reset, { loading }] = useMutation(RESET_VOTES_MUTATION, { onCompleted, onError });

  function onCompleted() {
    message.success(t('course:resetCourse.res.votesReset'));
  }

  function onError() {
    message.error(t('course:resetCourse.res.votesResetFail'));
  }

  function onReset() {
    return reset({
      variables: { sheet: sheetId, exercise: exerciseId },
      refetchQueries: [
        { query: EXERCISE_SHEET_QUERY, variables: { sheet: sheetId, exercise: exerciseId } }
      ]
    });
  }

  function onShowConfirmation() {
    Modal.confirm({
      title: t('course:resetCourse.title'),
      icon: <CloseCircleOutlined />,
      content: t('course:resetCourse.sure'),
      okText: t('course:resetCourse.okButton'),
      cancelText: t('buttons.cancel'),
      okButtonProps: { type: 'danger' },
      maskClosable: true,
      onOk: onReset,
      onCancel() {}
    });
  }

  return (
    <Tooltip title={t('course:tooltip.resetCourse')}>
      <Button
        {...buttonProps}
        disabled={loading || (!sheetId && !exerciseId)}
        onClick={onShowConfirmation}
      />
    </Tooltip>
  );
}

export default ButtonResetCourse;
