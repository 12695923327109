/**
 * Important: if you change something at the theme, you also have to adjust the fallback version
 */

import React from 'react';

import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import Footer from '../../Footer';

import '../shared.less';

function ContainerBlank({ children, title, withGoBack }) {
  const { t } = useTranslation();

  return (
    <div>
      {withGoBack && (
        <div className="container-go-back padding-top-md padding-left-md">
          <Link to="/login">
            <Button icon={<LeftOutlined />} size="large" shape="round">
              {t('toHome')}
            </Button>
          </Link>
        </div>
      )}
      <div className="content">
        <div className="text-center">
          <img src="/assets/icons/cards-blue.svg" alt="FinVote Icon" className="logo" />
          <h1 className="margin-bottom-lg">{title}</h1>
        </div>
        {children}
      </div>
      <Footer />
    </div>
  );
}

export function ContainerBlankFallback() {
  return (
    <div>
      <div className="content">
        <div className="text-center">
          <img src="/assets/icons/cards-blue.svg" alt="FinVote Icon" className="logo" />
        </div>
      </div>
    </div>
  );
}

export default ContainerBlank;
