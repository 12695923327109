import React, { useMemo } from 'react';
import { Progress } from 'antd';
import COLORS from '../constants/COLORS';

function ProgressStudent({ taskCount, voteCount, illCount, minVotes }) {
  const percentage = taskCount - illCount <= 0 ? 0 : (voteCount / (taskCount - illCount)) * 100;

  const strokeColor = useMemo(() => {
    if (percentage >= minVotes * 100) {
      return COLORS.SUCCESS;
    }
    if (percentage >= minVotes / 0.02) {
      return COLORS.WARNING;
    }
    return COLORS.ERROR;
  }, [percentage]);

  return (
    <Progress
      type="circle"
      format={(percent) => (
        <span className="text-secondary" style={{ fontSize: 14 }}>
          {percent.toFixed(2)}
          %
        </span>
      )}
      percent={percentage}
      width={80}
      strokeWidth={10}
      strokeColor={strokeColor}
    />
  );
}

export default ProgressStudent;
