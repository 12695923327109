import React, { Suspense } from 'react';

import { Route } from 'react-router-dom';

function RouteSuspense({ fallback, component: Component, children, ...props }) {
  return (
    <Route {...props}>
      <Suspense fallback={fallback}>
        <Component />
      </Suspense>
    </Route>
  );
}

export default RouteSuspense;
