import React from 'react';

import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { message, Modal, Input, Form, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { JOIN_EXERCISE_MUTATION } from '../../helper/api/mutations';
import getErrorCode from '../../helper/getErrorCode';
import { cacheUpdateJoinExercise } from '../../helper/cacheUpdate';

function ModalEnterExerciseForm({ onCancel }) {
  const { t } = useTranslation();
  const history = useHistory();

  const [exerciseJoin, { loading }] = useMutation(JOIN_EXERCISE_MUTATION, { onCompleted, onError });
  const [form] = Form.useForm();

  function onCompleted({ exercise: { join } }) {
    onCancel();
    message.success(t('modal.joinExercise.res.joinExercise', { exerciseName: join.course.name }));
    history.push(`/exercise/${join.id}/${join.course.name}`);
  }

  function onError(error) {
    const code = getErrorCode(error);
    switch (code) {
      case 412:
        form.setFields([
          { touched: true, errors: [t('modal.joinExercise.res.invalidCode')], name: 'code' }
        ]);
        break;
      case 417:
        form.setFields([
          {
            touched: true,
            errors: [t('modal.joinExercise.res.notFullAccountData')],
            name: 'code'
          }
        ]);
        break;
      case 409:
        form.setFields([
          {
            touched: true,
            errors: [t('modal.joinExercise.res.alreadyExerciseMember')],
            name: 'code'
          }
        ]);
        break;
      default:
        form.setFields([{ touched: true, errors: [t('res.error.unknownError')], name: 'code' }]);
    }
  }

  function onSubmit() {
    return form
      .validateFields()
      .then((values) =>
        exerciseJoin({
          variables: { code: values.code },
          update: cacheUpdateJoinExercise({}, (result) => ({ exercise: result.data.exercise.join }))
        })
      )
      .catch(() => {});
  }

  return (
    <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <h3>{t('modal.joinExercise.title')}</h3>
      <Form.Item
        label={t('exerciseCode')}
        name="code"
        validateTrigger="onBlur"
        required
        rules={[{ required: true, message: t('modal.joinExercise.validation.emptyCode') }]}
      >
        <Input placeholder={t('exerciseCode')} onPressEnter={onSubmit} autoFocus />
      </Form.Item>
      <div className="text-right padding-top-md">
        <span className="padding-right-sm">
          <Button onClick={onCancel}>{t('buttons.close')}</Button>
        </span>
        <Button type="primary" onClick={onSubmit} loading={loading} disabled={loading}>
          {t('modal.joinExercise.okButton')}
        </Button>
      </div>
    </Form>
  );
}

function ModalEnterExercise({ visible, onCancel }) {
  return (
    <Modal visible={visible} onCancel={onCancel} closable footer={null} destroyOnClose>
      <ModalEnterExerciseForm onCancel={onCancel} />
    </Modal>
  );
}

export default ModalEnterExercise;
