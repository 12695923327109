import React, { useState } from 'react';

import { Button, Modal, message, Input, Form, Checkbox } from 'antd';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import MainContainer from '../components/MainContainer';
import { DELETE_ACCOUNT_MUTATION } from '../../../helper/api/mutations';
import getErrorCode from '../../../helper/getErrorCode';
import useLogout from '../../../hooks/useLogout';

function ExtendedConfirmModal({ visible, onClose }) {
  const { t } = useTranslation(['common', 'settings']);
  const logout = useLogout();
  const [deleteAccount, { loading }] = useMutation(DELETE_ACCOUNT_MUTATION, {
    onCompleted,
    onError
  });

  const [form] = Form.useForm();

  function onCompleted() {
    message.success(t('settings:extended.res.deleted'));
    logout();
  }

  function onError(error) {
    const code = getErrorCode(error);

    switch (code) {
      case 403:
        form.setFields([
          {
            errors: [t('settings:validation.wrongPassword')],
            name: 'password'
          }
        ]);
        break;
      default:
        message.error(t('res.error.unknownError'));
        break;
    }
  }

  function onDelete() {
    return form
      .validateFields()
      .then((values) => deleteAccount({ variables: { password: values.password } }))
      .catch(() => {});
  }

  function onCancel() {
    form.resetFields();
    onClose();
  }

  return (
    <Modal
      title={t('settings:extended.modalConfirm.title')}
      visible={visible}
      closable
      onCancel={onCancel}
      okButtonProps={{ type: 'danger', loading }}
      okText={t('settings:extended.modalConfirm.okButton')}
      cancelText={t('buttons.cancel')}
      onOk={onDelete}
    >
      <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
        <Form.Item
          label={t('settings:currentPassword')}
          name="password"
          validateTrigger="onBlur"
          rules={[{ required: true, message: t('settings:validation.emptyCurrentPassword') }]}
        >
          <Input.Password type="password" placeholder={t('settings:currentPassword')} />
        </Form.Item>
        <Form.Item
          name="serious"
          valuePropName="checked"
          rules={[{ required: true, message: t('settings:extended.validation.emptySure') }]}
        >
          <Checkbox>{t('settings:extended.confirm')}</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
}

function DelectSection() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  function onOpenModal() {
    setShowModal(true);
  }

  function onCloseModal() {
    setShowModal(false);
  }

  return (
    <div>
      <ExtendedConfirmModal visible={showModal} onClose={onCloseModal} />
      <h3>{t('settings:extended.title')}</h3>
      <span className="text-secondary">
        {t('settings:extended.phrase1')} 
        {' '}
        <strong>{t('settings:extended.phrase2')}</strong>
        {' '}
        {t('settings:extended.phrase3')}
        <br />
        <br />
        {t('settings:extended.phrase4')} 
        {' '}
        <strong>{t('settings:extended.phrase5')}</strong>
        !
        <br />
        <br />
        {t('settings:extended.phrase6')} 
        {' '}
        <strong>{t('settings:extended.phrase7')}</strong>
        .
      </span>
      <br />
      <br />
      <Button danger style={{ width: '100%' }} onClick={onOpenModal}>
        {t('settings:extended.title')}
      </Button>
    </div>
  );
}

function Extended() {
  return (
    <MainContainer image="/assets/icons/settings-extended.svg">
      <DelectSection />
    </MainContainer>
  );
}

export default Extended;
