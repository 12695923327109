import React, { useMemo } from 'react';

import { Button, Dropdown, Menu, Typography, message } from 'antd';
import { useMutation } from '@apollo/client';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SmileOutlined,
  FrownOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import BadgeVoteStateStudent from './BadgeVoteStateStudent';
import VOTE_STATE from '../../constants/VOTE_STATE';
import { UPDATE_STUDENT_VOTE_MUTATION } from '../../helper/api/mutations';
import { updateCacheVotePresentation } from '../../helper/cacheUpdate';
import getErrorCode from '../../helper/getErrorCode';

function BadgeVoteStateTeacher({ state, presented, userId, taskId }) {
  const { t } = useTranslation();

  const menuItems = useMemo(() => {
    const items = [];
    if (state === VOTE_STATE.VOTED && presented) {
      items.push(
        <Menu.Item onClick={() => onUpdatePresentationState(false)} key="resetPresentation">
          <Typography.Text type="danger">
            <CloseCircleOutlined />
            {t('voteAction.resetPresentation')}
          </Typography.Text>
        </Menu.Item>
      );
    } else if (state === VOTE_STATE.VOTED) {
      items.push(
        <Menu.Item onClick={() => onUpdatePresentationState(true)} key="givePresentation">
          <CheckCircleOutlined />
          {t('voteAction.givePresentation')}
        </Menu.Item>
      );
    }

    if (state === VOTE_STATE.VOTED) {
      items.push(<Menu.Divider key="dividerPresentation" />);
    }

    switch (state) {
      case VOTE_STATE.NOT_VOTED:
        items.push(
          <Menu.Item onClick={() => onUpdateVoteState(VOTE_STATE.VOTED)} key="giveVote">
            <CheckCircleOutlined />
            {t('voteAction.give')}
          </Menu.Item>
        );
        break;
      case VOTE_STATE.REJECTED:
        items.push(
          <Menu.Item onClick={() => onUpdateVoteState(VOTE_STATE.NOT_VOTED)} key="resetVote">
            <CheckCircleOutlined />
            {t('voteAction.reset')}
          </Menu.Item>
        );
        items.push(
          <Menu.Item onClick={() => onUpdateVoteState(VOTE_STATE.VOTED)} key="giveVote">
            <CheckCircleOutlined />
            {t('voteAction.give')}
          </Menu.Item>
        );
        break;
      case VOTE_STATE.VOTED:
        items.push(
          <Menu.Item onClick={() => onUpdateVoteState(VOTE_STATE.NOT_VOTED)} key="resetVote">
            <CloseCircleOutlined />
            {t('voteAction.reset')}
          </Menu.Item>
        );
        items.push(
          <Menu.Item onClick={() => onUpdateVoteState(VOTE_STATE.REJECTED)} key="rejectVote">
            <Typography.Text type="danger">
              <CloseCircleOutlined />
              {t('voteAction.reject')}
            </Typography.Text>
          </Menu.Item>
        );
        break;
      default:
        break;
    }

    items.push(<Menu.Divider key="dividerVote" />);

    if (state === VOTE_STATE.ILL) {
      items.push(
        <Menu.Item onClick={() => onUpdateVoteState(VOTE_STATE.NOT_VOTED)} key="resetIll">
          <SmileOutlined />
          {t('voteAction.rejectIll')}
        </Menu.Item>
      );
    } else {
      items.push(
        <Menu.Item onClick={() => onUpdateVoteState(VOTE_STATE.ILL)} key="setIll">
          <Typography.Text type="danger">
            <FrownOutlined />
            {t('voteAction.setIll')}
          </Typography.Text>
        </Menu.Item>
      );
    }

    return items;
  }, [state, presented]);

  const [updateVote, { loading }] = useMutation(UPDATE_STUDENT_VOTE_MUTATION, {
    onCompleted,
    onError
  });

  function onCompleted() {
    message.success(t('res.success.voteUpdate'));
  }

  function onError(error) {
    const code = getErrorCode(error);

    switch (code) {
      case 409:
        message.error(t('res.error.studentNotMember'));
        break;
      case 404:
      default:
        message.error(t('res.error.unknownError'));
        break;
    }
  }

  function onUpdateVoteState(newState) {
    updateVote({
      variables: { user: userId, task: taskId, state: newState },
      update: updateCacheVotePresentation({ userId, taskId }, { state: newState, presented })
    });
  }

  function onUpdatePresentationState(newPresented) {
    updateVote({
      variables: { user: userId, task: taskId, presented: newPresented },
      update: updateCacheVotePresentation({ userId, taskId }, { state, presented: newPresented })
    });
  }

  const overlay = <Menu>{menuItems}</Menu>;

  return (
    <Dropdown overlay={overlay}>
      <Button type="link" shape="circle" loading={loading}>
        <BadgeVoteStateStudent state={state} />
      </Button>
    </Dropdown>
  );
}

export default BadgeVoteStateTeacher;
