import React, { useContext, useRef, useEffect, useMemo } from 'react';

import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  split,
  defaultDataIdFromObject
} from '@apollo/client';
import { onError } from '@apollo/link-error';
import { WebSocketLink } from '@apollo/link-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import AccountContext from '../contexts/AccountContextProvider';

const dataIdFromObject = (o) => {
  // eslint-disable-next-line no-underscore-dangle
  if (o?.__typename != null && o?.id != null) {
    // eslint-disable-next-line no-underscore-dangle
    return `${o.__typename}-${o.id}`;
  }
  // eslint-disable-next-line no-underscore-dangle
  if (o?.__typename === 'Vote' && o.user?.id && o.task?.id) {
    // eslint-disable-next-line no-underscore-dangle
    return `${o.__typename}-${o.user.id}-${o.task.id}`;
  }
  // eslint-disable-next-line no-underscore-dangle
  if (o?.__typename?.includes('Edge') && o?.cursor != null) {
    // eslint-disable-next-line no-underscore-dangle
    return `${o.__typename}-${o.cursor}`;
  }

  return defaultDataIdFromObject(o);
};

const apolloHttpLink = new HttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_HOST}/graphql`
});

const subscriptionLink = new SubscriptionClient(process.env.REACT_APP_GRAPHQL_WS_HOST, {
  reconnect: true,
  lazy: true
});

const cache = new InMemoryCache({
  cacheRedirects: {
    Query: {}
  },
  dataIdFromObject
});

function Apollo({ children }) {
  const { token, disconnectAccount } = useContext(AccountContext);
  const refToken = useRef(token);
  const refClient = useRef(undefined);

  refToken.current = token;

  function onUnauthorized() {
    if (refClient.current) {
      refClient.current.resetStore();
    }
    disconnectAccount();
  }

  const client = useMemo(() => {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map((error) => {
          const { message, locations, path, extensions } = error;
          if (extensions && extensions.code === 401) {
            onUnauthorized();
          } else if (process.env.REACT_APP_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
          }
          return error;
        });
      }
      if (networkError && process.env.REACT_APP_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log(`[Network error]: ${networkError}`);
      }
    });

    const authMiddleware = new ApolloLink((operation, forward) => {
      operation.setContext(({ authorization }) => {
        return {
          headers: {
            authorization: authorization || refToken.current
          }
        };
      });
      return forward(operation);
    });

    const link = ApolloLink.from([
      split(({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
      }, new WebSocketLink(subscriptionLink)),
      authMiddleware,
      errorLink,
      apolloHttpLink
    ]);

    return new ApolloClient({
      cache,
      link
    });
  }, []);

  useEffect(() => {
    subscriptionLink.connectionParams = () => ({
      Authorization: refToken.current
    });
    subscriptionLink.close();
  }, [token]);

  refClient.current = client;

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default Apollo;
