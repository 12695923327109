import React from 'react';

import { Modal, Form, Select, Input, Button, message, Alert, Checkbox } from 'antd';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { FEEDBACK_MUTATION } from '../../helper/api/mutations';

function ModalFeedbackContent({ onCancel }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [feedback, { loading, error }] = useMutation(FEEDBACK_MUTATION, { onCompleted, onError });

  function onCompleted() {
    onCancel();
    message.success(t('modal.feedback.res.thanks'));
  }

  function onError() {}

  function onSave() {
    return form.validateFields().then((values) =>
      feedback({
        variables: {
          type: values.type,
          category: values.category,
          message: values.message,
          email: null,
          platformType: 'web',
          useEmail: values.useEmail,
          platformVersion: process.env.REACT_APP_SENTRY_RELEASE
        }
      })
    );
  }

  return (
    <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <h2>{t('modal.feedback.title')}</h2>
      {error && (
        <Alert
          className="margin-bottom-md"
          description={t('res.error.unknownErrorAlert')}
          type="error"
          closable
        />
      )}
      <Form.Item
        name="type"
        label={t('modal.feedback.whatYouWant')}
        rules={[{ required: true, message: t('modal.feedback.validation.emptyAnswer') }]}
      >
        <Select placeholder={t('pleaseSelect')}>
          <Select.Option value="FEATURE">{t('modal.feedback.type.feature')}</Select.Option>
          <Select.Option value="BUG">{t('modal.feedback.type.bug')}</Select.Option>
          <Select.Option value="QUESTION">{t('modal.feedback.type.question')}</Select.Option>
          <Select.Option value="RATING">{t('modal.feedback.type.rating')}</Select.Option>
          <Select.Option value="OTHER">{t('modal.feedback.type.other')}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="category"
        label={t('modal.feedback.whatConcerns')}
        rules={[{ required: true, message: t('modal.feedback.validation.emptyAnswer') }]}
      >
        <Select placeholder={t('pleaseSelect')}>
          <Select.Option value="AUTHENTICATION">
            {t('modal.feedback.category.authentication')}
          </Select.Option>
          <Select.Option value="COURSE">{t('modal.feedback.category.course')}</Select.Option>
          <Select.Option value="EXERCISE">{t('modal.feedback.category.exercise')}</Select.Option>
          <Select.Option value="SHEET">{t('modal.feedback.category.sheet')}</Select.Option>
          <Select.Option value="TASK">{t('modal.feedback.category.task')}</Select.Option>
          <Select.Option value="VOTING">{t('modal.feedback.category.voting')}</Select.Option>
          <Select.Option value="ACCOUNT">{t('modal.feedback.category.account')}</Select.Option>
          <Select.Option value="OTHER">{t('modal.feedback.category.other')}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="message"
        validateTrigger="onBlur"
        label={t('modal.feedback.yourMessage')}
        rules={[{ required: true, message: t('modal.feedback.validation.emptyMessage') }]}
      >
        <Input.TextArea placeholder={t('modal.feedback.yourMessage')} />
      </Form.Item>
      <Form.Item
        name="useEmail"
        valuePropName="checked"
        label={t('modal.feedback.useEmail')}
        extra={t('modal.feedback.optionalUseEmail')}
      >
        <Checkbox>{t('modal.feedback.yes')}</Checkbox>
      </Form.Item>
      <div className="text-right padding-top-md">
        <span className="padding-right-sm">
          <Button onClick={onCancel}>{t('buttons.close')}</Button>
        </span>
        <Button type="primary" onClick={onSave} loading={loading} disabled={loading}>
          {t('modal.feedback.okButton')}
        </Button>
      </div>
    </Form>
  );
}

function ModalFeedback({ visible, onCancel }) {
  return (
    <Modal visible={visible} onCancel={onCancel} closable footer={null} destroyOnClose>
      <ModalFeedbackContent onCancel={onCancel} />
    </Modal>
  );
}

export default ModalFeedback;
