import React from 'react';

import './Statistic.less';

function Statistic({ reached, max, title }) {
  return (
    <div className="statistic">
      <h1>{reached}</h1>
      <h4>
        /
        {max}
      </h4>
      <span className="text-secondary">{title}</span>
    </div>
  );
}

export default Statistic;
