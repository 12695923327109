import React from 'react';

import { Card } from 'antd';

import './CardLoading.less';

function CardLoading() {
  return (
    <Card
      loading
      className="main-card student-card padding-top-sm padding-right-md padding-bottom-md padding-left-md"
    />
  );
}

export default CardLoading;
