import React, { useEffect, useReducer } from 'react';

import { Button, Modal, message, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { RightOutlined, DownOutlined } from '@ant-design/icons';
import shuffle from 'lodash/shuffle';
import sortBy from 'lodash/sortBy';

import { useMutation } from '@apollo/client';
import VOTE_STATE from '../../../../../constants/VOTE_STATE';
import { UPDATE_STUDENT_VOTE_MUTATION } from '../../../../../helper/api/mutations';
import getErrorCode from '../../../../../helper/getErrorCode';
import { updateCacheVotePresentation } from '../../../../../helper/cacheUpdate';

const ACTIONS = {
  SET_STUDENTS: 1,
  SELECT_NEXT_STUDENT: 2,
  CLOSE_MODAL: 3,
  TOGGLEINFO: 4
};

function reducerRandomStudentPicker(state, action) {
  switch (action.type) {
    case ACTIONS.SET_STUDENTS:
      return {
        ...state,
        studentsOrdered: action.studentsOrdered,
        nextStudent: state.nextStudent % action.studentsOrdered.length || 0,
        repeatStudent: false
      };
    case ACTIONS.SELECT_NEXT_STUDENT: {
      const nextStudent = (state.nextStudent + 1) % state.studentsOrdered.length;
      return {
        ...state,
        modalOnceOpened: true,
        visibleModal: true,
        nextStudent,
        selectedStudent: state.studentsOrdered[state.nextStudent],
        repeatStudent: state.nextStudent === 0 && state.modalOnceOpened === true
      };
    }
    case ACTIONS.CLOSE_MODAL:
      return {
        ...state,
        visibleModal: false,
        selectedStudent: undefined,
        repeatStudent: false
      };
    case ACTIONS.TOGGLEINFO:
      return { ...state, visibleInfo: !state.visibleInfo };
    default:
      return state;
  }
}

function RandomStudentPicker({ students, taskId }) {
  const { t } = useTranslation(['common', 'course']);
  const [state, dispatch] = useReducer(reducerRandomStudentPicker, {
    studentsOrdered: [],
    nextStudent: 0,
    visibleModal: false,
    selectedStudent: undefined,
    repeatStudent: false,
    modalOnceOpened: false,
    visibleInfo: false
  });

  const [updateStudentVote, { loading }] = useMutation(UPDATE_STUDENT_VOTE_MUTATION, {
    onError,
    onCompleted
  });

  useEffect(() => {
    const validStudents = students.filter((s) => {
      const { state: voteState, presented } = s.votes.find((vote) => vote.task.id === taskId) || {};
      return !presented && voteState === VOTE_STATE.VOTED;
    });

    const shuffledStudents = shuffle(validStudents);

    const studentsOrdered = sortBy(shuffledStudents, ['presentCount']);

    dispatch({ type: ACTIONS.SET_STUDENTS, studentsOrdered });
  }, [students, taskId]);

  useEffect(() => {
    if (state.repeatStudent) {
      message.info(t('course:modal.randomPick.repeatStudent'));
    }
  }, [state.repeatStudent]);

  function onCompleted() {
    message.success(t('res.success.presentationUpdate'));
    dispatch({ type: ACTIONS.CLOSE_MODAL });
  }

  function onError(error) {
    const code = getErrorCode(error);

    switch (code) {
      case 409:
        message.error(t('res.error.studentNotMember'));
        break;
      case 404:
      default:
        message.error(t('res.error.unknownError'));
        break;
    }
  }

  function onGivePresentation() {
    return updateStudentVote({
      variables: { user: state.selectedStudent.user.id, task: taskId, presented: true },
      update: updateCacheVotePresentation(
        { userId: state.selectedStudent.id, taskId },
        { presented: true }
      )
    });
  }

  function pickRandomStudent() {
    dispatch({ type: ACTIONS.SELECT_NEXT_STUDENT });
  }

  function onCancel() {
    dispatch({ type: ACTIONS.CLOSE_MODAL });
  }

  function toggleInfo() {
    dispatch({ type: ACTIONS.TOGGLEINFO });
  }

  if (students.length === 0) {
    return null;
  }

  return (
    <>
      <Modal visible={state.visibleModal} onCancel={onCancel} closable footer={null} destroyOnClose>
        <h4>{t('course:taskWillPresent')}</h4>
        <h2 style={{ marginBottom: 0 }}>
          {`${state.selectedStudent?.user.firstName} ${state.selectedStudent?.user.lastName}`}
        </h2>
        <div className="text-secondary margin-bottom-sm">
          {t('course:currentPresented')} 
          {' '}
          {state.selectedStudent?.presentCount}
        </div>
        <Button type="link" style={{ padding: 0 }} onClick={toggleInfo}>
          {t('course:modal.randomPick.howItWorks')}
          {state.visibleInfo ? <DownOutlined /> : <RightOutlined />}
        </Button>
        {state.visibleInfo && (
          <div className="padding-top-sm">
            <p style={{ fontWeight: 'bold' }}>{t('course:modal.randomPick.firstTry.title')}</p>
            <p>{t('course:modal.randomPick.firstTry.content')}</p>
            <p style={{ fontWeight: 'bold' }}>{t('course:modal.randomPick.secondTry.title')}</p>
            <p>{t('course:modal.randomPick.secondTry.content')}</p>
            <p style={{ fontWeight: 'bold' }}>{t('course:modal.randomPick.help.title')}</p>
            <p>{t('course:modal.randomPick.help.content')}</p>
          </div>
        )}
        <div className="text-right padding-top-md">
          <span className="padding-right-sm">
            <Button onClick={pickRandomStudent} disabled={loading}>
              {t('course:retrySelection')}
            </Button>
          </span>
          <span className="padding-right-sm">
            <Button onClick={onCancel} disabled={loading}>
              {t('buttons.close')}
            </Button>
          </span>
          <Button type="primary" onClick={onGivePresentation} loading={loading} disabled={loading}>
            {t('voteAction.givePresentation')}
          </Button>
        </div>
      </Modal>
      <Tooltip title={state.studentsOrdered.length === 0 && t('course:tooltip.noRandomSelection')}>
        <Button
          type="link"
          style={{ margin: 0 }}
          onClick={pickRandomStudent}
          disabled={state.studentsOrdered.length === 0}
        >
          {t('course:randomPick')}
        </Button>
      </Tooltip>
    </>
  );
}

export default React.memo(RandomStudentPicker);
