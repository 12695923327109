import { useState, useEffect } from 'react';

function useBreakpoint(width) {
  const [toSmall, setToSmall] = useState(window.innerWidth < width);

  function onScreenSizeChanges() {
    if (window.innerWidth < width && !toSmall) {
      setToSmall(true);
    } else if (window.innerWidth >= width && toSmall) {
      setToSmall(false);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', onScreenSizeChanges);
    return () => window.removeEventListener('resize', onScreenSizeChanges);
  });

  return { toSmall };
}

export default useBreakpoint;
