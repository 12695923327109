import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import ModalStudentVote from '../../../components/Modals/ModalStudentVote';
import AlertWithButton from '../../../components/AlertWithButton';

function FastVotableInfo({ exercises }) {
  const [selectedExercise, setSelectedExercise] = useState(null);
  const { t } = useTranslation(['common']);

  useEffect(() => {
    if (selectedExercise && !exercises.find((exercise) => exercise.id === selectedExercise.id)) {
      setSelectedExercise(null);
    }
  }, [exercises]);

  function onStartVoting(exercise) {
    setSelectedExercise(exercise);
  }

  function onCancelVoting() {
    setSelectedExercise(null);
  }

  if (exercises.length === 0) {
    return null;
  }

  return (
    <div className="padding-top-md padding-bottom-sm">
      <ModalStudentVote
        visible={!!selectedExercise}
        exerciseId={selectedExercise?.id}
        onClose={onCancelVoting}
      />
      {exercises.map((exercise) => (
        <div key={exercise.id} className="padding-bottom-sm">
          <AlertWithButton
            message={t('exerciseNowVotable', { name: exercise.course.name })}
            buttons={[
              {
                title: t('voteNow'),
                type: 'primary',
                onClick: () => onStartVoting(exercise)
              }
            ]}
            type="info"
            showIcon
          />
        </div>
      ))}
    </div>
  );
}

export default FastVotableInfo;
