import React, { useContext, useState } from 'react';

import { Row, Col, Button, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { COURSE_SETTINGS_TEACHERS_QUERY } from '../../../../../../helper/api/queries';
import TabsWrapper from '../../components/TabsWrapper';
import CourseContext from '../../../../contexts/CourseContextProvider';
import NewUserModal from './components/NewUserModal';
import CardTeacher from './components/CardTeacher';
import PendingInvite from './components/PendingInvite';

function Teachers() {
  const { t } = useTranslation(['common', 'course']);
  const { id } = useContext(CourseContext);
  const [modalVisible, setModalVisible] = useState(false);
  const { data } = useQuery(COURSE_SETTINGS_TEACHERS_QUERY, {
    variables: { id },
    fetchPolicy: 'cache-and-network'
  });

  function onOpenModal() {
    setModalVisible(true);
  }

  function onCloseModal() {
    setModalVisible(false);
  }

  return (
    <TabsWrapper>
      <Row gutter={30}>
        <Col xs={24} style={{ display: 'flex', alignItems: 'center' }} className="margin-bottom-md">
          <h2 style={{ marginBottom: 0 }} className="padding-right-sm">
            {t('teacher_plural')}
          </h2>
          <Tooltip title={t('addTeacher')}>
            <Button type="primary" icon={<PlusOutlined />} shape="circle" onClick={onOpenModal} />
          </Tooltip>
        </Col>
        {(data?.course.invitations || []).map((invitation) => (
          <Col xs={24} key={invitation.email}>
            <PendingInvite invitation={invitation} />
          </Col>
        ))}
        {(data?.course.teachers || []).map((teacher) => (
          <Col key={teacher.node.id} xs={24} lg={12}>
            <CardTeacher teacher={teacher} />
          </Col>
        ))}
        <Col xs={24} />
      </Row>
      <NewUserModal visible={modalVisible} onCancel={onCloseModal} />
    </TabsWrapper>
  );
}

export default Teachers;
