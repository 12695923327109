import React, { useEffect, useState, useRef } from 'react';

import { Form, Tag, Button, message } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import { EXERCISE_SHEET_VOTE_TIME } from '../../../../../helper/api/queries';
import { VOTEABLE_UPDATE_SUBSCRIPTION } from '../../../../../helper/api/subscriptions';
import { UPDATE_VOTABLE_MUTATION } from '../../../../../helper/api/mutations';

const INCREASE_TIME = 15;

function Countdown({ to }) {
  const { t } = useTranslation(['course']);
  const [humanizedDiff, setHumanizedDiff] = useState(null);
  const refTimout = useRef(undefined);

  useEffect(() => {
    countdownWeeks();
    return () => clearTimeout(refTimout.current);
  }, [to]);

  function countdownWeeks() {
    clearTimeout(refTimout);
    const diffWeeks = moment(to).diff(moment(), 'weeks');

    if (diffWeeks >= 1) {
      setHumanizedDiff({ diff: diffWeeks, unit: t('course:countdown.week', { count: diffWeeks }) });
    } else {
      countdownDays();
    }
  }

  function countdownDays() {
    clearTimeout(refTimout);
    const diffDays = moment(to).diff(moment(), 'days');

    if (diffDays >= 1) {
      setHumanizedDiff({ diff: diffDays, unit: t('course:countdown.day', { count: diffDays }) });
    } else {
      countdownHours();
    }
  }

  function countdownHours() {
    clearTimeout(refTimout);
    const diffHours = moment(to).diff(moment(), 'hours');

    if (diffHours >= 1) {
      setHumanizedDiff({ diff: diffHours, unit: t('course:countdown.hour', { count: diffHours }) });
      refTimout.current = setTimeout(countdownHours, 3600000);
    } else {
      countdownMinutesSeconds();
    }
  }

  function countdownMinutesSeconds() {
    clearTimeout(refTimout);
    const current = moment();

    const diffMinutes = moment(to).diff(current, 'minutes');
    const diffSeconds = moment(to).subtract(diffMinutes, 'minutes').diff(current, 'seconds');

    if (diffMinutes <= 0 && diffSeconds <= 0) {
      setHumanizedDiff({ diff: `00:00`, unit: t('course:countdown.minute_plural') });
    } else {
      setHumanizedDiff({
        diff: `${diffMinutes < 10 ? '0' : ''}${diffMinutes}:${
          diffSeconds < 10 ? '0' : ''
        }${diffSeconds}`,
        unit: t('course:countdown.minute', { count: diffMinutes })
      });
      refTimout.current = setTimeout(countdownMinutesSeconds, 1000);
    }
  }

  if (humanizedDiff) {
    return t('course:countdown.still', { time: `${humanizedDiff.diff} ${humanizedDiff.unit}` });
  }
  return null;
}

function VotingSchedule({ exerciseId, sheetId }) {
  const { t } = useTranslation(['common', 'course']);
  const { data, error, subscribeToMore } = useQuery(EXERCISE_SHEET_VOTE_TIME, {
    variables: { exercise: exerciseId, sheet: sheetId },
    skip: !exerciseId && !sheetId,
    fetchPolicy: 'cache-and-network'
  });

  const [updateVotable, { loading }] = useMutation(UPDATE_VOTABLE_MUTATION);

  useEffect(() => {
    if (exerciseId && sheetId && data) {
      const unsubscribe = subscribeToMore({
        document: VOTEABLE_UPDATE_SUBSCRIPTION,
        variables: { exercise: exerciseId, sheet: sheetId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          return update(prev, {
            exerciseSheet: {
              closeTime: { $set: subscriptionData.data.votableUpdate.closeTime },
              votable: { $set: subscriptionData.data.votableUpdate.votable }
            }
          });
        }
      });
      return unsubscribe;
    }
    return undefined;
  }, [subscribeToMore, exerciseId, sheetId, data]);

  function onCompletedOpenVoting() {
    message.success(t('course:res.timeExtended'));
  }

  function onErrorOpenVoting() {
    message.success(t('course:res.timeExtendedFail'));
  }

  function onCompletedCloseVoting() {
    message.success(t('course:res.timeEnd'));
  }

  function onErrorCloseVoting() {
    message.success(t('res.error.unknownError'));
  }

  function onAddFiveMinutes() {
    return updateVotable({
      variables: { exercise: exerciseId, sheet: sheetId, votable: true, minutes: INCREASE_TIME },
      onCompleted: onCompletedOpenVoting,
      onError: onErrorOpenVoting
    });
  }

  function onCloseVoting() {
    return updateVotable({
      variables: { exercise: exerciseId, sheet: sheetId, votable: false },
      onCompleted: onCompletedCloseVoting,
      onError: onErrorCloseVoting
    });
  }

  if (error) {
    return (
      <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
        <Tag color="default">{t('res.error.transmission')}</Tag>
      </Form.Item>
    );
  }

  if (!data) {
    return null;
  }

  if (data.exerciseSheet.votable) {
    return (
      <Form.Item
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        style={{ marginBottom: 0 }}
        extra={(
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={onCloseVoting}
            loading={loading}
            disabled={loading}
          >
            {t('course:closeVoting')}
          </Button>
        )}
      >
        <Tag color="success">{t('course:openVoting')}</Tag>
        <Countdown to={data.exerciseSheet.closeTime} />
      </Form.Item>
    );
  }
  return (
    <Form.Item
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      style={{ marginBottom: 0 }}
      extra={(
        <Button
          type="link"
          style={{ padding: 0 }}
          onClick={onAddFiveMinutes}
          loading={loading}
          disabled={loading}
        >
          {t('course:extendVoting', { time: INCREASE_TIME })}
        </Button>
      )}
    >
      <Tag color="error">{t('course:closedVoting')}</Tag>
    </Form.Item>
  );
}

export default React.memo(VotingSchedule);
