import React from 'react';

import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

function TagCommented({ note }) {
  const { t } = useTranslation();
  if (!note) {
    return null;
  }
  return (
    <Tag color="blue" style={{ marginLeft: 10 }}>
      {t('commented')}
    </Tag>
  );
}

export default React.memo(TagCommented);
