import React, { useContext } from 'react';

import { Button, message, Tooltip, Switch, Popconfirm } from 'antd';
import { DeleteOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import CourseContext from '../../../../../contexts/CourseContextProvider';
import PreviewWrapper from '../../../../../../../components/Preview/PreviewWrapper';
import {
  KICK_TEACHER_MUTATION,
  PROMOTE_TEACHER_MUTATION,
  DEMOTE_TEACHER_MUTATION
} from '../../../../../../../helper/api/mutations';
import AccountContext from '../../../../../../../contexts/AccountContextProvider';
import {
  updateCacheTeacherRole,
  updateCacheKickTeacher
} from '../../../../../../../helper/cacheUpdate';

function CardTeacher({ teacher }) {
  const { t } = useTranslation(['common', 'course']);
  const { account } = useContext(AccountContext);
  const { id } = useContext(CourseContext);

  const [kickTeacher, { loading: loadingKickTeacher }] = useMutation(KICK_TEACHER_MUTATION, {
    onError: onErrorKickTeacher,
    onCompleted: onCompletedKickTeacher
  });

  const [promoteTeacher, { loading: loadingPromoteTeacher }] = useMutation(
    PROMOTE_TEACHER_MUTATION,
    {
      onError: onErrorPromoteTeacher,
      onCompleted: onCompletedPromoteTeacher
    }
  );

  const [demoteTeacher, { loading: loadingDemotateTeacher }] = useMutation(
    DEMOTE_TEACHER_MUTATION,
    {
      onError: onErrorDemoteTeacher,
      onCompleted: onCompletedDemoteTeacher
    }
  );

  /* -------------------------------------------------------------------------- */
  /*                           Kick Teacher Operations                          */
  /* -------------------------------------------------------------------------- */

  function onKickTeacher() {
    return kickTeacher({
      variables: { course: id, user: teacher.node.id },
      update: updateCacheKickTeacher({ courseId: id }, { teacherId: teacher.node.id })
    });
  }

  function onCompletedKickTeacher() {
    message.success(t('course:res.kickTeacher'));
  }

  function onErrorKickTeacher() {
    message.error(t('course:res.kickTeacherFail'));
  }

  /* -------------------------------------------------------------------------- */
  /*                           Promote User Operations                          */
  /* -------------------------------------------------------------------------- */

  function onPromoteTeacher() {
    return promoteTeacher({
      variables: { course: id, user: teacher.node.id },
      update: updateCacheTeacherRole({ teacherId: teacher.node.id }, { role: 'ADMIN' })
    });
  }

  function onCompletedPromoteTeacher() {
    message.success(t('course:res.promoteTeacher', { name: teacher.node.firstName }));
  }

  function onErrorPromoteTeacher() {
    message.error(t('course:res.promoteTeacherFail', { name: teacher.node.firstName }));
  }

  /* -------------------------------------------------------------------------- */
  /*                           Demote User Operations                           */
  /* -------------------------------------------------------------------------- */

  function onDemoteTeacher() {
    return demoteTeacher({
      variables: { course: id, user: teacher.node.id },
      update: updateCacheTeacherRole({ teacherId: teacher.node.id }, { role: 'TUTOR' })
    });
  }

  function onCompletedDemoteTeacher() {
    message.success(t('course:res.demoteTeacher', { name: teacher.node.firstName }));
  }

  function onErrorDemoteTeacher() {
    message.error(t('course:res.demoteTeacherFail', { name: teacher.node.firstName }));
  }

  /* -------------------------------------------------------------------------- */
  /*                               Other Functions                              */
  /* -------------------------------------------------------------------------- */

  function onToggleAdmin() {
    if (teacher.role === 'ADMIN') {
      return onDemoteTeacher();
    }
    return onPromoteTeacher();
  }

  return (
    <PreviewWrapper
      title={`${teacher.node.firstName} ${teacher.node.lastName}`}
      extra={(
        <Tooltip
          title={
            teacher.node.id === account.id ? t('course:tooltip.kickSelf') : t('tooltip.kickTeacher')
          }
        >
          <Popconfirm
            okText={t('delete')}
            cancelText={t('buttons.cancel')}
            okType="danger"
            title={t('course:tooltip.kickTeacherSure')}
            onConfirm={onKickTeacher}
          >
            <Button
              icon={<DeleteOutlined />}
              type="danger"
              shape="circle"
              loading={loadingKickTeacher}
              disabled={teacher.node.id === account.id}
            />
          </Popconfirm>
        </Tooltip>
      )}
      description={(
        <span>
          {t('admin')}
          : &nbsp;
          <Tooltip title={teacher.node.id === account.id && t('course:tooltip.demoteSelf')}>
            <Switch
              loading={loadingDemotateTeacher || loadingPromoteTeacher}
              disabled={teacher.node.id === account.id}
              onClick={onToggleAdmin}
              checked={teacher.role === 'ADMIN'}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Tooltip>
        </span>
      )}
    />
  );
}

export default CardTeacher;
