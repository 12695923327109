import React, { useState, useCallback, useEffect } from 'react';

import { Modal, Form, Input, Select, TimePicker, Tooltip, Button, Alert } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function ModalExerciseForm({ values, loading, error, onCancel, onOk }) {
  const { t } = useTranslation();
  const [disableAutoVotingTime, setDisableAutoVotingTime] = useState(
    !(values.startTime && values.endTime)
  );
  const [form] = Form.useForm();

  // Check if usefull
  useEffect(() => {
    form.setFields([
      { name: 'exerciseTime', touched: true },
      { name: 'dayOfWeek', touched: true },
      { name: ['location', 'building'], touched: true },
      { name: ['location', 'room'], touched: true }
    ]);
  }, []);

  const transformInputData = useCallback(
    (data) => {
      const result = { id: data.id, name: data.name };

      if (data.location) {
        const splittedLocation = (values.location || '').split('-', 2);
        result.location = {
          building: (splittedLocation[0] || '').replace(/^G/, '').trim(),
          room: (splittedLocation[1] || '').trim()
        };
      }

      if (data.dayOfWeek && data.startTime && data.endTime) {
        result.dayOfWeek = data.dayOfWeek;
        result.exerciseTime = [
          moment(data.startTime, 'HH:mm:ss'),
          moment(data.endTime, 'HH:mm:ss')
        ];
      }

      if (data.startVote && data.endVote) {
        result.votingTime = [moment(data.startVote, 'HH:mm:ss'), moment(data.endVote, 'HH:mm:ss')];
      }

      return result;
    },
    [values]
  );

  function transformOutputData(data) {
    const result = { id: data.id, name: data.name };
    if (data.location.building && data.location.room) {
      result.location = `G${data.location.building} - ${data.location.room}`;
    }

    if (data.dayOfWeek && data.exerciseTime && data.exerciseTime[0] && data.exerciseTime[1]) {
      result.dayOfWeek = data.dayOfWeek;
      result.startTime = moment(data.exerciseTime[0]).format('HH:mm:ss');
      result.endTime = moment(data.exerciseTime[1]).format('HH:mm:ss');
    }

    if (data.votingTime && data.votingTime[0] && data.votingTime[1]) {
      result.dayOfWeek = data.dayOfWeek;
      result.startVote = moment(data.votingTime[0]).format('HH:mm:ss');
      result.endVote = moment(data.votingTime[1]).format('HH:mm:ss');
      result.autoVotable = true;
    }

    return result;
  }

  function onSave() {
    return form
      .validateFields()
      .then((valuesForm) => {
        onOk(transformOutputData(valuesForm));
      })
      .catch(() => {});
  }

  function onValuesChange(
    { exerciseTime: newExerciseTime, votingTime: newVotingTime },
    { exerciseTime, votingTime }
  ) {
    if (newExerciseTime && newExerciseTime[0] && newExerciseTime[1] && disableAutoVotingTime) {
      setDisableAutoVotingTime(false);
      if (!votingTime || !votingTime[0] || !votingTime[1]) {
        form.setFieldsValue({
          votingTime: [
            moment(newExerciseTime[0]).subtract(15, 'minutes'),
            moment(newExerciseTime[0]).add(5, 'minutes')
          ]
        });
      }
    } else if (
      newExerciseTime &&
      (!newExerciseTime[0] || !newExerciseTime[1]) &&
      !disableAutoVotingTime
    ) {
      setDisableAutoVotingTime(true);
    } else if (
      newVotingTime &&
      newVotingTime[0] &&
      newVotingTime[1] &&
      (!exerciseTime || (!exerciseTime[0] && !exerciseTime[1]))
    ) {
      form.setFieldsValue({
        exerciseTime: [moment(newVotingTime[1]), moment(newVotingTime[1]).add(120, 'minutes')]
      });
      if (disableAutoVotingTime) {
        setDisableAutoVotingTime(false);
      }
    }
  }

  function setSmartVotingBegin(offset) {
    const exerciseTime = form.getFieldValue('exerciseTime');
    form.setFieldsValue({
      votingTime: [
        moment(exerciseTime[0]).subtract(offset, 'minutes'),
        moment(exerciseTime[0]).add(5, 'minutes')
      ]
    });
  }

  function onSetSmartVotingBegin15() {
    setSmartVotingBegin(15);
  }

  function onSetSmartVotingBegin30() {
    setSmartVotingBegin(30);
  }

  return (
    <Form
      form={form}
      initialValues={transformInputData(values)}
      name="exerciseForm"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onValuesChange={onValuesChange}
    >
      <h3>{t('modal.exercise.title')}</h3>
      {error && (
        <Alert
          className="margin-bottom-md"
          description={t('res.error.unknownErrorAlert')}
          type="error"
          closable
        />
      )}
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        hasFeedback
        label={t('modal.exercise.groupName')}
        name="name"
        validateTrigger="onBlur"
        rules={[{ required: true, message: t('modal.exercise.validation.emptyExerciseName') }]}
      >
        <Input autoFocus placeholder={t('modal.exercise.groupName')} />
      </Form.Item>
      <Form.Item label={t('modal.exercise.location')}>
        <Input.Group compact>
          <Form.Item
            name={['location', 'building']}
            validateTrigger="onBlur"
            dependencies={[['location', 'room']]}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value && getFieldValue(['location', 'room'])) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject(t('modal.exercise.validation.emptyBuilding'));
                  }
                  return Promise.resolve();
                }
              })
            ]}
            noStyle
          >
            <Input
              placeholder={t('modal.exercise.building')}
              addonBefore={t('modal.exercise.building')}
              style={{ width: '50%' }}
            />
          </Form.Item>
          <Form.Item
            name={['location', 'room']}
            validateTrigger="onBlur"
            dependencies={[['location', 'building']]}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value && getFieldValue(['location', 'building'])) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject(t('modal.exercise.validation.emptyRoom'));
                  }
                  return Promise.resolve();
                }
              })
            ]}
            noStyle
          >
            <Input
              placeholder={t('modal.exercise.room')}
              addonBefore={t('modal.exercise.room')}
              style={{ width: '50%' }}
            />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Form.Item
        label={t('modal.exercise.dayOfWeek')}
        name="dayOfWeek"
        dependencies={['exerciseTime']}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              const exerciseTime = getFieldValue('exerciseTime');
              if (!value && exerciseTime && exerciseTime[0] && exerciseTime[1]) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject(t('modal.exercise.validation.emptyDayOfWeek'));
              }
              return Promise.resolve();
            }
          })
        ]}
      >
        <Select placeholder={t('modal.exercise.dayOfWeek')} allowClear>
          <Select.Option value={1}>{t('modal.exercise.weekDays.mo')}</Select.Option>
          <Select.Option value={2}>{t('modal.exercise.weekDays.tu')}</Select.Option>
          <Select.Option value={3}>{t('modal.exercise.weekDays.we')}</Select.Option>
          <Select.Option value={4}>{t('modal.exercise.weekDays.th')}</Select.Option>
          <Select.Option value={5}>{t('modal.exercise.weekDays.fr')}</Select.Option>
          <Select.Option value={6}>{t('modal.exercise.weekDays.sa')}</Select.Option>
          <Select.Option value={7}>{t('modal.exercise.weekDays.su')}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label={t('modal.exercise.exerciseTime')}
        name="exerciseTime"
        dependencies={['dayOfWeek']}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if ((!value || !value[0] || !value[1]) && getFieldValue('dayOfWeek')) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject(t('modal.exercise.validation.emptyExerciseTime'));
              }
              return Promise.resolve();
            }
          })
        ]}
      >
        <TimePicker.RangePicker format="HH:mm" minuteStep={15} style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        label={t('modal.exercise.votingTime')}
        name="votingTime"
        help={t('modal.exercise.infoAutomaticVoting')}
        extra={(
          <div>
            <Tooltip title={disableAutoVotingTime && t('modal.exercise.smartAutoVotingTooltip')}>
              <Button
                type="link"
                style={{ paddingLeft: 0 }}
                disabled={disableAutoVotingTime}
                onClick={onSetSmartVotingBegin15}
              >
                {t('modal.exercise.smartAutoVoting', { time: 15 })}
              </Button>
            </Tooltip>
            <Tooltip title={disableAutoVotingTime && t('modal.exercise.smartAutoVotingTooltip')}>
              <Button
                type="link"
                style={{ paddingLeft: 0 }}
                disabled={disableAutoVotingTime}
                onClick={onSetSmartVotingBegin30}
              >
                {t('modal.exercise.smartAutoVoting', { time: 30 })}
              </Button>
            </Tooltip>
          </div>
        )}
      >
        <TimePicker.RangePicker format="HH:mm" minuteStep={5} style={{ width: '100%' }} />
      </Form.Item>
      <div className="text-right padding-top-md">
        <span className="padding-right-sm">
          <Button onClick={onCancel}>{t('buttons.close')}</Button>
        </span>
        <Button type="primary" onClick={onSave} loading={loading} disabled={loading}>
          {t('modal.exercise.okButton')}
        </Button>
      </div>
    </Form>
  );
}

function ModalExercise({ visible, values, loading, error, onCancel, onOk }) {
  return (
    <Modal visible={visible} footer={null} onCancel={onCancel} closable destroyOnClose>
      <ModalExerciseForm
        values={values}
        loading={loading}
        error={error}
        onCancel={onCancel}
        onOk={onOk}
      />
    </Modal>
  );
}

export default ModalExercise;
