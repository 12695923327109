import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, message, Tooltip } from 'antd';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { EXERCISE_KICK_STUDENT } from '../../../../../helper/api/mutations';
import { cacheUpdateKickStudent } from '../../../../../helper/cacheUpdate';

function DeleteButton({ studentId, exerciseId, courseId, query }) {
  const { t } = useTranslation(['common', 'course']);
  const [kick, { loading }] = useMutation(EXERCISE_KICK_STUDENT, { onCompleted, onError });

  function onCompleted() {
    message.success(t('course:res.kickStudent'));
  }

  function onError() {
    message.error(t('course:res.kickStudentFail'));
  }

  function onKickStudent() {
    kick({
      variables: { exercise: exerciseId, user: studentId },
      update: cacheUpdateKickStudent({ courseId, query }, { studentId })
    });
  }

  return (
    <Tooltip title={t('course:tooltip.kickStudent')}>
      <Popconfirm
        okText={t('delete')}
        cancelText={t('buttons.cancel')}
        okType="danger"
        title={t('course:tooltip.kickStudent')}
        onConfirm={onKickStudent}
      >
        <Button
          shape="circle"
          type="danger"
          icon={<DeleteOutlined />}
          loading={loading}
          disabled={loading}
        />
      </Popconfirm>
    </Tooltip>
  );
}

export default React.memo(DeleteButton);
