/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useCallback } from 'react';

import { Modal, Button, Spin, Typography, Row, Col, Divider } from 'antd';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { COURSE_STUDENT_INFO_QUERY } from '../../../../helper/api/queries';
import CourseContext from '../../contexts/CourseContextProvider';
import ResponseNetworkError from '../../../../components/Response/ResponseNetworkError';
import DEGREES from '../../../../constants/DEGREES';
import Sheets from './components/Sheets';
import StudentExerciseSelection from './components/StudentExerciseSelection';
import TagAllowed from '../../../../components/Tags/TagAllowed';

function ModalStudentInfoForm({ studentId, courseId, onCancel }) {
  const { t } = useTranslation(['common', 'course']);
  const { data, loading, error } = useQuery(COURSE_STUDENT_INFO_QUERY, {
    variables: { course: courseId, user: studentId },
    fetchPolicy: 'cache-and-network'
  });

  const getDegree = useCallback((degree) => DEGREES.find((e) => e.key === degree)?.name, [
    data?.courseStudent.node.degree
  ]);

  if (loading) {
    return (
      <div style={{ width: '100%' }} className="padding-top-lg padding-bottom-lg text-center">
        <Spin />
      </div>
    );
  }

  if (error) {
    return <ResponseNetworkError />;
  }

  const {
    node: user,
    exercise,
    origin,
    voteCount,
    presentCount,
    illCount,
    sheets
  } = data.courseStudent;

  return (
    <div>
      <h3>{`${user.firstName}  ${user.lastName}`}</h3>
      <Row>
        <Col xs={24} md={12}>
          <h4>{t('course:modal.studentInfo.informations')}</h4>
          <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
            {[
              { label: t('email'), value: user.email, copyable: true },
              { label: t('matriculationNumber'), value: user.matriculationNumber, copyable: true },
              { label: t('study'), value: `${getDegree(user.degree)}, ${user.study}` }
            ].map(({ label, value, copyable }) => (
              <li className="padding-bottom-xs" key={label}>
                <b>{`${label}: `}</b>
                <Typography.Paragraph
                  copyable={copyable}
                  style={{ display: 'inline-block', marginBottom: 0 }}
                >
                  {value}
                </Typography.Paragraph>
              </li>
            ))}
          </ul>
        </Col>
        <Col xs={24} md={0}>
          <Divider />
        </Col>
        <Col xs={24} md={12}>
          <h4>{t('course:modal.studentInfo.progress')}</h4>
          <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
            {[
              {
                label: t('isAllowed'),
                value: (
                  <TagAllowed
                    userCourseInfo={{ voteCount, presentCount, illCount }}
                    taskCount={origin.taskCount}
                    minVotes={origin.minVotes}
                    minPresentations={origin.minPresentations}
                  />
                )
              },
              { label: t('voting_plural'), value: `${voteCount}/${origin.taskCount}` },
              {
                label: t('presentation_plural'),
                value: `${presentCount}/${origin.minPresentations}`
              },
              { label: t('illVotes'), value: illCount }
            ].map(({ label, value, copyable }) => (
              <li className="padding-bottom-xs" key={label}>
                <b>{`${label}: `}</b>
                <Typography.Paragraph
                  copyable={copyable}
                  style={{ display: 'inline-block', marginBottom: 0 }}
                >
                  {value}
                </Typography.Paragraph>
              </li>
            ))}
          </ul>
        </Col>
        <Col span={24}>
          <Divider />
          <h4>{t('exercise')}</h4>
          <StudentExerciseSelection
            courseId={courseId}
            studentId={studentId}
            studentExerciseId={exercise.id}
          />
        </Col>
        <Col span={24}>
          <Divider />
          <h4>{t('sheet_plural')}</h4>
          <Sheets sheets={sheets} />
        </Col>
      </Row>
      <div className="text-right padding-top-md">
        <span className="padding-right-sm">
          <Button onClick={onCancel}>{t('buttons.close')}</Button>
        </span>
      </div>
    </div>
  );
}

function ModalStudentInfo({ visible, studentId, onCancel }) {
  const { id } = useContext(CourseContext);
  return (
    <Modal visible={visible} onCancel={onCancel} destroyOnClose footer={null} closable width="80vw">
      <ModalStudentInfoForm studentId={studentId} courseId={id} onCancel={onCancel} />
    </Modal>
  );
}

export default ModalStudentInfo;
