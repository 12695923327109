import React from 'react';

import { Button } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import ContainerMaxWidth from './ContainerMaxWidth';
import PrimaryButton from '../../../components/PrimaryButton';

function StepsWrapper({
  title,
  description,
  onSkip,
  onPrev,
  onSubmit,
  submitText,
  submitProps = {},
  prevText,
  children
}) {
  const { t } = useTranslation(['common', 'createCourse']);

  return (
    <ContainerMaxWidth maxWidth={600}>
      <div
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        className="padding-bottom-md"
      >
        <h3 style={{ margin: 0 }}>{title}</h3>
        {onSkip && (
          <Button style={{ float: 'right' }} shape="round" onClick={onSkip}>
            {t('createCourse:skip')} 
            {' '}
            <RightOutlined />
          </Button>
        )}
      </div>
      {description && <div className="text-secondary padding-bottom-md">{description}</div>}
      {children}

      <div className="padding-top-md">
        {onPrev && (
          <Button style={{ float: 'left' }} type="defaut" onClick={onPrev}>
            <LeftOutlined /> 
            {' '}
            {prevText || t('buttons.back')}
          </Button>
        )}
        {onSubmit && (
          <PrimaryButton
            style={{ float: 'right' }}
            htmlType="submit"
            onClick={onSubmit}
            {...submitProps}
          >
            {submitText || t('buttons.next')} 
            {' '}
            <RightOutlined />
          </PrimaryButton>
        )}
      </div>
    </ContainerMaxWidth>
  );
}

export default StepsWrapper;
