import React from 'react';

import { Modal, Col } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Qrcode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

import './ModalInvite.less';

function Teaser() {
  return (
    <div className="container-logo text-center margin-bottom-md">
      <img alt="logo" src="/assets/icons/cards.svg" className="padding-right-sm" />
      <span className="title">FinVote</span>
    </div>
  );
}

function ModalInvite({ visible, onCancel, title, inviteCode }) {
  const { t } = useTranslation(['course']);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      closable
      footer={null}
      width="95%"
      className="modal-invite"
      closeIcon={<CloseOutlined style={{ color: '#fff' }} />}
    >
      <div className="text-center padding-top-md padding-right-md padding-bottom-md padding-left-md">
        <Col xs={24} lg={0}>
          <Teaser />
        </Col>
        <Qrcode
          size={1024}
          className="invite-qr-code padding-top-md padding-right-md padding-bottom-md padding-left-md"
          value={`${process.env.REACT_APP_REMOTE_URL}/join/${inviteCode}`}
        />
      </div>
      <div className="container-info padding-top-md padding-right-md padding-bottom-md padding-left-md">
        <Col xs={0} lg={24}>
          <Teaser />
        </Col>

        <h1 style={{ color: '#fff' }}>
          {title}
          :
        </h1>
        <div className="manual-link margin-bottom-md">
          {process.env.REACT_APP_REMOTE_URL}
          /join/
          <span style={{ color: '#00e676' }}>{inviteCode}</span>
        </div>
        <h3 style={{ color: '#fff', fontStyle: 'italic' }}>
          {t('course:modal.invite.alternative')}
        </h3>
      </div>
    </Modal>
  );
}

export default ModalInvite;
