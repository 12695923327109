import React from 'react';

import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import StudentVoteTable from '../../../../../components/StudentVoteTable';

function ModalEditVotesForm({ sheetId, userId, onCancel }) {
  const { t } = useTranslation(['common', 'course']);

  return (
    <div className="padding-right-md">
      <h3>{t('course:modal.editVotes.title')}</h3>
      <StudentVoteTable sheetId={sheetId} userId={userId} />

      <div className="text-right padding-top-sm">
        <Button onClick={onCancel} type="primary">
          {t('buttons.close')}
        </Button>
      </div>
    </div>
  );
}

function ModalEditVotes({ visible, onCancel, sheetId, userId }) {
  return (
    <Modal visible={visible} onCancel={onCancel} closable footer={null} destroyOnClose>
      <ModalEditVotesForm sheetId={sheetId} userId={userId} onCancel={onCancel} />
    </Modal>
  );
}

export default ModalEditVotes;
