import React, { useMemo, useReducer, useEffect, useContext } from 'react';

import { Col, Skeleton, Row, message, Button, Tooltip } from 'antd';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TabsWrapper from '../components/TabsWrapper';
import {
  DELETE_EXERCISE_MUTATION,
  UPDATE_EXERCISE_MUTATION,
  CREATE_EXERCISE_MUTATION
} from '../../../../../helper/api/mutations';
import ResponseNetworkError from '../../../../../components/Response/ResponseNetworkError';
import {
  COURSE_SETTINGS_EXERCISES_QUERY,
  COURSE_OVERVIEW_MENU_QUERY
} from '../../../../../helper/api/queries';
import ResponseEmpty from '../../../../../components/Response/ResponseEmpty';
import PreviewExercise from '../../../../../components/Preview/PreviewExercise';
import ModalExercise from '../../../../../components/Modals/ModalExercise';
import CourseContext from '../../../contexts/CourseContextProvider';
import {
  updateCacheRemoveExercise,
  updateCacheAddExercise
} from '../../../../../helper/cacheUpdate';
import COURSE_ROLE from '../../../../../constants/COURSE_ROLE';

const ACTIONS_EXERCISE_CONTENT = {
  EDIT: 1,
  CREATE: 2,
  NOTHING: 3,
  LOADING: 4,
  ERROR: 5
};

const MODE = {
  EDIT: 1,
  CREATE: 2
};

function reducerExerciseContent(state, action) {
  switch (action.type) {
    case ACTIONS_EXERCISE_CONTENT.EDIT:
      return {
        visible: true,
        mode: MODE.EDIT,
        loading: false,
        error: false,
        values: action.values
      };
    case ACTIONS_EXERCISE_CONTENT.CREATE:
      return {
        visible: true,
        mode: MODE.CREATE,
        loading: false,
        error: false,
        values: action.values
      };
    case ACTIONS_EXERCISE_CONTENT.NOTHING:
      return { visible: false, mode: null, loading: false, error: false, values: {} };
    case ACTIONS_EXERCISE_CONTENT.LOADING:
      return { ...state, loading: true, error: false };
    case ACTIONS_EXERCISE_CONTENT.ERROR:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}

function ExerciseList({ onAddExercise, onEditExercise, onDeleteExercise }) {
  const { t } = useTranslation(['course']);
  const { id, role } = useContext(CourseContext);
  const { data, loading: loadingQuery, error: errorQuery } = useQuery(
    COURSE_SETTINGS_EXERCISES_QUERY,
    {
      variables: { id },
      fetchPolicy: 'cache-and-network'
    }
  );

  const sortedExercises = useMemo(() => {
    if (data && data.course) {
      return [...data.course.exercises].sort((exercise1, exercise2) => {
        if (exercise1.name > exercise2.name) return 1;
        if (exercise1.name < exercise2.name) return -1;
        return 0;
      });
    }
    return [];
  }, [data]);

  if (loadingQuery) {
    return (
      <Col span={24}>
        <Skeleton active />
      </Col>
    );
  }

  if (errorQuery) {
    return (
      <Col span={24}>
        <ResponseNetworkError />
      </Col>
    );
  }

  if (sortedExercises.length === 0) {
    return (
      <Col span={24}>
        <ResponseEmpty
          title={t('course:res.noExercise')}
          button={{ title: t('course:createExercise'), onClick: onAddExercise }}
        />
      </Col>
    );
  }

  return (
    <Col span={24}>
      <Row gutter={30}>
        {sortedExercises.map((exercise) => (
          <Col key={exercise.id} xs={24} lg={12}>
            <PreviewExercise
              exercise={exercise}
              onEdit={onEditExercise}
              onDelete={role === COURSE_ROLE.ADMIN && onDeleteExercise}
            />
          </Col>
        ))}
      </Row>
    </Col>
  );
}

function Exercise() {
  const { t } = useTranslation(['common', 'course']);
  const { id } = useContext(CourseContext);
  const history = useHistory();

  const [modalExerciseState, dispatch] = useReducer(reducerExerciseContent, {
    visible: false,
    mode: null,
    values: null
  });

  const client = useApolloClient();

  const [deleteExerciseMutation] = useMutation(DELETE_EXERCISE_MUTATION, {
    onError: onErrorDeleteExercise,
    onCompleted: onCompletedDeleteExercise
  });

  const [updateExerciseMutation] = useMutation(UPDATE_EXERCISE_MUTATION, {
    onError: onErrorUpdateExercise,
    onCompleted: onCompletedUpdateExercise
  });

  const [createExerciseMutation] = useMutation(CREATE_EXERCISE_MUTATION, {
    onError: onErrorCreateExercise,
    onCompleted: onCompletedCreateExercise
  });

  useEffect(() => {
    if (history.location.state?.addExercise) {
      // has to be with timout, becaus ui transition is to slow
      setTimeout(() => {
        dispatch({ type: ACTIONS_EXERCISE_CONTENT.CREATE, values: { name: `${t('group')} 1` } });
        history.replace(history.location.pathname, {});
      }, 300);
    }
  }, [history.location.state?.addExercise]);

  /* -------------------------------------------------------------------------- */
  /*                              Delete Operations                             */
  /* -------------------------------------------------------------------------- */

  function onDeleteExercise(exerciseId) {
    return deleteExerciseMutation({
      variables: { id: exerciseId },
      refetchQueries: [{ query: COURSE_OVERVIEW_MENU_QUERY, variables: { id } }],
      update: updateCacheRemoveExercise({ courseId: id }, { exerciseId })
    });
  }

  function onCompletedDeleteExercise() {
    message.success(t('course:res.deleteExercise'));
  }

  function onErrorDeleteExercise() {
    message.error(t('course:res.deleteExerciseFail'));
  }

  /* -------------------------------------------------------------------------- */
  /*                              Update Operations                             */
  /* -------------------------------------------------------------------------- */

  function onEditExercise(exercise) {
    dispatch({ type: ACTIONS_EXERCISE_CONTENT.EDIT, values: exercise });
  }

  function updateExercise(exercise) {
    dispatch({ type: ACTIONS_EXERCISE_CONTENT.LOADING });

    return updateExerciseMutation({
      variables: {
        id: exercise.id,
        ...exercise
      }
    });
  }

  function onCompletedUpdateExercise() {
    dispatch({ type: ACTIONS_EXERCISE_CONTENT.NOTHING });
    message.success(t('course:res.saveExercise'));
  }

  function onErrorUpdateExercise() {
    dispatch({ type: ACTIONS_EXERCISE_CONTENT.ERROR });
    message.error(t('course:res.saveExerciseFail'));
  }

  /* -------------------------------------------------------------------------- */
  /*                              Create Operations                             */
  /* -------------------------------------------------------------------------- */

  function onAddExercise() {
    let exercisesCount = null;
    try {
      const exercises = client.readQuery({
        query: COURSE_SETTINGS_EXERCISES_QUERY,
        variables: { id }
      });
      exercisesCount = exercises?.course?.exercises?.length + 1;
    } catch (e) {}

    dispatch({
      type: ACTIONS_EXERCISE_CONTENT.CREATE,
      values: { name: `${t('group')} ${exercisesCount || ''}` }
    });
  }

  function createExercise(exercise) {
    return createExerciseMutation({
      mutation: CREATE_EXERCISE_MUTATION,
      variables: {
        course: id,
        ...exercise
      },
      refetchQueries: [{ query: COURSE_OVERVIEW_MENU_QUERY, variables: { id } }],
      update: updateCacheAddExercise({ courseId: id }, (result) => ({
        exercise: result.data.exercise.create
      }))
    });
  }

  function onCompletedCreateExercise() {
    dispatch({ type: ACTIONS_EXERCISE_CONTENT.NOTHING });
    message.success(t('course:res.saveExercise'));
  }

  function onErrorCreateExercise() {
    dispatch({ type: ACTIONS_EXERCISE_CONTENT.ERROR });
    message.error(t('course:res.createExerciseFail'));
  }

  /* -------------------------------------------------------------------------- */
  /*                               Other Functions                              */
  /* -------------------------------------------------------------------------- */

  function onOk(values) {
    if (modalExerciseState.mode === MODE.EDIT) {
      return updateExercise(values);
    }
    return createExercise(values);
  }

  function onCloseModalExercise() {
    dispatch({ type: ACTIONS_EXERCISE_CONTENT.NOTHING });
  }

  return (
    <TabsWrapper>
      <Row>
        <Col xs={24} style={{ display: 'flex', alignItems: 'center' }} className="margin-bottom-md">
          <h2 style={{ marginBottom: 0 }} className="padding-right-sm">
            {t('exercise_plural')}
          </h2>
          <Tooltip title={t('course:tooltip.addExercise')}>
            <Button type="primary" icon={<PlusOutlined />} shape="circle" onClick={onAddExercise} />
          </Tooltip>
        </Col>
        <ExerciseList
          onAddExercise={onAddExercise}
          onEditExercise={onEditExercise}
          onDeleteExercise={onDeleteExercise}
        />
        <ModalExercise
          visible={modalExerciseState.visible}
          values={modalExerciseState.values}
          loading={modalExerciseState.loading}
          error={modalExerciseState.error}
          onCancel={onCloseModalExercise}
          onOk={onOk}
        />
      </Row>
    </TabsWrapper>
  );
}

export default Exercise;
