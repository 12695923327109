import React, { useContext } from 'react';

import { message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import CourseContext from '../../../../../contexts/CourseContextProvider';
import { DELETE_INVITATION_MUTATION } from '../../../../../../../helper/api/mutations';
import AlertWithButton from '../../../../../../../components/AlertWithButton';
import { cacheUpdateDeleteInvitation } from '../../../../../../../helper/cacheUpdate';

function PendingInvite({ invitation }) {
  const { t } = useTranslation(['common', 'course']);
  const { id } = useContext(CourseContext);

  const [deleteInvatation, { loading }] = useMutation(DELETE_INVITATION_MUTATION, {
    onError,
    onCompleted
  });

  function onDeleteInvitation() {
    return deleteInvatation({
      variables: { course: id, email: invitation.email },
      update: cacheUpdateDeleteInvitation({ courseId: id }, { email: invitation.email })
    });
  }

  function onCompleted() {
    message.success(t('course:res.deleteInvite'));
  }

  function onError() {
    message.error(t('course:res.deleteInviteFail'));
  }

  return (
    <AlertWithButton
      message={(
        <span>
          {t('course:invitePending')} 
          {' '}
          <b>{invitation.email}</b>
        </span>
      )}
      buttons={[
        {
          title: t('delete'),
          icon: <DeleteOutlined />,
          shape: 'round',
          loading,
          disabled: loading,
          onClick: onDeleteInvitation
        }
      ]}
      className="margin-bottom-md"
    />
  );
}

export default PendingInvite;
