import React, { useMemo } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import { Typography, Select, Form } from 'antd';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';

import {
  COURSE_STUDENT_INFO_EXERCISES_QUERY,
  COURSE_STUDENT_INFO_QUERY
} from '../../../../../helper/api/queries';
import { MOVE_STUDENT_MUTATION } from '../../../../../helper/api/mutations';

function StudentExerciseSelection({ studentExerciseId, courseId, studentId }) {
  const { t } = useTranslation(['common', 'course']);
  const { data, loading: loadingQuery, error: errorQuery } = useQuery(
    COURSE_STUDENT_INFO_EXERCISES_QUERY,
    {
      variables: { id: courseId },
      fetchPolicy: 'cache-and-network'
    }
  );
  const [move, { loading: loadingMutation, error: errorMutation }] = useMutation(
    MOVE_STUDENT_MUTATION
  );

  const error = useMemo(() => {
    if (errorMutation) {
      return (
        <Typography.Text type="danger">
          {t('course:modal.studentInfo.res.studentNotMovable')}
        </Typography.Text>
      );
    }
    if (errorQuery) {
      return t('course:modal.studentInfo.res.exercisesNotLoadable');
    }
    return null;
  }, [errorMutation, errorQuery]);

  const loading = loadingQuery || loadingMutation;

  function onChangeSelect(exerciseId) {
    function updateCache(
      cache,
      {
        data: {
          exercise: { move: result }
        }
      }
    ) {
      if (result) {
        const dataCache = cache.readQuery({
          query: COURSE_STUDENT_INFO_QUERY,
          variables: { course: courseId, user: studentId }
        });

        cache.writeQuery({
          query: COURSE_STUDENT_INFO_QUERY,
          variables: { course: courseId, user: studentId },
          data: update(dataCache, {
            courseStudent: { exercise: { id: { $set: exerciseId } } }
          })
        });
      }
    }

    move({ variables: { exercise: exerciseId, user: studentId }, update: updateCache });
  }

  return (
    <Form.Item wrapperCol={{ span: 24 }} validateStatus={loading && 'validating'} extra={error}>
      <Select
        value={!loading && studentExerciseId}
        disabled={loading || errorQuery}
        onChange={onChangeSelect}
        placeholder={t('pleaseSelect')}
      >
        {data?.course.exercises.map((exercise) => (
          <Select.Option key={exercise.id} value={exercise.id}>
            {exercise.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default StudentExerciseSelection;
