import React, { useReducer } from 'react';

import SpecificOverviewContent from './components/SpecificOverviewContent';
import ContainerSelection from './components/ContainerSelection/ContainerSelection';

const ACTIONS_OVERVIEW = {
  SET_EXERCISE: 1,
  SET_SHEET: 2,
  INIT: 3,
  TOGGLE_PRIVACY_MODE: 4,
  SET_COUNTS: 5
};

function reducerOverview(state, action) {
  switch (action.type) {
    case ACTIONS_OVERVIEW.SET_EXERCISE:
      return { ...state, selectedExercise: action.selectedExercise };
    case ACTIONS_OVERVIEW.SET_SHEET:
      return { ...state, selectedSheet: action.selectedSheet };
    case ACTIONS_OVERVIEW.INIT:
      return {
        selectedExercise: action.selectedExercise,
        selectedSheet: action.selectedSheet,
        exerciseCount: action.exerciseCount,
        sheetCount: action.sheetCount,
        loading: false,
        error: action.error
      };
    case ACTIONS_OVERVIEW.TOGGLE_PRIVACY_MODE:
      return { ...state, privacyMode: !state.privacyMode };
    case ACTIONS_OVERVIEW.SET_COUNTS:
      return { ...state, exerciseCount: action.exerciseCount, sheetCount: action.sheetCount };
    default:
      return state;
  }
}

function Overview() {
  const [state, dispatch] = useReducer(reducerOverview, {
    selectedExercise: null,
    selectedSheet: null,
    privacyMode: false,
    exerciseCount: 0,
    sheetCount: 0,
    loading: true,
    error: false
  });

  function onChangeSelectedExercise(exercise) {
    dispatch({ type: ACTIONS_OVERVIEW.SET_EXERCISE, selectedExercise: exercise });
  }

  function onChangeSelectedSheet(sheet) {
    dispatch({ type: ACTIONS_OVERVIEW.SET_SHEET, selectedSheet: sheet });
  }

  function init(sheet, exercise, sheetCount, exerciseCount, error) {
    dispatch({
      type: ACTIONS_OVERVIEW.INIT,
      selectedSheet: sheet,
      selectedExercise: exercise,
      sheetCount,
      exerciseCount,
      error
    });
  }

  function onChangeExerciseAndSheetCounts(sheetCount, exerciseCount) {
    dispatch({
      type: ACTIONS_OVERVIEW.SET_COUNTS,
      sheetCount,
      exerciseCount
    });
  }

  function togglePrivacyMode() {
    dispatch({ type: ACTIONS_OVERVIEW.TOGGLE_PRIVACY_MODE });
  }

  return (
    <div>
      <ContainerSelection
        state={state}
        init={init}
        onChangeSelectedExercise={onChangeSelectedExercise}
        onChangeSelectedSheet={onChangeSelectedSheet}
        onChangeExerciseAndSheetCounts={onChangeExerciseAndSheetCounts}
        togglePrivacyMode={togglePrivacyMode}
      />
      <SpecificOverviewContent state={state} />
    </div>
  );
}

export default Overview;
