import React from 'react';

import { useTranslation } from 'react-i18next';

import ContainerError from '../components/Container/ContainerError/ContainerError';

function Error404() {
  const { t } = useTranslation(['common']);
  return (
    <ContainerError>
      <img
        src="/assets/icons/404.svg"
        alt="error 404"
        className="padding-bottom-md"
        style={{ width: '100%', maxWidth: 600 }}
      />
      <h1 style={{ color: '#fff' }}>{t('404.title')}</h1>
      <span style={{ color: '#fff' }}>{t('404.desc')}</span>
    </ContainerError>
  );
}

export default Error404;
