// subscription: exerciseUpdate => triggered when new exercise or update in exercise

import React, { useMemo, useState, useEffect } from 'react';

import { Row, Col, message } from 'antd';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';

import { useQuery, useSubscription, useMutation } from '@apollo/client';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import ContainerLoggedIn from '../../components/Container/ContainerLoggedIn/ContainerLoggedIn';
import CardExercise from './components/CardExercise/CardExercise';
import CardLoading from './components/CardLoading/CardLoading';
import CardCourse from './components/CardCourse/CardCourse';
import ResponseNetworkError from '../../components/Response/ResponseNetworkError';
import ResponseEmpty from '../../components/Response/ResponseEmpty';
import FastVotableInfo from './components/FastVotableInfo';
import { DASHBOARD_QUERY } from '../../helper/api/queries';
import {
  DASHBOARD_COURSE_SUBSCRIPTION,
  DASHBOARD_EXERCISE_SUBSCRIPTION,
  DASHBOARD_INVITATION_SUBSCRIPTION
} from '../../helper/api/subscriptions';
import AlertWithButton from '../../components/AlertWithButton';
import {
  JOIN_COURSE_MUTATION,
  REJECT_COURSE_MUTATION,
  JOIN_EXERCISE_MUTATION
} from '../../helper/api/mutations';
import getErrorCode from '../../helper/getErrorCode';
import {
  cacheUpdateJoinCourse,
  cacheUpdateRejectCourse,
  cacheUpdateJoinExercise
} from '../../helper/cacheUpdate';
import ModalEnterExercise from '../../components/Modals/ModalEnterExercise';

function Wrapper({ children, style, className, onClick }) {
  return (
    <Col xs={24} lg={12} xxl={8} style={style} className={className} onClick={onClick}>
      {children}
    </Col>
  );
}

function CardList({ title, data, renderItem }) {
  if (!data || data.length === 0) {
    return null;
  }

  return (
    <div>
      <h2>{title}</h2>
      <Row gutter={[60, 30]}>
        {data.map((item) => (
          <Wrapper key={item.id}>{renderItem(item)}</Wrapper>
        ))}
      </Row>
    </div>
  );
}

function Invitation({ invitation }) {
  const { t } = useTranslation(['common']);
  const history = useHistory();
  const [join, { loading: loadingJoinCourse }] = useMutation(JOIN_COURSE_MUTATION, {
    onCompleted: onCompletedJoinCourse,
    onError: onErrorJoinCourse
  });
  const [reject, { loading: loadingRejectCourse }] = useMutation(REJECT_COURSE_MUTATION, {
    onCompleted: onCompletedRejectCourse,
    onError: onErrorRejectCourse
  });

  const disabled = loadingJoinCourse || loadingRejectCourse;

  function onCompletedJoinCourse() {
    message.success(t('dashboard.res.joinCourse'));
    history.push(`/course/${invitation.course.id}`);
  }

  function onErrorJoinCourse(error) {
    const code = getErrorCode(error);
    switch (code) {
      case 404:
        message.error(t('dashboard.res.invalidCode'));
        break;
      case 409:
        onCompletedJoinCourse();
        break;
      default:
        message.error(t('res.errror.unknownError'));
    }
  }

  function onCompletedRejectCourse() {
    message.success(t('dashboard.res.rejectInvite'));
  }

  function onErrorRejectCourse(error) {
    const code = getErrorCode(error);
    switch (code) {
      case 404:
        message.error(t('dashboard.res.invalidCode'));
        break;
      default:
        message.error(t('res.errror.unknownError'));
    }
  }

  function onJoinCourse() {
    return join({
      variables: { course: invitation.course.id },
      update: cacheUpdateJoinCourse({}, (result) => ({
        course: result.data.course.join
      }))
    });
  }

  function onRejectCourse() {
    return reject({
      variables: { course: invitation.course.id },
      update: cacheUpdateRejectCourse({}, { courseId: invitation.course.id })
    });
  }

  return (
    <AlertWithButton
      message={t('dashboard.inviteAlert', { name: invitation.course.name })}
      buttons={[
        {
          title: t('dashboard.accept'),
          tooltipTitle: t('dashboard.tooltip.accept'),
          icon: <CheckOutlined />,
          shape: 'round',
          loading: loadingJoinCourse,
          disabled,
          type: 'primary',
          onClick: onJoinCourse
        },
        {
          icon: <DeleteOutlined />,
          tooltipTitle: t('dashboard.tooltip.reject'),
          shape: 'circle',
          loading: loadingRejectCourse,
          disabled,
          onClick: onRejectCourse
        }
      ]}
    />
  );
}

function InvitationList({ invitations }) {
  const { t } = useTranslation();
  if (invitations.length === 0) {
    return null;
  }
  return (
    <div>
      <h2>{t('dashboard.invite')}</h2>
      <Row gutter={[60, 30]}>
        {invitations.map((invitation) => (
          <Col span={24} key={invitation.course.id}>
            <Invitation invitation={invitation} />
          </Col>
        ))}
      </Row>
    </div>
  );
}

function DashboardList() {
  const [visibleModalEnterExercise, setVisibleModalEnterExercise] = useState(false);
  const { t } = useTranslation(['common']);
  const { data, loading, error } = useQuery(DASHBOARD_QUERY, {
    fetchPolicy: 'cache-and-network'
  });
  const { state = {} } = useLocation();
  const history = useHistory();

  useSubscription(DASHBOARD_COURSE_SUBSCRIPTION, {
    onSubscriptionData: updateCacheCourses,
    skip: !data
  });

  useSubscription(DASHBOARD_EXERCISE_SUBSCRIPTION, {
    onSubscriptionData: updateCacheExercises,
    skip: !data
  });

  useSubscription(DASHBOARD_INVITATION_SUBSCRIPTION, {
    onSubscriptionData: updateCacheInvitation,
    skip: !data
  });

  const votableExercises = useMemo(
    () => data?.account.exercises.filter((exercise) => exercise.votable) || [],
    [data?.account.exercises]
  );

  function updateCacheCourses({ client, subscriptionData }) {
    // eslint-disable-next-line no-underscore-dangle
    switch (subscriptionData.data.courseUpdate.__typename) {
      case 'NewCourse':
        {
          const cacheData = client.readQuery({ query: DASHBOARD_QUERY });
          client.writeQuery({
            query: DASHBOARD_QUERY,
            data: update(cacheData, {
              account: { courses: { $push: [subscriptionData.data.courseUpdate.course] } }
            })
          });
        }
        break;
      case 'Deleted':
        {
          const cacheData = client.readQuery({ query: DASHBOARD_QUERY });
          const index = cacheData.account.courses.findIndex(
            (course) => course.id === subscriptionData.data.courseUpdate.deleted
          );
          client.writeQuery({
            query: DASHBOARD_QUERY,
            data: update(cacheData, {
              account: { courses: { $splice: [[index, 1]] } }
            })
          });
        }
        break;
      case 'Course':
      default:
        break;
    }
  }

  function updateCacheExercises({ client, subscriptionData }) {
    // eslint-disable-next-line no-underscore-dangle
    switch (subscriptionData.data.studentExerciseUpdate.__typename) {
      case 'NewExercise':
        {
          const cacheData = client.readQuery({ query: DASHBOARD_QUERY });
          client.writeQuery({
            query: DASHBOARD_QUERY,
            data: update(cacheData, {
              account: {
                exercises: { $push: [subscriptionData.data.studentExerciseUpdate.exercise] }
              }
            })
          });
        }
        break;
      case 'Deleted':
        {
          const cacheData = client.readQuery({ query: DASHBOARD_QUERY });
          const index = cacheData.account.exercises.findIndex(
            (exercise) => exercise.id === subscriptionData.data.studentExerciseUpdate.deleted
          );
          client.writeQuery({
            query: DASHBOARD_QUERY,
            data: update(cacheData, {
              account: { exercises: { $splice: [[index, 1]] } }
            })
          });
        }
        break;
      case 'Exercise':
      default:
        break;
    }
  }

  function updateCacheInvitation({ client, subscriptionData }) {
    // eslint-disable-next-line no-underscore-dangle
    switch (subscriptionData.data.invitationUpdate.__typename) {
      case 'NewInvitation':
        {
          const cacheData = client.readQuery({ query: DASHBOARD_QUERY });
          client.writeQuery({
            query: DASHBOARD_QUERY,
            data: update(cacheData, {
              account: {
                invitations: { $push: [subscriptionData.data.invitationUpdate.invitation] }
              }
            })
          });
        }
        break;
      case 'Deleted':
        {
          const cacheData = client.readQuery({ query: DASHBOARD_QUERY });
          const index = cacheData.account.invitations.findIndex(
            (invitation) => invitation.course.id === subscriptionData.data.invitationUpdate.deleted
          );
          client.writeQuery({
            query: DASHBOARD_QUERY,
            data: update(cacheData, {
              account: { invitations: { $splice: [[index, 1]] } }
            })
          });
        }
        break;
      case 'Course':
      default:
        break;
    }
  }

  function onCreateCourse() {
    history.push('/course/create');
  }

  function onOpenJoinCourse() {
    setVisibleModalEnterExercise(true);
  }

  function onCloseJoinCourse() {
    setVisibleModalEnterExercise(false);
  }

  if (loading) {
    return (
      <CardList title="&nbsp;" data={[{ id: 'loading' }]} renderItem={() => <CardLoading />} />
    );
  }

  if (error) {
    return <ResponseNetworkError />;
  }

  if (data.account.exercises.length + data.account.courses.length === 0) {
    if (state.onBoardUserAs === 'teacher') {
      return (
        <>
          <InvitationList invitations={data.account.invitations} />
          <ResponseEmpty
            title={t('dashboard.onBoard', { context: 'teacher' })}
            imgSrc="/assets/icons/finish-register.svg"
            button={{ title: t('createCourse'), onClick: onCreateCourse }}
          />
        </>
      );
    }
    if (state.onBoardUserAs === 'student') {
      return (
        <>
          <InvitationList invitations={data.account.invitations} />
          <ModalEnterExercise visible={visibleModalEnterExercise} onCancel={onCloseJoinCourse} />
          <ResponseEmpty
            title={t('dashboard.onBoard', { context: 'student' })}
            imgSrc="/assets/icons/finish-register.svg"
            button={{ title: t('header.joinExercise'), onClick: onOpenJoinCourse }}
          />
        </>
      );
    }
    return (
      <>
        <InvitationList invitations={data.account.invitations} />
        <ResponseEmpty title={t('dashboard.noJoines')} />
      </>
    );
  }

  return (
    <>
      <FastVotableInfo exercises={votableExercises} />
      <InvitationList invitations={data.account.invitations} />
      <CardList
        title={t('exercise_plural')}
        data={data.account.exercises}
        renderItem={(item) => (
          <Link to={`/exercise/${item.id}/${item.course.name}`}>
            <CardExercise exercise={item} />
          </Link>
        )}
      />
      <CardList
        title={t('course_plural')}
        data={data.account.courses}
        renderItem={(item) => (
          <Link to={`/course/${item.id}`}>
            <CardCourse course={item} />
          </Link>
        )}
      />
    </>
  );
}

function JoinExercisePlugin() {
  const { t } = useTranslation();
  const history = useHistory();
  const { inviteCode } = useParams();
  const [exerciseJoin] = useMutation(JOIN_EXERCISE_MUTATION, {
    onCompleted,
    onError
  });

  function onCompleted({ exercise: { join } }) {
    message.success(t('modal.joinExercise.res.joinExercise', { exerciseName: join.course.name }));
    history.push(`/exercise/${join.id}/${join.course.name}`);
  }

  function onError(error) {
    const code = getErrorCode(error);
    switch (code) {
      case 412:
        message.error(t('modal.joinExercise.res.invalidCode'));
        break;
      case 417:
        message.error(t('modal.joinExercise.res.notFullAccountData'));
        break;
      case 409:
        message.error(t('modal.joinExercise.res.alreadyExerciseMember'));
        break;
      default:
        message.error(t('res.error.unknownError'));
    }
    history.replace('/');
  }

  useEffect(() => {
    if (inviteCode) {
      exerciseJoin({
        variables: { code: inviteCode },
        update: cacheUpdateJoinExercise({}, (result) => ({ exercise: result.data.exercise.join }))
      });
    }
  }, [inviteCode]);

  return null;
}

function Dashboard() {
  const { t } = useTranslation();
  return (
    <ContainerLoggedIn routes={[{ title: t('myCourses'), href: '/' }]}>
      <DashboardList />
      <JoinExercisePlugin />
    </ContainerLoggedIn>
  );
}

export default Dashboard;
