import React from 'react';

import { Result } from 'antd';
import { useTranslation } from 'react-i18next';

import useBreakpoint from '../hooks/useBreakpoint';

function MinScreenWidth({ width, children }) {
  const { t } = useTranslation();
  const { toSmall } = useBreakpoint(width);

  if (toSmall) {
    return (
      <Result
        icon={(
          <img
            src="/assets/icons/to-small.svg"
            alt="Screen Size to Small"
            style={{ width: '100%', maxWidth: 400 }}
          />
        )}
        title={t('toSmallScreen')}
      />
    );
  }

  return children;
}

export default MinScreenWidth;
