import React, { useState, useMemo } from 'react';

import { Form, Input, Collapse, Empty, message, Alert } from 'antd';
import { useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import StepsWrapper from '../components/StepsWrapper';

import {
  CREATE_COURSE_MUTATION,
  CREATE_EXERCISE_MUTATION,
  CREATE_SHEET_MUTATION,
  INVITE_TEACHER_MUTATION
} from '../../../helper/api/mutations';

import PreviewExercise from '../../../components/Preview/PreviewExercise';
import PreviewSheet from '../../../components/Preview/PreviewSheet';

function ReviewAndSaveData({ state, onPrev }) {
  const [internalState, setInternalState] = useState({ loading: false, error: false });
  const { t } = useTranslation(['common', 'createCourse']);
  const client = useApolloClient();
  const history = useHistory();

  const validTeachers = useMemo(() => state.users.filter((e) => e), [state.users]);

  function onError() {
    setInternalState({ loading: false, error: true });
    message.error(t('createCourse:reviewAndSave.res.createCourseFail'));
  }

  function onSuccess({
    data: {
      course: {
        create: { id }
      }
    }
  }) {
    message.success(t('createCourse:reviewAndSave.res.createCourse'));
    history.push(`/course/${id}`);
  }

  function onSave() {
    setInternalState({ loading: true, error: false });
    return client
      .mutate({
        mutation: CREATE_COURSE_MUTATION,
        variables: {
          name: state.name,
          minVotes: state.minVotes ? parseFloat(state.minVotes) / 100.0 : undefined,
          minPresentations: state.minPresentations
        }
      })
      .then(async ({ data }) =>
        Promise.all(
          state.exercises.map((exercise) =>
            client.mutate({
              mutation: CREATE_EXERCISE_MUTATION,
              variables: {
                course: data.course.create.id,
                ...exercise
              }
            })
          )
        ).then(() => ({ data }))
      )
      .then(async ({ data }) =>
        Promise.all(
          state.sheets.map((sheet) => {
            return client.mutate({
              mutation: CREATE_SHEET_MUTATION,
              variables: {
                course: data.course.create.id,
                ...sheet
              }
            });
          })
        ).then(() => ({ data }))
      )
      .then(async ({ data }) =>
        Promise.all(
          state.users
            .filter((e) => e)
            .map((email) => {
              return client.mutate({
                mutation: INVITE_TEACHER_MUTATION,
                variables: {
                  course: data.course.create.id,
                  email
                }
              });
            })
        ).then(() => ({ data }))
      )
      .then(onSuccess)
      .catch(onError);
  }

  return (
    <StepsWrapper
      title={t('createCourse:reviewAndSave.summary')}
      onPrev={onPrev}
      onSubmit={onSave}
      submitText={t('buttons.save')}
      submitProps={{ loading: internalState.loading, disabled: internalState.loading }}
    >
      {internalState.error && (
        <Alert
          className="margin-bottom-md"
          description={t('res.error.unknownError')}
          type="error"
          closable
        />
      )}
      <Collapse bordered={false} className="finvote-collapse" defaultActiveKey={['0']}>
        <Collapse.Panel
          header={(
            <div>
              <h4>{t('createCourse:courseInformation')}</h4>
            </div>
          )}
          key="0"
        >
          <Form.Item label={t('courseName')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <Input value={state.name} disabled />
          </Form.Item>
          <Form.Item label={t('minVote')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <Input
              value={state.minVotes}
              disabled
              addonAfter="%"
              placeholder={t('createCourse:reviewAndSave.notSet')}
            />
          </Form.Item>
          <Form.Item
            label={t('minPresentations')}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input
              value={state.minPresentations}
              disabled
              placeholder={t('createCourse:reviewAndSave.notSet')}
            />
          </Form.Item>
        </Collapse.Panel>
        <Collapse.Panel
          header={(
            <div>
              <h4>{t('exercise_plural')}</h4>
              <span className="text-secondary">
                {state.exercises.length > 0
                  ? t('createCourse:reviewAndSave.containsExercise', {
                      count: state.exercises.length
                    })
                  : t('createCourse:reviewAndSave.emptyExercises')}
              </span>
            </div>
          )}
          key="1"
        >
          {state.exercises.length === 0 ? (
            <Empty description={t('createCourse:reviewAndSave.emptyExercises')} />
          ) : (
            state.exercises.map((exercise) => (
              <PreviewExercise exercise={exercise} key={exercise.id} />
            ))
          )}
        </Collapse.Panel>
        <Collapse.Panel
          header={(
            <div>
              <h4>{t('sheet_plural')}</h4>
              <span className="text-secondary">
                {state.sheets.length > 0
                  ? t('createCourse:reviewAndSave.containsSheet', {
                      count: state.sheets.length
                    })
                  : t('createCourse:reviewAndSave.emptySheet')}
              </span>
            </div>
          )}
          key="2"
        >
          {state.sheets.length === 0 ? (
            <Empty description={t('createCourse:reviewAndSave.emptySheet')} />
          ) : (
            state.sheets.map((sheet) => <PreviewSheet sheet={sheet} key={sheet.id} />)
          )}
        </Collapse.Panel>
        <Collapse.Panel
          header={(
            <div>
              <h4>{t('teacher_plural')}</h4>
              <span className="text-secondary">
                {validTeachers.length > 0
                  ? t('createCourse:reviewAndSave.containsTeacher', {
                      count: validTeachers.length
                    })
                  : t('createCourse:reviewAndSave.emptyTeacher')}
              </span>
            </div>
          )}
          key="3"
        >
          {validTeachers.length === 0 ? (
            <Empty description={t('createCourse:reviewAndSave.emptyTeacher')} />
          ) : (
            validTeachers.map((user, index) => (
              <Form.Item
                key={user}
                label={`${t('teacher')} ${index + 1}`}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input value={user} disabled />
              </Form.Item>
            ))
          )}
        </Collapse.Panel>
      </Collapse>
    </StepsWrapper>
  );
}

export default ReviewAndSaveData;
