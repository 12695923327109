import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import { Menu, Avatar } from 'antd';
import { MenuOutlined, UserOutlined } from '@ant-design/icons';

import DropdownLng from '../../../DropdownLng/DropdownLng';
import DropdownAccount from './DropdownAccount';
import ModalEnterExercise from '../../../Modals/ModalEnterExercise';

import './Header.less';

function Header() {
  const { t } = useTranslation();
  const history = useHistory();

  const [visibleModalEnterExercise, setVisibleModalEnterExercise] = useState(false);

  function onCloseEnterModal() {
    setVisibleModalEnterExercise(false);
  }

  function onVisibleModalEnterExercise() {
    setVisibleModalEnterExercise(true);
  }

  function onCreateCourse() {
    history.push('/course/create');
  }

  return (
    <div className="header padding-left-md padding-right-md">
      <Link to="/" className="container-logo padding-right-md">
        <img src="/assets/icons/cards.svg" alt="finvote logo" />
        <span className="title padding-left-sm padding-right-md">FinVote</span>
        <span className="divider" />
      </Link>
      <div className="container-menu">
        <Menu mode="horizontal" overflowedIndicator={<MenuOutlined />}>
          <Menu.Item key="addCourse" onClick={onCreateCourse}>
            {t('createCourse')}
          </Menu.Item>
          <Menu.Item key="joinExercise" onClick={onVisibleModalEnterExercise}>
            {t('header.joinExercise')}
          </Menu.Item>
        </Menu>
      </div>
      <DropdownLng className="container-lang" sendToBackend />
      <DropdownAccount />
      <ModalEnterExercise visible={visibleModalEnterExercise} onCancel={onCloseEnterModal} />
    </div>
  );
}

export function HeaderFallback() {
  return (
    <div className="header padding-left-md padding-right-md">
      <Link to="/" className="container-logo padding-left-xs padding-right-md">
        <img src="/assets/icons/cards.svg" alt="finvote logo" />
        <span className="title padding-left-sm padding-right-md">FinVote</span>
        <span className="divider" />
      </Link>
      <div className="container-menu" />
      <div className="container-account">
        <Avatar icon={<UserOutlined />} size={38} className="avatar" />
      </div>
    </div>
  );
}

export default Header;
