import React from 'react';

import './ContainerShadow.less';

function ContainerShadow({ children, className, ...props }) {
  return (
    <div className={`container-shadow ${className}`} {...props}>
      {children}
    </div>
  );
}

export default ContainerShadow;
